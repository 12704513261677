import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'separateNumber'
})
export class SeparateNumberPipe implements PipeTransform {
	transform(value: string | number): string {
		let strValue = value.toString();
		return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	}
}
