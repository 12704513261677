import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppointmentService } from 'src/app/services/appointment.service';
import { SpecialtyService } from 'src/app/services/specialty.service';
import { StoreService } from 'src/app/services/store.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.css']
})
export class AppointmentComponent implements OnInit {
	public stores: any;
	public specialties: any;
	public appointmentForm: FormGroup;
	public datemin: any;

	constructor(private formBuilder: FormBuilder, private appointmentService: AppointmentService, private storeService: StoreService, private specialtyService: SpecialtyService) {
		this.datemin = new Date();

		this.appointmentForm = this.formBuilder.group({
			name: [null, [Validators.required]],
			email: [null, [Validators.required, Validators.email]],
			phone: [null, [Validators.required]],
			store_id: [null, [Validators.required]],
			specialty_id: [null, [Validators.required]],
			date: [null, [Validators.required]],
			time: [null, [Validators.required]],
			info: [null]
		});
	}

	ngOnInit() {
		this.getStores();
	}

	getStores() {
		this.storeService.all().subscribe((data: any) => {
			if (data.status == 'OK') {
				this.stores = data.data.filter((s: any) => s.specialties.length);
			}
		});
	}

	send() {
		this.appointmentService.create(this.appointmentForm.value).subscribe((data: any) => {
			if (data.status == 'OK') {
				Swal.fire({
					title: 'Sucesso!',
					text: 'Contacto efetuado com sucesso. Aguarde confirmação por e-mail.',
					icon: 'success',
					confirmButtonText: 'OK',
					confirmButtonColor: '#afd43d'
				});
			}
		});
	}

	selectStore(event: any) {
		let store = this.stores.find((s: any) => s.id == event);
		this.specialties = store.specialties.filter((s: any) => s.allow_appointment == 1);
	}

	selectSpecialty(event: any) {
	}
}
