import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
	public changePasswordForm = this.formBuilder.group({
		password: '',
		repeat_password: '',
	});

	constructor(private router: Router, private formBuilder: FormBuilder, private userService: UserService) { }

	ngOnInit(): void {
	}

	save() {
		this.userService.password(this.changePasswordForm.value).subscribe((data: any) => {
			if (data.status == 'OK') {
				Swal.fire({
					title: 'Sucesso!',
					text: 'Password alterada com sucesso',
					icon: 'success',
					confirmButtonText: 'OK',
					confirmButtonColor: '#afd43d'
				}).then((result) => {
					if (result.isConfirmed) {
						this.router.navigate(['/profile']);
					}
				});
			}
		});
	}

	logout() {
		this.userService.logout();
	}
}
