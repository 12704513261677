<div class="container">
	<h3 class="title text-center">História da empresa</h3>

	<p class="mt-5 mx-5">A Ópticas Lince foi fundada na Guarda em 1993 e conta com 14 lojas nos distritos da Guarda, Castelo Branco, Coimbra, Leiria, Porto e Santarém.</p>
	<p class="mx-5">Foi distinguida em 2022 pelo quarto ano consecutivo com prémio de excelência Cinco Estrelas Regiões na Categoria de serviços óticos nos distritos da Guarda, Castelo Branco, Coimbra, Leiria, Porto e Santarém, e viu renovado em 2019 pelo quarto ano consecutivo o reconhecimento como PME Líder.</p>
	<p class="mx-5">
		Dispõe de uma equipa de 40 de profissionais, altamente qualificada e com formação técnica ao mais alto nível repartida pelas diversas áreas de Oftalmologia, Optometria, Contactologia e Ortótica.A Ópticas Lince dispõe das mais conceituadas marcas de lentes do mercado, como a Zeiss, Hoya e Essilor. Na área de contactologia comercializa as marcas Alcon, Baush & Lomb, Cooper Vision e
		Johnson&Johnson. É, ainda, representante de marcas conceituadas como Ana Hickmann, Blackfin, Carrera, Carolina Herrera, Guess, Gucci, Montblanc, Morel, Persol, Ray-Ban, Saint Laurent, Swarovski, T-Charge, The Rolling Stones, Timberland e Mykita.
	</p>
	<p class="mx-5">Ao longo dos últimos anos, construiu a sua experiência privilegiando o contato com os Clientes e com a população em geral, potenciando rastreios em instituições, empresas, estabelecimentos de ensino, IPSS, entre outras.</p>

	<div class="m-5 separator text-center">
		<img src="assets/about.png" style="width: 100%" alt="" />
	</div>

	<div class="row mx-5">
		<div class="col">
			<h3>Missão</h3>
			<p>
				A Ópticas Lince tem como principal
				<b>objetivo contribuir para a melhoria da saúde visual, através de um aconselhamento completo e totalmente adaptado às necessidades de cada um, contribuindo para a saúde visual de todos.</b>
				Tem como missão garantir um serviço totalmente personalizado, em que alia o rigor uma equipa de profissionais altamente qualificada, e com formação técnica ao mais alto nível, repartida pelas diversas áreas de Optometria, Contactologia e Ortóptica, à qualidade dos seus produtos e serviços.
			</p>
		</div>
		<div class="col">
			<h3>Visão</h3>
			<p>Ser a óptica de referência ao nível de cuidados visuais nas áreas onde está presente e reforçar o seu posicionamento e potencial de negócio aumentando a sua cadeia de lojas de forma gradual e sustentada.</p>
		</div>
		<div class="col">
			<h3>Valores</h3>
			<ul>
				<li>Valores</li>
				<li>Valores</li>
				<li>Confiança</li>
				<li>Compromisso</li>
				<li>Zelo</li>
				<li>Proximidade</li>
				<li>Qualidade</li>
			</ul>
		</div>
	</div>

	<h3 class="title text-center mt-5">Responsabilidade Social</h3>

	<p class="mx-5">
		A Ópticas Lince, tendo como principal objetivo contribuir para a saúde visual de todos, promove e apoia também diversas ações de sensibilização, prevenção e rastreio junto de entidades e das comunidades locais. Estas ações têm como principais objetivos o envolvimento com a comunidade local; promover a saúde visual nas freguesias rurais dos concelhos onde está presente, com especial
		incidência na mais idosa; prevenir, diagnosticar, corrigir e combater défices visuais da população em geral das comunidades locais; proporcionar e facilitar, quando necessário, o devido encaminhamento para tratamentos ou meios/recursos; ajudar os mais carenciados no acesso à saúde visual. Realiza campanhas de distribuição de comunicação informativa com o intuito de sensibilizar e
		esclarecer a população em geral para a temática da saúde visual e participa ativamente em feiras de saúde promovidas por instituições e associações locais como Universidades, Centros de Dia, Associações Recreativas, IPSS, entre outras. A Ópticas Lince trabalha com instituições de solidariedade social como a Acreditar, Aldeia de Crianças SOS Guarda, Cáritas Diocesana Guarda, Casa Sagrada
		Família, Cruz Vermelha Portuguesa, entre muitas outras.
	</p>

	<div class="m-5 separator text-center">
		<img src="assets/about02.png" style="width: 100%" alt="" />
	</div>
</div>
