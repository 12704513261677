<div id="homeSlides" class="carousel slide" data-bs-ride="carousel" data-bs-interval="15000">
	<div class="carousel-indicators">
		<button *ngFor="let banner of banners; let i = index" type="button" [ngClass]="{ active: i === 0 }"
			data-bs-target="#homeSlides" attr.data-bs-slide-to="{{ i }}"></button>
	</div>
	<div class="carousel-inner">
		<div *ngFor="let banner of banners; let i = index" class="carousel-item" [ngClass]="{ active: i === 0 }">
			<div class="video-container">
				<img *ngIf="banner.video_url === null" src="{{ isMobile ? banner.mobile_image : banner.image }}" class="d-block w-100" />
				<iframe *ngIf="banner.video_url !== null" width="100%" height="600"
					[src]="this._sanitizer.bypassSecurityTrustResourceUrl(banner.video_url)" frameborder="0"
					allowfullscreen></iframe>
				<div class="overlay">
					<div *ngIf="banner.readmore_title != ''" class="button">
						<a href="{{ banner.readmore_url }}" target="_blank"
							[style.background-color]="banner.title_color" class="btn btn-home btn-lg">{{
							banner.readmore_title }}</a>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="carousel-item active">
			<div class="video-container">
				<iframe width="100%" height="600" src="https://www.youtube-nocookie.com/embed/blMf448Ebm0?modestbranding=1&autoplay=1&loop=1&mute=1&showinfo=0&controls=0&si=ZHiXyYly6yUCMBaa&playlist=blMf448Ebm0" frameborder="0" allowfullscreen></iframe>
				<div class="overlay">
					<div class="button">
						<a routerLink="/products" [queryParams]="{ brand_id: 1766 }" class="btn btn-home btn-lg">Saber Mais</a>
					</div>
				</div>
			</div>
		</div>
		<div class="carousel-item">
			<div class="video-container">
				<img src="assets/slides/2.png" class="d-block w-100" />
				<div class="overlay">
					<div class="button">
						<a href="https://www.instagram.com/p/CsuSp3TsPlH/" target="_blank" class="btn btn-home btn-lg">Saber Mais</a>
					</div>
				</div>
			</div>
		</div>
		<div class="carousel-item">
			<div class="video-container">
				<img src="assets/slides/3.png" class="d-block w-100" />
				<div class="overlay">
					<div class="button">
						<a routerLink="/campaign/3" class="btn btn-home btn-lg">Saber Mais</a>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</div>

<section *ngIf="productFeature.length > 0">
	<div class="container mt-5">
		<h4 class="text-light text-center my-5">Novidades</h4>

		<div id="carouselFeatures" class="carousel slide">
			<div class="carousel-inner">
				<div *ngFor="let i of productFeatureLength; let index" class="carousel-item"
					[ngClass]="{ active: index == 0 }">
					<div class="products">
						<div *ngFor="let product of productFeature | slice : i * productPerSlide : i * productPerSlide + productPerSlide"
							class="product mb-4">
							<a routerLink="/product/{{ product.id }}" class="link-product">
								<div class="image" style="background-image: url('{{ product.image }}');"></div>
							</a>
							<a routerLink="/product/{{ product.id }}" class="link-product">
								<div class="detail">
									<div class="name">
										<div>{{ product.brand.name }}<span *ngIf="product.brand.name"> | </span>{{
											product.name }}</div>
										{{ product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}
									</div>
									<div class="like" (click)="$event.stopPropagation(); favorite(product)">
										<img src="assets/icons/like.svg" *ngIf="!product.favorite" height="20px" />
										<img src="assets/icons/like2.svg" *ngIf="product.favorite" height="20px" />
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
			<button class="carousel-control-prev" type="button" data-bs-target="#carouselFeatures" data-bs-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Previous</span>
			</button>
			<button class="carousel-control-next" type="button" data-bs-target="#carouselFeatures" data-bs-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Next</span>
			</button>
		</div>

		<div class="text-center my-5">
			<div class="btn btn-lg btn-more px-5" routerLink="/products">Ver Todos</div>
		</div>
	</div>
</section>

<section>
	<div class="container">
		<div class="row">
			<div class="col-md-4 my-2">
				<div class="d-flex flex-column align-items-center justify-content-end category woman">
					<a (click)="openWoman()" class="btn btn-lg btn-more px-5">Mulher</a>
				</div>
			</div>
			<div class="col-md-4 my-2">
				<div class="d-flex flex-column align-items-center justify-content-end category kid">
					<a (click)="openKid()" class="btn btn-lg btn-more px-5">Criança</a>
				</div>
			</div>
			<div class="col-md-4 my-2">
				<div class="d-flex flex-column align-items-center justify-content-end category man">
					<a (click)="openMan()" class="btn btn-lg btn-more px-5">Homem</a>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="group">
	<div class="container">
		<div class="row d-flex align-items-end justify-content-center">
			<div class="col-sm-12 col-md-4">
				<img class="img-fluid" src="assets/selo.png" alt="" />
			</div>
		</div>
	</div>
</section>

<app-appointment></app-appointment>

<section>
	<div class="container">
		<div class="d-flex flex-row align-items-center">
			<a routerLink="/campaigns">
				<img class="d-md-none" src="assets/banner_campaigns_mobile.jpeg" style="width: 100%" alt="" />
				<img class="d-none d-md-block" src="assets/banner_campaigns.jpeg" style="width: 100%" alt="" />
			</a>
		</div>
	</div>
</section>

<section>
	<div class="container">
        <h3 class="text-center text-light pb-5">
            Seguir <a class="btn-link" target="_blank" href="https://www.instagram.com/opticaslincesa/">&#64;opticaslincesa</a>
        </h3>
		<div class="news row">
            <a href="{{ post.type == 'fb' ? post.link : getTitlePathAsString('news', post) }}" *ngFor="let post of news | slice : 0 : 5"
               class="post my-3 col-xs-12 col-sm-6 col-md-3 col-lg">
				<img src="{{ post.image_path }}" />
			</a>
		</div>
	</div>
</section>
