<div class="container">
	<h3 class="title text-center">Condições Gerais de Venda</h3>

	<p class="mt-5 mx-5">Os presentes termos e condições subsequentes são aplicáveis a todas as vendas e entregas, se nada for acordado em contrário, por escrito.</p>

	<ol type="I" class="mx-5">
		<li class="mt-3">
			<p><b>INFORMAÇÕES</b></p>
			<ol type="1">
				<li>A aquisição de bens e serviços à Ópticas Lince implica que o Cliente aceita as condições gerais de utilização e venda que detalhamos nos parágrafos seguintes.</li>
				<li>O conjunto de regras e procedimentos apresentados pretende enquadrar os direitos e deveres da relação entre o Cliente e as Ópticas Lince, respeitando a lei em vigor.</li>
				<li>A derrogação de alguma ou de algumas das cláusulas ou o estabelecimento de condições diferentes, acordadas com o Cliente, só se consideram válidas quando feitas por escrito no corpo da fatura ou em documento particular escrito e assinado por ambas as partes.</li>
				<li>As condições de venda são aplicadas, quer a encomenda/compra tenha sido feita presencialmente, por correio eletrónico, telefone, fax ou correio.</li>
				<li>A Ópticas Lince reserva-se ao direito de alterar sem aviso prévio as Condições Gerais de Venda, sendo o Cliente aconselhado a uma consulta regular das mesmas.</li>
				<li>As encomendas/compras são desta forma geridas pelas Condições Gerais de Venda em uso e por conseguinte em vigor na data da encomenda.</li>
				<li>O Cliente é responsável por qualquer custo, despesa, prejuízo ou perda, independentemente da sua natureza, em virtude do incumprimento dos termos e condições gerais de venda.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>ENCOMENDA DE PRODUTOS</b></p>
			<ol type="1">
				<li>A encomenda de produtos é realizada presencialmente, por correio eletrónico, telefone, fax ou correio.</li>
				<li>A encomenda é considerada contratualmente aceite pela Ópticas Lince mediante a confirmação da mesma ao cliente, pelos meios limencionados no ponto 1.</li>
				<li>Os prazos de entrega, que se estabelece em 30 (trinta) dias, são apenas previsionais, salvo ocorrência de força maior não imputável à empresa, não poderão ser imputadas à Ópticas Lince responsabilidades de atrasos nas entregas dos produtos encomendados, nem o cliente terá direito a reclamar qualquer indemnização.
					<ol>
						<li>Não sendo cumprida a obrigação de entrega dos bens na data acordada ou no prazo previsto na alínea anterior, o Cliente tem o direito de solicitar a entrega num prazo adicional adequado às circunstâncias.</li>
						<li>Se a empresa não entregar os bens dentro do prazo adicional, o Cliente tem o direito de resolver o contrato e exigir a devolução de todos os montantes pagos.</li>
					</ol>
				</li>
				<li>No ato da encomenda, caso o produto não faça parte do stock ou seja um produto específico, o Cliente deverá pagar 30% a título de adiantamento e os restantes 70% no acto da entrega.</li>
				<li>
					Quando comunicado ao Cliente que os produtos se encontram à sua disposição para levantamento, este dispõe de um prazo máximo de 30 (trinta) dias para o efetuar. Findo esse prazo, o produto e o valor pago a título de adiantamento, quando aplicado, reverterão a favor da Ópticas Lince.
					<ol>
						<li>No caso de bens encomendados com especificações ou personalizados pelo consumidor ou que não estejam no stock da empresa, poderá ser exigível o valor total da encomenda.</li>
					</ol>
				</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>PREÇO DE VENDA</b></p>
			<ol>
				<li>Os produtos são faturados de acordo com os preços praticados pela Ópticas Lince na data da sua encomenda.</li>
				<li>Os orçamentos/propostas previamente fornecidos tem validade de 15 dias.</li>
				<li>Entre a data em que é solicitada ou apresentada a proposta e a data da encomenda, a Ópticas Lince pode alterar os preços, exceto se a encomenda for realizada dentro da validade da proposta.</li>
				<li>Os preços dos produtos apresentados em loja são indicados pelo seu valor bruto, já incluído do Imposto sobre o valor Acrescentado (IVA) em vigor. Os preços dos produtos vendidos são apresentados ao cliente pelo seu valor líquido, já incluído do IVA em vigor e dos descontos aplicados, seja por negociação, seja derivado de acordos e/ou protocolos.</li>
				<li>As vendas a crédito estão sujeitas a uma aprovação por parte da Ópticas Lince e ao preenchimento e assinatura de um acordo de pagamento entre ambas as partes com as condições acordadas. A Ópticas Lince reserva-se ao direito de reverter a decisão de atribuição de crédito.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>DESCONTOS/PROMOÇÕES</b></p>
			<ol>
				<li>Todos os descontos/promoções têm um período determinado de vigência. As condições e o período de vigência podem ser sempre consultadas no interior das lojas.</li>
				<li>Os descontos/promoções representam um compromisso durante o período anunciado, mas sempre na condição de que a encomenda seja finalizada pelo Cliente até ao final desse período</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>CONDIÇÕES DE PAGAMENTO</b></p>
			<ol>
				<li>O pagamento da fatura deve ser efetuado no prazo fixado pela Ópticas Lince a contar da data de emissão da mesma.</li>
				<li>O pagamento deve ser efetuado mediante entrega em numerário, multibanco, cartão de crédito, cheque bancário, ordem de transferência bancaria para a conta do IBAN fornecido.</li>
				<li>O pagamento efetuado por cheque só será considerado realizado após boa cobrança do mesmo.</li>
				<li>A falta de pagamento do valor constante na fatura confere à Ópticas Lince a faculdade de suspender, com efeitos imediatos, a posterior venda de novos produtos ao Cliente.</li>
				<li>O incumprimento do prazo de pagamento e das condições acordadas, por mais de 30 dias, implica o débito de juros de mora à taxa legal em vigor a partir da data de vencimento da fatura ou das condições acordadas.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>ENTREGAS</b></p>
			<ol>
				<li>Os produtos vendidos consideram-se entregues quando aceites pelo Cliente no local da venda ou local por este indicado.</li>
				<li>Os produtos correspondentes a uma encomenda serão objeto de um único ato de entrega ao Cliente, exceto quando o Cliente tenha especificamente solicitado entregas parcelares da encomenda e essa pretensão tenha sido aceite pela Ópticas Lince.</li>
				<li>O Cliente deve, no momento da receção da sua encomenda, proceder à verificação da mesma no que diz respeito à condição dos produtos, bem como condições de venda acordadas.</li>
				<li>Aquando da entrega, caso se verifiquem de imediato danos evidentes nos produtos ou divergência nas condições de venda acordadas, o Cliente deve fazer reclamação no momento à Ópticas Lince.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>DEVOLUÇÃO (PRODUTOS CONFORME)</b></p>
			<ol>
				<li>O prazo para a devolução dos bens adquiridos é de 7 dias a contar da data da compra, com a apresentação da respectiva factura, salvo o estipulado no n.º 2) e 3) do presente articulado.</li>
				<li>Os produtos devolvidos para serem aceites têm obrigatoriamente de se encontrar em condições de venda, ou seja, no mesmo estado em que chegaram às mãos do Cliente, sem qualquer anomalia. As lentes de contacto e líquidos de conservação e limpeza têm obrigatoriamente que manter a inviolabilidade</li>
				<li>
					A Ópticas Lince reserva-se o direito de não aceitar a troca/devolução de um produto que não cumpra os aspetos enumerados no ponto 2, incluindo:
					<ol>
						<li>se os bens fornecidos tiverem sido produzidos ou encomendados com especificações ou personalizados pelo consumidor;</li>
						<li>se os bens forem susceptíveis de se deteriorarem ou ficarem fora do prazo de validade;</li>
						<li>se os bens que foram abertos e após entrega e por motivos de protecção da saúde ou da higiene não podem ser devolvidos;</li>
						<li>se os bens não apresentarem as etiquetas, garantias do produto, caixas ou os artigos apresentarem-se danificados.</li>
					</ol>
				</li>
				<li>Se o valor resultante de uma devolução de produtos for superior ao valor inicial da fatura, o Cliente terá de custear a diferença, utilizando para tal uma das formas de pagamento disponíveis no nº 2 do artigo V.</li>
				<li>Se o valor resultante de uma devolução de produtos for inferior ao valor inicial da fatura, o Cliente ficará com o valor pago em excesso em crédito, válido para utilização em futuras compras.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>GARANTIA (PRODUTOS NÃO CONFORME)</b></p>
			<ol>
				<li>Todos os produtos comercializados pela Ópticas Lince, exceto os que estejam em liquidação (com redução de preço igual ou acima de 50% do PVP original), possuem a garantia indicada nos termos da legislação nacional, de 3 (três) anos contra defeito de fabrico, a partir da data de emissão da respetiva fatura, de acordo com a Lei das Garantias (D.L. n.º 67/2003, atualizado pelo D. L. n.º 84/2021). A garantia no 3º (terceiro) ano só é aplicável e válida caso surja algum defeito e terá de ser o Cliente a provar que tal defeito já existia no momento em que o bem lhe foi entregue.
					<ol>
						<li>Todos os bens vendidos/entregues até 31.12.2021 beneficiam apenas de garantia contra defeito de fabrico de 2 (dois) anos, não sendo aplicável o ponto anterior.</li>
						<li>Os bens em liquidação são vendidos no exato estado em que se encontram e não beneficiam de garantia/assistências por se tratarem de produtos descontinuados pelo fornecedor.</li>
					</ol>
				</li>
				<li>De forma a ser acionada a garantia é obrigatória a apresentação da fatura e do produto pelo Cliente, bem como o preenchimento do respetivo formulário de reclamação. O produto será posteriormente enviado para análise.</li>
				<li>Em caso de defeito do artigo, a troca ou devolução será alvo de reparação, troca ou desconto sobre o preço de venda por parte da Ópticas Lince. Caso se verifique uma utilização incorrecta do Cliente, não há direito a garantia, pelo que a eventual reparação e/ou troca é suportado por este.</li>
				<li>Caso o defeito do artigo se manifeste no prazo de 30 dias, a contar da data de entrega, o Cliente pode solicitar a sua imediata substituição ou resolução do contrato de compra e venda.</li>
				<li>
					As garantias não cobrem:
					<ul>
						<li>Desgaste normal do material, deteriorações ou acidentes resultantes de utilizações negligentes;</li>
						<li>Intervenções nos produtos, nomeadamente tentativas de reparação, ou mesmo reparação, fora dos nossos serviços de assistência;</li>
						<li>Uso impróprio ou negligente do artigo, que pode causar danos irrecuperáveis (variações de temperatura, contacto com água, utilização de produtos abrasivos e não recomendáveis pelo fabricante);</li>
						<li>A alteração da cor do produto com o tempo, devido à oxidação dos acabamentos.</li>
					</ul>
				</li>
				<li>Ao abrigo da garantia, a reparação/substituição por produto igual ou similar, caso não exista rutura ou descontinuidade de stock/produto, deve ser realizada num prazo máximo de 45 (quarenta e cinco) dias, não sendo disponibilizado por parte da Ópticas Lince um produto provisório de substituição.</li>
				<li>Ao abrigo da garantia, em caso de reparação/s, o bem reparado passa a beneficiar de um prazo de garantia adicional de seis meses por cada reparação até ao limite de quatro reparações.</li>
				<li>A reparação de produtos reclamados fora do prazo de garantia ou do seu âmbito é sempre suportada pelo Cliente. Será obrigatária a concordância escrita por parte do Cliente com o Termo de Responsabilidade de Reparação.</li>
				<li>No caso particular das Lentes Oftálmicas, o Cliente beneficia de um prazo de 30 dias, a partir da data de entrega do produto, para adaptação às lentes prescritas e fornecidas.</li>
				<li>No caso particular das reclamações relativas às inadaptações às lentes oftálmicas, de forma a ser acionada a garantia é obrigatória a apresentação da fatura e do produto pelo Cliente, bem como o preenchimento do respetivo formulário de reclamação. O produto será posteriormente enviado para análise e o Cliente terá de ser sujeito a nova consulta.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>POLÍTICA DE PRIVACIDADE E PROTECÇÃO DE DADOS PESSOAIS DOS CLIENTES</b></p>
			<ol>
				<li>
					No cumprimento da legislação sobre a Proteção de Dados Pessoais, todos os dados pessoais das pessoas singulares, fornecidos e recolhidos pela Ópticas Lince, são guardados e processados exclusivamente para uso pessoal da Ópticas Lince, e destinam-se, designadamente para a execução do contrato de compra e venda no qual o titular dos dados é parte e para cumprimento de obrigação
					jurídica a que o responsável pelo tratamento esteja sujeito, bem como para análise estatística, por razões técnicas e administrativas, faturação, cobrança do preço e comunicação com o Cliente, definição de perfis do Cliente.
				</li>
				<li>A Ópticas Lince poderá subcontratar a prestação de serviços junto de outras empresas com vista ao cumprimento das finalidades acima identificadas, que atuam mediante instruções da Ópticas Lince.</li>
				<li>
					O Cliente, na qualidade de titular, tem o direito a solicitar o acesso aos seus dados; a retificá-los caso estejam fora de validade, incorretos, incompletos ou alterados; a apagá-los quando os mesmos já não são necessários à finalidade para a qual foram recolhidos (o apagamento está limitado ao prazo imposto legalmente); à limitação dos seus dados, para a execução do contrato,
					e cumprimento da legislação; à oposição do tratamento, à portabilidade dos dados fornecidos; a retirar o seu consentimento desde que estes direitos não colidam com a execução do contrato ou com o cumprimento das normas legais.
				</li>
				<li>Contudo o direito ao apagamento encontra-se limitado aos prazos legais de 10 anos (ou 12 anos para documentos referentes aos períodos de tributação de 2014 e 2015), prazo exigido para a conservação de documentos da contabilidade (artigo 123º do CIRC).</li>
				<li>O Cliente tem direito a apresentar reclamação a uma entidade de controlo, caso entenda ter existido violação legal de um dos seus direitos supra identificados.</li>
				<li>A prestação de dados pelo Cliente à Ópticas Lince, para as finalidades descritas É OBRIGATÓRIA e a sua não prestação impede o cumprimento do contrato, levando à cessação do mesmo.</li>
				<li>
					O Cliente para o exercício dos seus direitos, poderá contactar o representante da Ópticas Lince através dos seguintes meios:
					<ul>
						<li>Email: geral@opticaslince.com</li>
						<li>Site: www.opticaslince.com</li>
						<li>Carta: Óptica Lince, SA, com sede na Rua do Comércio n.º 32, 6300-679 Guarda</li>
						<li>Telefone: 271 200 830</li>
						<li>Horário de atendimento: das 9h às 20h, de segunda a sexta-feira e das 9h às 19h ao sábado, exceto feriados</li>
					</ul>
				</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>LITÍGIOS</b></p>
			<ol>
				<li>O contrato de compra e venda dos nossos produtos rege-se, em todas as circunstâncias, pela Lei Portuguesa.</li>
				<li>Fica reconhecido por ambas as partes que em caso de litígio o foro competente para a respetiva ação judicial é a Comarca da Guarda.</li>
				<li>De acordo com a Lei nº144/2015, de 8 de Setembro, em caso de litígio o consumidor pode recorrer a uma Entidade de Resolução Alternativa de Litígios de consumo (RAL):
					<ul>
						<li>
							Lojas Ópticas Lince em Batalha | Belmonte | Celorico da Beira | Covilhã | Gouveia | Guarda | Ourém | Pinhel | Sabugal | Seia | Trancoso | Vila Nova de Foz Côa:
							<ul>
								<li>Centro Nacional de Informação e Arbitragem de Conflitos de Consumo (CNIACC)</li>
							</ul>
						</li>
						<li>
							Lojas Ópticas Lince em Matosinhos:
							<ul>
								<li>Centro de Informação e Arbitragem do Porto (CICAP)</li>
							</ul>
						</li>
						<li>
							Lojas Ópticas Lince em Oliveira do Hospital:
							<ul>
								<li>Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra (CACCDC)</li>
							</ul>
						</li>
					</ul>
				</li>
			</ol>
		</li>
	</ol>

	<p class="mx-5">Aprovado em Guarda a 12 de Março de 2024</p>
	<p class="mx-5">Pela Administração</p>
</div>
