import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

const passwordMatcher: ValidatorFn = (
	control: AbstractControl
): ValidationErrors | null => {
	const password = control.get('password');
	const confirmPassword = control.get('confirm_password');

	return password && confirmPassword && password.value !== confirmPassword.value
		? { passwordsDoNotMatch: true }
		: null;
};

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
	public registerForm: FormGroup;

	constructor(private formBuilder: FormBuilder, private router: Router, private userService: UserService) {
		this.registerForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
			password: ['', [Validators.required, Validators.minLength(6)]],
			confirm_password: ['', [Validators.required]],
			born: ['', [Validators.required]],
			name: ['', [Validators.required]],
			phone: ['', [Validators.required]]
		}, {
			validators: passwordMatcher,
		});
	}

	ngOnInit(): void {
	}

	register() {
		if (this.registerForm.valid) {
			this.userService.register(this.registerForm.value).subscribe(async (data: any) => {
				if (data.code === 200) {
					this.registerForm.reset();
					this.router.navigate(['/']);
				} else {
					if (data.data) {
						Swal.fire({
							title: 'Erro!',
							text: data.data,
							icon: 'error',
							confirmButtonText: 'OK',
							confirmButtonColor: '#afd43d'
						});
					}
				}
			});
		}
	}
}
