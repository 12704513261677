import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
	providedIn: "root"
})
export class UtilsService {
	public loading$: BehaviorSubject<any>;
	constructor() {
		this.loading$ = new BehaviorSubject(false);
	}

	loading() {
		return this.loading$;
	}

	showLoading() {
		this.loading$.next(true);
	}

	hideLoading() {
		this.loading$.next(false);
	}

	showAlert(title: any, text: string, icon: any, confirmButtonText: string = "OK", confirmButtonColor: string = "#afd43d") {
		Swal.fire({
			title,
			text,
			icon,
			confirmButtonText,
			confirmButtonColor
		});
	}
}
