import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import { AddressService } from 'src/app/services/address.service';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { MerchantService } from 'src/app/services/merchant.service';
import { OrderService } from 'src/app/services/order.service';
import { StorageService } from 'src/app/services/storage.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
	public cart: any;
	public card: any;
	public merchant: any;
	public quantity: number;
	public user: User;
	public checkoutForm = this.formBuilder.group({
		name: ['', Validators.required],
		email: ['', Validators.required],
		phone: '',
		nif: '',
		address_id: null,
		address_fiscal_id: null,
		address: null,
		postal: null,
		city: null,
		country: '',
		address_fiscal: null,
		postal_fiscal: null,
		city_fiscal: null,
		country_fiscal: '',
		store_id: null,
		use_accumulate: false,
		use_balance: false,
		use_coupon: false,
		delivery_type: ['', Validators.required],
		payment_type: ['', Validators.required],
		delivery_types: [],
		mbway: null,
		cart: null,
		total: 0,
		delivery: null,
		discount: 0,
		coupon: null,
		discount_coupon: 0
	});
	public delivery_types = [
		{
			'id': 1,
			'name': 'Envio para a morada',
		},
		{

			'id': 2,
			'name': 'Levantamento na loja',
		}
	];
	public payments = [
		{
			'id': 1,
			'name': 'MBWay',
		},
		{

			'id': 2,
			'name': 'Cartão de Crédito',
		},
		{

			'id': 3,
			'name': 'Referências Multibanco'
		}
	];
	public coupons: any = [];
	public stores: any = [];
	public addresses: any = [];
	public coupon: any = null;
	public loading: boolean = false;

	constructor(private apiService: ApiService, private storageService: StorageService, private merchantService: MerchantService, private cartService: CartService, private formBuilder: FormBuilder, private userService: UserService, private storeService: StoreService, private addressService: AddressService, private orderService: OrderService, private router: Router) {
		this.user = this.storageService.get("user");
		this.apiService.user = this.user;
	}

	async ngOnInit() {
		this.cart = this.cartService.getCart();
		this.recalculate();

		await this.getMerchant();
		await this.getStores();
		await this.getAddresses();
		await this.loadCard();
		await this.getUser();

		this.checkoutForm.controls['coupon'].valueChanges.subscribe(value => {
			const coupon = this.coupons.find((c: any) => c.id == value);
			let amount = 0;

			if (coupon.amount_type == 'Value') {
				amount = parseFloat(coupon.value);
			} else {
				amount = (this.cart.total * parseFloat(coupon.amount)) / 100;
			}

			this.checkoutForm.controls['discount_coupon'].setValue(amount);
			this.recalculate();
		});

		this.checkoutForm.controls['discount'].valueChanges.subscribe(value => {
			this.recalculate();
		});

		this.checkoutForm.controls['delivery_type'].valueChanges.subscribe(value => {
			switch (value) {
				case '1':
					this.checkoutForm.controls['delivery'].setValue(this.merchant.delivery);
					this.checkoutForm.controls['address_id'].setValidators(Validators.required);

					this.checkoutForm.controls['store_id'].clearValidators();
					this.checkoutForm.controls['store_id'].updateValueAndValidity();
					break;
				case '2':
					this.checkoutForm.controls['delivery'].setValue(0);
					this.checkoutForm.controls['store_id'].setValidators(Validators.required);

					this.checkoutForm.controls['address_id'].clearValidators();
					this.checkoutForm.controls['address_id'].updateValueAndValidity();
					break;
			}

			this.recalculate();
		});

		this.checkoutForm.controls['payment_type'].valueChanges.subscribe(value => {
			switch (value) {
				case '1':
					this.checkoutForm.controls['mbway'].setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9)]);
					break;
				default:
					this.checkoutForm.controls['mbway'].clearValidators();
					this.checkoutForm.controls['mbway'].updateValueAndValidity();
					break;
			}
		});

		this.checkoutForm.controls['address_id'].valueChanges.subscribe(value => {
			switch (value) {
				case 'add':
					this.checkoutForm.controls['address'].setValidators(Validators.required);
					this.checkoutForm.controls['city'].setValidators(Validators.required);
					this.checkoutForm.controls['postal'].setValidators(Validators.required);
					break;
				default:
					this.checkoutForm.controls['address'].clearValidators();
					this.checkoutForm.controls['address'].updateValueAndValidity();

					this.checkoutForm.controls['city'].clearValidators();
					this.checkoutForm.controls['city'].updateValueAndValidity();

					this.checkoutForm.controls['postal'].clearValidators();
					this.checkoutForm.controls['postal'].updateValueAndValidity();
					break;
			}
		});
	}

	async getUser() {
		this.fillCheckout();
	}

	getMerchant() {
		this.merchantService.one().subscribe((data: any) => {
			this.merchant = data.data;
		});
	}

	getStores() {
		this.storeService.all().subscribe((data: any) => {
			this.stores = data.data;
		});
	}

	getAddresses() {
		this.addressService.all().subscribe((data: any) => {
			this.addresses = data.data;
		});
	}

	async loadCard() {
		this.userService.card().subscribe(
			(data: any) => {
				if (data.status === "OK") {
					this.card = data.data[0];

					this.loadCoupons();
				}
			}
		);
	}

	loadCoupons() {
		this.userService.coupons(this.card.id).subscribe(
			(data: any) => {
				if (data.status === "OK") {
					this.coupons = data.data;
				}
			}
		);
	}

	fillCheckout() {
		this.checkoutForm.controls['name'].setValue(this.user.name);
		this.checkoutForm.controls['email'].setValue(this.user.email);
		this.checkoutForm.controls['phone'].setValue(this.user.phone);
		this.checkoutForm.controls['country'].setValue('pt');
		this.checkoutForm.controls['country_fiscal'].setValue('pt');
		this.checkoutForm.controls['nif'].setValue(this.user.nif);
	}

	recalculate() {
		this.cartService.recalculate();

		let delivery_value = this.checkoutForm.get('delivery')?.value;
		const delivery = parseFloat(delivery_value ? delivery_value : 0);

		const discount = parseFloat(this.checkoutForm.get('discount')?.value);
		const discount_coupon = parseFloat(this.checkoutForm.get('discount_coupon')?.value);
		const total = this.cart.total + delivery - discount - discount_coupon;

		this.checkoutForm.controls['total'].setValue(total);
	}

	removeItem(id: any) {
		this.cartService.remove(id);
		this.recalculate();
	}

	checkout() {
		if (!this.loading && this.checkoutForm.valid) {
			this.loading = true;

			this.checkoutForm.controls['cart'].setValue(this.cart.items);

			this.orderService.new(this.checkoutForm.value).subscribe((data: any) => {
				if (data.status == 'OK') {
					const payment = {
						order_id: data.data.id
					};

					this.cartService.clean();

					switch (parseInt(this.checkoutForm.controls['payment_type'].value)) {
						case 1:
							this.orderService.async(payment).subscribe((data: any) => {
								if (data.status === 'OK') {
									Swal.fire({
										title: 'Sucesso!',
										text: 'A sua encomenda foi criada com sucesso',
										icon: 'success',
										confirmButtonText: 'Ver encomenda',
										confirmButtonColor: '#afd43d',
									}).then((result) => {
										if (result.isConfirmed) {
											this.router.navigate(["/orders"]);
										}
									});
								} else {
									this.loading = false;
								}
							});
							break;
						case 3:
							this.orderService.multibanco(payment).subscribe((data: any) => {
								if (data.status === 'OK') {
									Swal.fire({
										title: 'Sucesso!',
										text: 'A sua encomenda foi criada com sucesso',
										icon: 'success',
										confirmButtonText: 'Ver encomenda',
										confirmButtonColor: '#afd43d',
									}).then((result) => {
										if (result.isConfirmed) {
											this.router.navigate(["/orders"]);
										}
									});
								} else {
									this.loading = false;
								}
							});
							break;
						case 2:
						case 4:
							this.orderService.create(payment).subscribe((data: any) => {
								if (data.status === 'OK') {
									Swal.fire({
										title: 'Sucesso!',
										text: 'A sua encomenda foi criada com sucesso',
										icon: 'success',
										timer: 2000,
										showConfirmButton: false
									}).then(() => {
										this.router.navigate(['/payment/', payment.order_id]);
									});
								} else {
									this.loading = false;
								}
							});
							break;
					}
				} else {
					this.loading = false;

					Swal.fire({
						title: 'Erro!',
						text: data.message,
						icon: 'error',
						confirmButtonText: 'OK',
						confirmButtonColor: '#afd43d',
					});
				}
			});
		} else {
			Object.keys(this.checkoutForm.controls).forEach((key: string) => {
				const controlErrors = this.checkoutForm.get(key)?.errors;
				if (controlErrors != null) {
					Object.keys(controlErrors).forEach((errorKey: string) => {
						console.log(key, errorKey);
					});
				}
			});
		}
	}
}