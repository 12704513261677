<div class="container">
	<h4 class="title text-center">Moradas</h4>
</div>

<div class="container px-xl-5">
	<div class="box p-3 p-md-5 mx-md-5">
		<div class="d-md-none">
			<div class="mb-5 list-group">
				<a class="list-group-item list-group-item-action border-0" routerLink="/profile">Meu Perfil</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/orders">Encomendas</a>
				<a class="list-group-item list-group-item-action active border-0" routerLink="/address">Moradas</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/family">Família</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/loyalty">Fidelização</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/change-profile">Dados Pessoais</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/change-password">Password</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">Terminar Sessão</a>
			</div>
		</div>
		<div class="row">
			<div class="d-none d-md-block col-md-auto">
				<div class="list-group">
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/profile">
						<span class="menu-user"><i class="fa fa-user"></i><span class="text">Meu Perfil</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/orders">
						<span class="menu-user"><i class="fa fa-box-open"></i><span class="text">Encomendas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action active border-0" routerLink="/address">
						<span class="menu-user"><i class="fa fa-home"></i><span class="text">Moradas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/family">
						<span class="menu-user"><i class="fa fa-user-group"></i><span class="text">Família</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/loyalty">
						<span class="menu-user"><i class="fa fa-medal"></i><span class="text">Fidelização</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/change-profile">
						<span class="menu-user"><i class="fa fa-user-edit"></i><span class="text">Dados Pessoais</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/change-password">
						<span class="menu-user"><i class="fa fa-key"></i><span class="text">Password</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">
						<span class="menu-user"><i class="fa fa-sign-out"></i><span class="text">Terminar Sessão</span></span>
					</a>
				</div>
			</div>
			<div class="col-md">
				<div class="row">
					<div class="mb-3 col-10 offset-1">
						<div class="text-center">
							<button class="btn btn-primary" (click)="addAddressIsCollapsed = ! addAddressIsCollapsed"
								[attr.aria-expanded]="!addAddressIsCollapsed">
								{{ addAddressIsCollapsed ? 'Adicionar Morada' : 'Minhas Moradas' }}
							</button>
						</div>
						<div #collapse="ngbCollapse" [(ngbCollapse)]="addAddressIsCollapsed">
							<div class="py-5">
								<form [formGroup]="addressForm" action="">
									<h5 class="text-center text-uppercase">Adicionar Morada</h5>
									<div class="mb-3">
										<label for="">Morada</label>
										<input type="text" class="form-control" placeholder="Morada"
											formControlName="address" />
									</div>
									<div class="mb-3">
										<label for="">Código Postal</label>
										<input type="text" class="form-control" placeholder="Código Postal"
											formControlName="postcode" />
									</div>
									<div class="mb-3">
										<label for="">Cidade</label>
										<input type="text" class="form-control" placeholder="Cidade"
											formControlName="city" />
									</div>
									<div class="mb-3">
										<label for="">País</label>
										<select class="form-control" placeholder="País" formControlName="country_id" (change)="changeCountry($event)">
											<option *ngFor="let country of countries" value="{{ country.id }}">{{
												country.name }}</option>
										</select>
									</div>
									<button class="btn btn-primary" (click)="save()">Guardar</button>
									<button class="btn btn-light"
										(click)="addAddressIsCollapsed = !addAddressIsCollapsed">Cancelar</button>
								</form>
							</div>
						</div>
						<div *ngIf="addresses?.length > 0" #collapse="ngbCollapse"
							[(ngbCollapse)]="!addAddressIsCollapsed">
							<div class="my-5">
							</div>

							<h5 class="text-center text-uppercase">As Minhas Moradas</h5>

							<div class="card" *ngFor="let address of addresses">
								<div class="card-body">
									<div class="row">
										<div class="col">
											{{ address.address}}
											<br>
											{{ address.postcode}} {{ address.city}}
											<br>
											{{ address.country}}
										</div>
										<div class="col-auto">
											<button type="button" (click)="remove(address.id)" class="btn border-0 btn-outline-danger"><i
													class="fa fa-trash"></i></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>