<div class="container">
	<h3 class="title text-center">Marcas</h3>
</div>

<div class="container">
	<div class="row mt-5 mx-5">
		<div class="col-3" *ngFor="let brand of brands">
			<div class="store" routerLink="/products" [queryParams]="{ brand_id: brand.id }">
				<div class="gradient">
					<div class="name py-2 px-3">
						{{ brand.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
