<section>
	<div class="container">
		<h4 class="title text-center">Criar Conta</h4>
	</div>

	<div class="container">
		<div class="box">
			<div class="row align-items-center">
				<div class="offset-md-4 col-md-4">
					<form [formGroup]="registerForm">
						<div class="form-group mb-3">
							<input type="text" class="form-control" formControlName="name" placeholder="Nome" />
						</div>
						<div class="form-group mb-3">
							<input type="text" class="form-control" formControlName="phone" placeholder="Telemóvel" />
						</div>
						<div class="form-group mb-3">
							<input type="email" class="form-control" formControlName="email" placeholder="E-mail" />
						</div>
						<div class="form-group mb-3">
							<input type="text" class="form-control" formControlName="born" placeholder="Data Nascimento" onclick="(this.type='date')" onblur="(this.type='text')" />
						</div>
						<div class="form-group mb-3">
							<input type="password" class="form-control" formControlName="password" placeholder="Password" />
						</div>
						<div class="form-group mb-3">
							<input type="password" class="form-control" formControlName="confirm_password" placeholder="Confirmar Password" />
						</div>
						<div class="form-group mt-4">
							<button class="btn btn-lg btn-primary" [disabled]="registerForm.invalid" (click)="register()" type="submit" style="width: 100%">Registar</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>
