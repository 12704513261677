<div class="container">
	<h3 class="title text-center">Política de Sustentabilidade Ambiental</h3>

	<ol class="mt-5 mx-2 mx-md-5">
		<li class="mt-3">
			<p><b>SUSTENTABILIDADE COMO EIXO DE NEGÓCIO</b></p>
			<p>Desenvolvemos a nossa atividade, sempre, com o objetivo de proteger os recursos naturais e o ambiente, não esquecendo a responsabilidade da Ópticas Lince para com a comunidade e os seus colaboradores.</p>
		</li>
		<li class="mt-3">
			<p><b>PREVENÇÃO</b></p>
			<p>Promovemos a minimização da produção de resíduos e utilização de recursos, com o objetivo da conservação da natureza e dos recursos, através da prática diária dos 3 Rs: <b>Reduzir</b>, <b>Reutilizar</b> e <b>Reciclar</b>.</p>
		</li>
		<li class="mt-3">
			<p><b>REDUZIR</b></p>
			<p>Evitamos o consumo desnecessário de produtos e recursos com o objetivo de diminuir a quantidade de lixo gerado e recursos consumidos.</p>
			<p>O que fazemos:</p>
			<ul>
				<li>
					Energia:
					<ul>
						<li>Racionalização do uso de equipamentos de climatização: ligação apenas durante o horário de funcionamento dos pontos de venda, com utilização moderado dos mesmos; não utilização dos aparelhos de climatização com as portas abertas.</li>
						<li>Sempre que possível utilização da iluminação natural.</li>
						<li>Utilização de luminárias de baixo consumo tipo LED.</li>
						<li>Verificação de que não foram deixadas luzes acesas aquando do encerramento dos pontos de venda.</li>
						<li>Desligamos o computador e demais equipamentos depois de cada dia de trabalho (desligar o botão STAND BY).</li>
					</ul>
				</li>
				<li>
					Papel:
					<ul>
						<li>Impressão de versões em escala reduzida (mais de uma página por folha) e impressão de folhas apenas quando extremamente necessário.</li>
						<li>Evitamos imprimir e fotocopiar apenas num dos lados das folhas.</li>
						<li>Reutilizamos as folhas para impressão e rascunho.</li>
						<li>Utilizamos o suporte informático como forma de envio e análise de documentos e como forma preferencial de comunicação com fornecedores, clientes, parceiros institucionais, em detrimento de correio normal e fax.</li>
					</ul>
				</li>
				<li>
					Água:
					<ul>
						<li>Minimizamos os consumos de água através da constante monotorização das fontes consumidoras e equipamentos consumidores de água (casas de banho, torneiras, biseladoras e outros equipamentos básicos e de limpeza).</li>
					</ul>
				</li>
			</ul>
		</li>
		<li class="mt-3">
			<p><b>REUTILIZAR</b></p>
			<p>Damos utilidade e reutilizamos materiais e produtos.</p>
			<p>O que fazemos:</p>
			<ul>
				<li>Reutilização de papel impresso para novas impressões e para folhas de rascunhos.</li>
				<li>Reutilização de caixas e embalagens para expedição de encomendas.</li>
				<li>Utilização de tinteiros reciclados, devolvendo os antigos.</li>
				<li>Reutilização e arranjo frequente de equipamentos elétricos e eletrónicos.</li>
			</ul>
		</li>
		<li class="mt-3">
			<p><b>RECICLAR</b></p>
			<p>Temos como constante preocupação a separação do lixo orgânico do reciclável para posterior reencaminhamento.</p>
			<p>O que fazemos:</p>
			<ul>
				<li>Separamos internamente os resíduos no contentor adequado:</li>
				<li>Contentor Verde: Vidro</li>
				<li>Contentor Amarelo: Plástico/Metal</li>
				<li>Contentor Azul: Papel/Cartão</li>
				<li>Contentor Cinza/Branco: Indiferenciados</li>
				<li>Sendo o microplástico, um dos principais desperdícios da nossa atividade, temos especial atenção em que todos os detritos sejam reencaminhados para o respetivo ecoponto, fazendo a sua separação por processo de filtragem de forma a evitar a contaminação dos lençóis freáticos e encaminhamento para o oceano.</li>
				<li>Não enviamos papel para reciclar com agrafos, clipes ou elásticos.</li>
			</ul>
		</li>
		<li class="mt-3">
			<p><b>CONSCIÊNCIA</b></p>
			<p>Temos consciência de que atitudes simples e escolhas conscientes no nosso dia-a-dia podem ajudar a garantir um futuro melhor para todos Nós!</p>
			<p>Faz parte da nossa missão e valores a adoção de boas práticas que contribuem para um mundo melhor, diminuição da pegada ecológica, poupança dos recursos, e que vão ao encontro das preocupações dos consumidores.</p>
		</li>
	</ol>
</div>
