import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'root'
})
export class NotLoginGuard implements CanActivate {

	constructor(private storageService: StorageService, private router: Router) { }

	canActivate() {
		let user = this.storageService.get('user');

		if (!user) {
			return true;
		}

		this.router.navigateByUrl('/profile');
		return false;
	}
}
