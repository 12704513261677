<section #appointment>
	<div class="container">
		<div class="mx-3 mx-md-5">
			<div class="row">
				<div class="col-12 col-md-6">
					<div class="pub">
						<a class="link" target="_blank" href="https://teste-visual.opticaslince.com">
							<div class="slogan p-3">Teste aqui a sua visão gratuitamente</div>
						</a>
					</div>
				</div>
				<div class="col-12 col-md-6">
					<div class="title mt-4 mt-md-0 mb-4">Agendar Consulta</div>
					<form [formGroup]="appointmentForm">
						<div class="row">
							<div class="mb-3 col-12">
								<input type="text" class="form-control" placeholder="Nome" formControlName="name" />
							</div>
						</div>
						<div class="row">
							<div class="mb-3 col">
								<input type="text" class="form-control" placeholder="E-mail" formControlName="email" />
							</div>
						</div>
						<div class="row">
							<div class="mb-3 col-6">
								<input type="text" class="form-control" placeholder="Telemóvel" formControlName="phone" />
							</div>
							<div class="mb-3 col-6">
								<select formControlName="store_id" (ngModelChange)="selectStore($event)" class="form-control" placeholder="Selecione uma loja">
									<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione uma loja</option>
									<option *ngFor="let store of stores" [ngValue]="store.id">
										{{ store.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="mb-3 col-12">
								<select formControlName="specialty_id" (ngModelChange)="selectSpecialty($event)" class="form-control" placeholder="Selecione uma especialidade">
									<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione uma especialidade</option>
									<option *ngFor="let specialty of specialties" [ngValue]="specialty.id">
										{{ specialty.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="row">
							<div class="mb-3 col-6">
								<input type="text" class="form-control" placeholder="Escolha a data" onclick="(this.type='date')" onblur="(this.type='text')" formControlName="date" min="{{datemin | date:'yyyy-MM-dd'}}" />
							</div>
							<div class="mb-3 col-6">
								<input type="text" class="form-control" placeholder="Período Preferível" onclick="(this.type='time')" onblur="(this.type='text')" formControlName="time" />
							</div>
						</div>
						<div class="row">
							<div class="col">
								<textarea class="form-control" rows="2" placeholder="Informação Adicional" formControlName="info"></textarea>
							</div>
						</div>
						<div class="my-5">
							<button [disabled]="appointmentForm.invalid" type="submit" class="btn btn-lg btn-more px-5" (click)="send()">Enviar</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>
