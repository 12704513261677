import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-orders',
	templateUrl: './orders.component.html',
	styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
	@ViewChild('accordion') accordion: NgbAccordion;

	user: User;
	subscriptionUser: Subscription;

	orders: any[];
	selectedOrder: any = null;
	orderStep: number = 0;

	constructor(private userService: UserService, private orderService: OrderService, private router: Router) {
	}

	async ngOnInit() {
		const user = await this.userService.getUser();

		if (user) {
			this.user = user;
		}

		this.subscriptionUser = this.userService.userObservable().subscribe(data => {
			if (data) this.user = data;
		});

		this.userService.authenticate();

		this.load();
	}

	ngOnDestroy() {
		this.subscriptionUser.unsubscribe();
	}

	changeOrder(id: number, accordion?: boolean) {
		this.selectedOrder = null;

		this.orderService.one(id).subscribe((data: any) => {
			this.selectedOrder = data.data;
			let target = "orders";

			switch (this.selectedOrder.status) {
				case 'Submetida':
					this.orderStep = 1;
					break;
				case 'Paga':
					this.orderStep = 2;
					break;
				case 'Enviada':
					this.orderStep = 3;
					break;
				case 'Entregue':
					this.orderStep = 4;
					break;
				case 'Anulada':
					this.orderStep = 5;
					break;
				default:
					this.orderStep = 0;
					break;
			}

			if (accordion) {
				target = id + "-header";
			}

			this.accordion.expand(String(id));
		});
	}

	load() {
		this.orderService.all().subscribe((data: any) => {
			if (data.status == 'OK') {
				this.orders = data.data;

				if (this.orders.length) {
					this.changeOrder(this.orders[0].id);
				}
			}
		});
	}

	toggleAccordian(event: any) {
		if (event.nextState) {
			this.changeOrder(event.panelId, true);
		}
	}

	private handleError(error: any) {
		console.error('Error processing action', error);
	}

	pay() {
		this.router.navigate(['/payment/', this.selectedOrder.id]);
	}

	logout() {
		this.userService.logout();
	}
}
