<div class="container">
	<h3 class="title text-center">Serviços Clínicos Gratuitos</h3>

	<div class="row mt-5 mx-2 mx-md-5">
		<div class="col">
			<div class="service" style="background-image: url('/assets/optometria.png')">
				<span class="btn btn-lg btn-more px-5 name">Optometria</span>
			</div>
			<div class="description">Ciência que estuda o sistema visual. Deteta, analisa e compensa problemas visuais prescrevendo lentes, ajudas visuais ou terapia visual.</div>
		</div>
		<div class="col">
			<div class="service" style="background-image: url('/assets/contactologia.png')">
				<span class="btn btn-lg btn-more px-5 name">Contactologia</span>
			</div>
			<div class="description">Especialidade da optometria que trata erros refrativos com adaptação da melhor lente de contacto para cada indivíduo.</div>
		</div>
		<div class="col">
			<div class="service" style="background-image: url('/assets/tonometria.png')">
				<span class="btn btn-lg btn-more px-5 name">Tonometria</span>
			</div>
			<div class="description">Processo de medição da pressão interna do globo ocular. Deve ser um exame de rotina em todas as consultas, especialmente em idosos e pacientes com suspeitas de glaucoma.</div>
		</div>
	</div>

	<div class="row mt-5 mx-2 mx-md-5">
		<div class="col">
			<div class="service" style="background-image: url('/assets/queratometria.png')">
				<span class="btn btn-lg btn-more px-5 name">Queratometria</span>
			</div>
			<div class="description">Medição do raio de curvatura da córnea, indispensável para adaptação de lentes de contacto. Exame indolor</div>
		</div>
		<div class="col">
			<div class="service" style="background-image: url('/assets/avaliacao_cromatica.png')">
				<span class="btn btn-lg btn-more px-5 name">Avaliação Cromática</span>
			</div>
			<div class="description">Exame não invasivo e indolor, que deteta anomalias na visão das cores (daltonismo).</div>
		</div>
		<div class="col">
			<div class="service" style="background-image: url('/assets/retinografia.png')">
				<span class="btn btn-lg btn-more px-5 name">Retinografia</span>
			</div>
			<div class="description">Técnica de exame que consiste em observar e registar fotografias da retina, do nervo ótico e do fundo do olho. Exame simples, indolor e que dura alguns minutos. Diagnóstico e acompanhamento de algumas doenças oftalmológicas que podem afetar a retina e o nervo ótico.</div>
		</div>
	</div>

	<app-appointment></app-appointment>
</div>
