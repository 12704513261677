import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class NewsService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get("news/all", { id: this.api.MERCHANT_ID });
	}

    one(id: any) {
        return this.api.get( 'news/one', { id: id });
    }
}
