import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class CountryService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get("country/all", {merchant_id: this.api.MERCHANT_ID}, true);
	}

	one(id: number) {
		return this.api.get("address/one", {id, merchant_id: this.api.MERCHANT_ID}, true);
	}
}
