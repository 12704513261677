import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BannerService } from 'src/app/services/banner.service';
import { NewsService } from 'src/app/services/news.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	@HostListener('window:resize', ['$event']) onResize(event: Event) {
		this.checkIfMobile();
	}

	public features: any;
	public productFeature: any[] = [];
	public productFeatureLength: number[];
	public banners: any[];
	public news: any[];
	public productPerSlide: number = 4;
	public isMobile: boolean = false;
	public isDesktop: boolean = false;

	constructor(private productService: ProductService, private bannerService: BannerService, private newsService: NewsService, private router: Router, public _sanitizer: DomSanitizer) { }

	async ngOnInit() {
		this.checkIfMobile();

		if (document.body.offsetWidth > 1200) {
			this.productPerSlide = 4;
		} else if (document.body.offsetWidth > 768) {
			this.productPerSlide = 3;
		} else if (document.body.offsetWidth > 480) {
			this.productPerSlide = 2;
		} else {
			this.productPerSlide = 1;
		}

		await this.getBanners();
		await this.getProductFeatures();
		this.getNews();
	}

	checkIfMobile() {
		this.isMobile = window.innerWidth <= 767.98;
		this.isDesktop = window.innerWidth >= 1400;
	}

	async getBanners() {
		return new Promise((resolve) => {
			this.bannerService.all().subscribe((data: any) => {
				if (data.status === "OK") {
					if (this.isMobile) {
						this.banners = data.data.filter((item: any) => {
							return item.mobile_image !== null || item.video_url;
						});
					} else {
						this.banners = data.data;
					}
				}
				resolve(true);
			});
		});
	}

	async getProductFeatures() {
		return new Promise((resolve) => {
			this.productService.feature().subscribe((data: any) => {
				this.productFeature = data.data;
				this.productFeatureLength = Array.from(Array(Math.ceil(this.productFeature.length / this.productPerSlide)).keys());
				resolve(true);
			});
		});
	}

	favorite(product: any) {
		this.productService.favorite(product.id).subscribe((data: any) => {
			if (data.status === "OK") {
				product.favorite = !product.favorite;
			}
		});
	}

	getNews() {
		this.newsService.all().subscribe((data: any) => {
			if (data.status === "OK") {
				this.news = data.data;
			}
		});
	}

	openWoman() {
		this.router.navigate(['/products'], { queryParams: { category_id: 351, subcategory_id: 353 } });
	}

	openKid() {
		this.router.navigate(['/products'], { queryParams: { category_id: 350, subcategory_id: 494 } });
	}

	openMan() {
		this.router.navigate(['/products'], { queryParams: { category_id: 350, subcategory_id: 493 } });
	}

    getTitlePathAsString(type: string, item: any) {
        return type + '/' + item.id + '/' + item.title.replace(/ /g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    }
}
