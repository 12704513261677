import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "src/app/models/User";
import { ApiService } from "src/app/services/api.service";
import { StorageService } from "src/app/services/storage.service";
import { UserService } from "src/app/services/user.service";
import { UtilsService } from "src/app/services/utils.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
	public loginForm: FormGroup;
	public user: User;
	public loading = false;

	constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private userService: UserService, private storageService: StorageService, public utilsService: UtilsService) {
		this.loginForm = this.formBuilder.group({
			email: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required, Validators.minLength(6)]]
		});
	}

	ngOnInit(): void {}

	login() {
		if (this.loginForm.valid) {
			this.utilsService.showLoading();
			this.userService.login(this.loginForm.value).subscribe(
				async (data: any) => {
					this.utilsService.hideLoading();
					if (data.code === 200) {
						this.storageService.set("user", data.data);
						this.user = data.data;
						this.userService.setUser(this.user);
						this.loginForm.reset();
						this.router.navigate([""]);
					} else {
						this.utilsService.showAlert("Erro", "Email ou password são inválidos!", "error");
					}
				},
				() => {
					this.utilsService.hideLoading();
				}
			);
		}
	}

	register() {
		this.router.navigate(["/register"]);
	}

	async loginFirebase(type: "Facebook" | "Google") {
		try {
			let data = type === "Facebook" ? await this.userService.facebook() : await this.userService.google();
			const email = data.user.email;
			const name = data.user.displayName;
			this.userService.verifyemail(email).subscribe((data: any) => {
				if (data.status === "OK") {
					this.userService.verifytype(email).subscribe(async (data: any) => {
						if (data.status === "OK") {
							this.storageService.set("user", data.data);
							this.user = data.data;
							this.userService.setUser(this.user);
							this.loginForm.reset();
							this.router.navigate(["/"]);
						} else {
							this.utilsService.showAlert("Erro", "Este email já se encontra registado, se não sabe a sua password faça recuperar password.", "error");
						}
					});
				} else {
					var data: any = {
						status: "1",
						email,
						name,
						phone: "",
						password: ""
					};
					this.userService.register(data, 2).subscribe((data: any) => {
						if (data.status === "OK") {
							this.userService.verifyemail(email).subscribe((data: any) => {
								if (data.status === "OK") {
									this.userService.verifytype(email).subscribe(async (data: any) => {
										if (data.status === "OK") {
											this.storageService.set("user", data.data);
											this.user = data.data;
											this.userService.setUser(this.user);
											this.loginForm.reset();
											this.router.navigate(["/"]);
										}
									});
								}
							});
						}
					});
				}
			});
		} catch (err) {
			console.log(err);
		}
	}

	async recovery() {
		const { value: email } = await Swal.fire({
			title: "Recuperar password",
			input: "email",
			inputLabel: "Insira o seu email",
			inputPlaceholder: "Email",
			validationMessage: "Insira um email válido",
			confirmButtonColor: '#afd43d'
		});

		if (email) {
			this.utilsService.showLoading();
			this.userService.recovery(email).subscribe((data: any) => {
				this.utilsService.hideLoading();
				if (data.status === "OK") {
					this.utilsService.showAlert("Sucesso", "A password foi enviada para o seu email!", "success");
				} else {
					this.utilsService.showAlert("Erro a recuperar password", "Por favor confirme que o email está correto.", "error");
				}
			});
		}
	}
}
