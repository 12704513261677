<div class="container px-5">
	<h3 class="title text-center">Código de Boa Conduta, para a Prevenção e Combate ao Assédio no trabalho</h3>

	<h5 class="mt-5 mx-5">Preâmbulo</h5>

	<p class="mx-5">
		<b>ÓPTICAS LINCE, S.A.</b>, dando cumprimento ao previsto na Lei nº 73/2017 de 16 de agosto (retificada pela declaração de Retificação nº 28/2017 de 02 de Outubro) adotou o presente Código de Boa Conduta para Prevenção e Combate ao Assédio no Trabalho, pretendendo assim que o mesmo seja uma base orientadora para todos os que exercem funções na Identificação da empresa, através de contrato
		de trabalho, em regime de estágio ou outra.
	</p>

	<h5 class="mt-5 mx-5">CLÁUSULA 1.ª</h5>

	<p class="mx-5">O "Código de Boa Conduta para a Prevenção e Combate ao Assédio no Trabalho" estabelece linhas de orientação em matéria de conduta profissional relativa à prevenção e combate ao assédio para todos aqueles que exercem funções ou atividades profissionais na <b>ÓPTICAS LINCE, S.A.</b>.</p>

	<h5 class="mt-5 mx-5">CLÁUSULA 2.ª</h5>

	<ol class="mx-5">
		<li>É proibida a prática de assédio</li>
		<li>Entende-se por “assédio” o comportamento indesejado, nomeadamente o baseado em fator de discriminação, praticado aquando do acesso ao emprego ou no próprio emprego, trabalho ou formação profissional, com o objetivo ou o efeito de perturbar ou constranger a pessoa, afetar a sua dignidade, ou de lhe criar um ambiente intimidativo, hostil, degradante, humilhante ou desestabilizador</li>
		<li>Constitui “assédio sexual” o comportamento indesejado de carácter sexual, sob forma verbal, não-verbal ou física, com o objetivo ou o efeito referido no número anterior</li>
		<li>"assédio" é caracterizado pela intencionalidade e pela repetição</li>
	</ol>

	<h5 class="mt-5 mx-5">CLÁUSULA 3.ª</h5>

	<p class="mx-5">A <b>ÓPTICAS LINCE, S.A.</b> tem uma política de “tolerância zero” ao assédio relacionado com o trabalho, incluindo trabalhadores, voluntários, clientes, fornecedores e utentes, qualquer que seja o meio utilizado e mesmo que ocorra fora do local de trabalho.</p>

	<h5 class="mt-5 mx-5">CLÁUSULA 4.ª</h5>

	<ol class="mx-5">
		<li>O/A trabalhador(a) que considere estar a ser alvo de assédio no local de trabalho, ou por parte de pessoas com as quais tem relações profissionais, deve reportar a situação à Administração das ÓPTICAS LINCE, S.A., que tratará a mesma de forma confidencial, imparcial, eficiente, célere e com salvaguarda do princípio de presunção da inocência.</li>
		<li>
			O/A denunciante e as testemunhas por si indicadas, a menos que atuem com dolo, não podem ser sancionados disciplinarmente, com base em declarações ou factos constantes dos autos de processo, judicial ou contraordenacional, desencadeado por assédio até decisão final, transitada em julgado, sem prejuízo do exercício do direito ao contraditório por parte daquele a quem o assédio é
			imputado.
		</li>
	</ol>

	<h5 class="mt-5 mx-5">CLÁUSULA 5.ª</h5>

	<ol class="mx-5">
		<li>A <b>ÓPTICAS LINCE, S.A.</b> divulga a existência do presente “Código de Boa Conduta para a Prevenção e Combate ao Assédio no Trabalho” junto dos seus trabalhadores, voluntários, clientes, fornecedores e utentes mediante a afixação do presente Código em locais visíveis em todas as suas instalações, assim como o divulga no seu site.</li>
		<li>Compete à Administração da <b>ÓPTICAS LINCE, S.A.</b>, a implementação de ações concretas com vista à identificação do potencial e de fatores de risco para a ocorrência de assédio no local de trabalho.</li>
		<li>
			As formas que a <b>ÓPTICAS LINCE, S.A.</b> pode adotar para identificar o potencial para a ocorrência de assédio no local de trabalho incluem, entre outras:
			<ol>
				<li>Conolta regular aos/às trabalhadores/as, que garanta o anonimato das respostas, avaliando ou identificando fatores que aumentem o risco de assédio.</li>
				<li>Consulta regular aos/às trabalhadores/as, que garanta o anonimato das respostas, averiguando a ocorrência de potenciais casos de assédio.</li>
				<li>Consulta regular aos responsáveis e chefias diretas.</li>
				<li>Instituição da prática de entrevistas de saída de emprego aos trabalhadores em processo de saída voluntária.</li>
			</ol>
		</li>

		<li>A <b>ÓPTICAS LINCE, S.A.</b> deve instaurar procedimento disciplinar sempre que tiver conhecimento de alegadas situações de assédio no trabalho.</li>
	</ol>

	<h5 class="mt-5 mx-5">CLÁUSULA 6.ª</h5>

	<p class="mx-5">Após aprovação em 15 de Julho de 2019, o presente Código entra em vigor na data da sua divulgação, nomeadamente através da Internet, junto dos diversos Serviços e Instalações da <b>ÓPTICAS LINCE, S.A.</b>.</p>

	<p class="mx-5">Aprovado em Guarda a 15 de Julho de 2019</p>

	<p class="mx-5">Pela Administração</p>
</div>
