<div class="container">
	<h4 class="title text-center">Favoritos</h4>

	<div class="products">
		<div *ngFor="let product of products" class="product mb-4" routerLink="/product/{{ product.id }}">
			<div class="image" style="background-image: url('{{ product.image }}');"></div>
			<div class="detail">
				<div class="name">
					<div>{{ product?.brand?.name }}<span *ngIf="product?.brand?.name"> | </span>{{ product.name }}</div>
					{{ product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}
				</div>
				<div class="like" (click)="$event.stopPropagation(); favorite(product)">
					<img src="assets/icons/like.svg" *ngIf="product.favorite" height="20px" />
					<img src="assets/icons/like2.svg" *ngIf="!product.favorite" height="20px" />
				</div>
			</div>
		</div>
	</div>
</div>
