import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/User';
import { AddressService } from 'src/app/services/address.service';
import { CountryService } from 'src/app/services/country.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
	user: User;
	addresses: any;
	countries: any;
	addAddressIsCollapsed: boolean = true;

	public addressForm = this.formBuilder.group({
		address: '',
		postcode: '',
		city: '',
		country_id: ''
	});

	constructor(private addressService: AddressService, private countryService: CountryService, private formBuilder: FormBuilder, private userService: UserService) { }

	async ngOnInit() {
		const user = await this.userService.getUser();

		if (user) {
			this.user = user;
		}

		this.countryService.all().subscribe((data: any) => {
			if (data.code === 200) {
				this.countries = data.data;
				this.addressForm.get('country_id')?.setValue(this.countries[0].id);
			}
		});

		this.loadAddresses();
	}

	loadAddresses() {
		this.addressService.all().subscribe((data: any) => {
			if (data.code === 200) {
				this.addresses = data.data;
			}
		});
	}

	save() {
		this.addAddressIsCollapsed = true;

		this.addressService.create(this.addressForm.value).subscribe((data: any) => {
			if (data.status == 'OK') {
				this.loadAddresses();

				Swal.fire({
					title: 'Sucesso!',
					text: 'Morada adicionada com sucesso',
					icon: 'success',
					timer: 3000,
					showConfirmButton: false
				});
			}
		});
	}

	changeCountry(event: any) {
		this.addressForm.patchValue({
			country_id: event.target.value
		});
	}

	logout() {
		this.userService.logout();
	}

	remove(id: number) {
		Swal.fire({
			title: 'Apagar Morada!',
			text: 'Tem a certeza que pretende apagar esta morada?',
			icon: 'warning',
			confirmButtonText: "Sim",
			confirmButtonColor: '#afd43d',
			showCancelButton: true,
			cancelButtonText: "Não"
		}).then((result) => {
			if (result.isConfirmed) {
				this.addressService.remove(id).subscribe(() => {
					this.loadAddresses();
				});
			}
		});
	}
}
