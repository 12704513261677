import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-terms-customer-card',
	templateUrl: './terms-customer-card.component.html',
	styleUrls: ['./terms-customer-card.component.css']
})
export class TermsCustomerCardComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}
}
