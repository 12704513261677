import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Meta } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CartService } from "src/app/services/cart.service";
import { ProductService } from "src/app/services/product.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
	selector: "app-product",
	templateUrl: "./product.component.html",
	styleUrls: ["./product.component.css"]
})
export class ProductComponent implements OnInit {
	product: any;
	productForm: FormGroup;

	selectedMeasures: any = null;
	selectedMaterial: any = null;
	selectedColor: any = null;
	selectedColorLens: any = null;

	addictions: any = null;
	axies: any = null;
	balls: any = null;
	curves: any = null;
	cylindricals: any = null;
	diameters: any = null;
	geometries: any = null;
	packs: any = null;

	lensProduct: boolean = false;
	eye: number = 1;
	pack: any = null;
	shareUrl: string = "";
	shareShow: boolean = false;

	selectedImage: any = null;
	selectedImages: any = null;

	formInvalid: boolean = false;

	constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private productService: ProductService, private cartService: CartService, private metaService: Meta) {
		this.route.params.subscribe((params: any) => {
			if (params.id != "undefined") {
				this.load(params.id);
			}
		});

		this.productForm = this.formBuilder.group({
			pack: [null],
			pack_id: [null],
			eye: [null],
			curve: [null],
			curve2: [null],
			diameter: [null],
			diameter2: [null],
			ball: [null],
			ball2: [null],
			cylindrical: [null],
			cylindrical2: [null],
			axle: [null],
			axle2: [null],
			addiction: [null],
			addiction2: [null],
			geometry: [null],
			geometry2: [null],
			version: [null]
		});
	}

	ngOnInit(): void {
		this.productForm.statusChanges.subscribe(newStaus => {
			this.formInvalid = (newStaus == 'INVALID');
		})

		this.productForm.controls["pack"].valueChanges.subscribe((data) => {
			this.productForm.controls['eye'].setValidators(Validators.required);
			this.productForm.controls['eye'].updateValueAndValidity();

			this.pack = data;
			this.product.price = data.value;
			if (this.eye == 1) {
				this.product.price *= 2;
			}
			this.productForm.controls["pack_id"].setValue(data.id);
			this.productForm.controls["pack_id"].updateValueAndValidity();
		});

		this.productForm.controls["eye"].valueChanges.subscribe((data) => {
			if (data) {
				this.eye = data;
				this.product.price = this.pack.value;

				if (this.eye == 1) {
					this.product.price *= 2;
				}

				this.changeToRequired("addiction", this.addictions, this.eye);
				this.changeToRequired("axle", this.axies, this.eye);
				this.changeToRequired("ball", this.balls, this.eye);
				this.changeToRequired("curve", this.curves, this.eye);
				this.changeToRequired("cylindrical", this.cylindricals, this.eye);
				this.changeToRequired("diameter", this.diameters, this.eye);
				this.changeToRequired("geometry", this.geometries, this.eye);
			}
		});

		this.productForm.controls["version"].valueChanges.subscribe((data) => {
			let selected_version = data;
			let version = this.product.versions.find((version: any) => version.id == selected_version);

			if (version.images.length) {
				this.selectedImage = version.images[0];
				this.selectedImages = version.images;

				if (version.color) {
					this.selectedColor = version.color;
				}
				
				if (version.color_lens) {
					this.selectedColorLens = version.color_lens;
				}

				if (version.measures) {
					this.selectedMeasures = version.measures;
				}
			} else {
				this.selectedImage = '';
				this.selectedImages = null;
			}
		});
	}

	load(id: string) {
		this.productService.one(id).subscribe((data: any) => {
			this.product = data.data;
			this.selectedImages = this.product.images;
			this.selectedColor = this.product.color;
			this.selectedColorLens = this.product.color_lens;
			this.selectedMaterial = this.product.material;
			this.selectedMeasures = this.product.measures;

			if (this.product.versions.length) {
				this.productForm.controls['version'].setValidators(Validators.required)
				// this.productForm.controls["version"].setValue(this.product.versions[0].id);
			}

			this.metaService.addTags([
				{ name: 'description', content: this.product.description },
				{ name: 'image', content: this.product.image },
				{ property: 'og:title', content: this.product.name },
				{ property: 'og:description', content: this.product.description },
				{ property: 'og:type', content: 'article' },
				{ property: 'og:url', content: environment.url_base + "product/" + id },
				{ property: 'og:image', content: this.product.image },
				{ name: 'twitter:card', content: 'summary' },
				{ property: 'twitter:url', content: environment.url_base + "product/" + id },
				{ name: 'twitter:title', content: this.product.name },
				{ name: 'twitter:description', content: this.product.description },
				{ name: 'twitter:image', content: this.product.image }
			]);

			this.addictions = this.product.addictions;
			this.axies = this.product.axies;
			this.balls = this.product.balls;
			this.curves = this.product.curves;
			this.cylindricals = this.product.cylindricals;
			this.diameters = this.product.diameters;
			this.geometries = this.product.geometries;
			this.packs = this.product.packs;

			if (this.addictions.length || this.axies.length || this.balls.length || this.curves.length || this.cylindricals.length || this.diameters.length || this.geometries.length) {
				this.lensProduct = true;
				this.productForm.controls['pack'].setValidators(Validators.required);
			}

			this.shareUrl = "https://opticae.online/product/share?id=" + id + '&s=' + window.btoa(encodeURIComponent(environment.url_base + "product/" + id));
		});
	}

	changeToRequired(value: string, array: any, eye: any) {
		this.productForm.controls[value].clearValidators();
		this.productForm.controls[value + "2"].clearValidators();

		if (array.length) {
			switch (eye) {
				case '1':
					this.productForm.controls[value].setValidators(Validators.required);
					this.productForm.controls[value + "2"].setValidators(Validators.required);

					if (array.length == 1) {
						this.productForm.controls[value].setValue(array[0].value);
						this.productForm.controls[value + "2"].setValue(array[0].value);
					}
					break;
				case '2':
					this.productForm.controls[value].setValidators(Validators.required);

					if (array.length == 1) {
						this.productForm.controls[value].setValue(array[0].value);
					}

					this.productForm.controls[value + "2"].setValue(null);
					this.productForm.controls[value + "2"].clearValidators();
					break;
				case '3':
					this.productForm.controls[value + "2"].setValidators(Validators.required);

					if (array.length == 1) {
						this.productForm.controls[value + "2"].setValue(array[0].value);
					}

					this.productForm.controls[value].setValue(null);
					this.productForm.controls[value].clearValidators();
					break;
			}
		}

		this.productForm.controls[value].updateValueAndValidity();
		this.productForm.controls[value + "2"].updateValueAndValidity();
	}

	addCart() {
		if (this.productForm.valid) {
			if (this.productForm.controls["version"].value) {
				this.productForm.controls["version"].setValue(String(this.productForm.controls["version"].value));
			}

			this.cartService.add(this.product, 1, this.productForm.value);
			Swal.fire({
				title: "Produto adicionado com sucesso",
				icon: "success",
				showClass: {
					popup: "animate__animated animate__fadeInDown"
				},
				hideClass: {
					popup: "animate__animated animate__fadeOutUp"
				},
				confirmButtonText: "Ir para o carinho",
				confirmButtonColor: '#afd43d',
				showCancelButton: true,
				cancelButtonText: "Continuar a comprar"
			}).then((result) => {
				if (result.isConfirmed) {
					this.router.navigate(["checkout"]);
				}
			});
		} else {
			Object.keys(this.productForm.controls).forEach((key: string) => {
				const controlErrors = this.productForm.get(key)?.errors;
				if (controlErrors != null) {
					Object.keys(controlErrors).forEach((errorKey: string) => {
						console.log(key, errorKey);
					});
				}
			});
		}
	}

	favorite(product: any) {
		this.productService.favorite(product.id).subscribe((data: any) => {
			if (data.status === "OK") {
				product.favorite = !product.favorite;

				Swal.fire({
					title: "Sucesso!",
					text: product.favorite ? "Produto adicionado aos favoritos" : "Produto retirado dos favoritos",
					icon: "success",
					confirmButtonText: "OK",
					confirmButtonColor: "#afd43d"
				});
			} else {
				if (data.code == 404) {
					Swal.fire({
						title: "Deve iniciar sessão para adicionar produtos aos favoritos",
						icon: "error",
						showClass: {
							popup: "animate__animated animate__fadeInDown"
						},
						hideClass: {
							popup: "animate__animated animate__fadeOutUp"
						},
						confirmButtonText: "Iniciar Sessão",
						confirmButtonColor: '#afd43d',
						showCancelButton: true,
						cancelButtonText: "Fechar"
					}).then((result) => {
						if (result.isConfirmed) {
							this.router.navigate(["/login"]);
						}
					});
				}
			}
		});
	}

	share() {
		this.shareShow = !this.shareShow;
	}

	changeImage(image: string) {
		this.selectedImage = image;
	}
}
