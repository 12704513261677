<section>
	<div class="container">
		<h4 class="title text-center">Pagamento</h4>
	</div>

	<div class="container">
		<div class="box">
			<section id="pay" class="fdb-block">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-6 order-resume" *ngIf="selectOrder">
							<h3>Resumo</h3>
							<i class="icon-dots"></i>
							<br />
							<div class="row align-items-center" *ngFor="let product of selectOrder.items"
								style="margin-bottom: 20px">
								<div class="col col-3">
									<img [src]="product.image" style="width: 100%" />
								</div>
								<div class="col col-5">{{ product?.brand?.name }}<span *ngIf="product?.brand?.name"> |
									</span>{{ product.name }}</div>
								<div class="col col-4 text-right">{{ product.quantity }} x {{ product.price | currency:
									'EUR' }}</div>
							</div>
							<div class="row"
								style="border: 1px solid #f9f9f9; margin-bottom: 20px; background: #f9f9f9; padding-top: 10px; padding-bottom: 10px">
								<div class="col col-12">
									<!-- <div class="row text-right">
							<div class="col col-8">
								Sub-total:
							</div>
							<div class="col col-4">
								{{selectOrder.sub_total | currency: 'EUR' }}
							</div>
						</div> -->
									<div class="row text-right" *ngIf="selectOrder.fee">
										<div class="col col-8">Taxa de envio:</div>
										<div class="col col-4">{{ selectOrder.fee | currency: 'EUR' }}</div>
									</div>
									<!-- <div
                            class="row text-right"
                            *ngIf="selectOrder.coupon_id"
                        >
                            <div class="col col-8">Desconto de cupão:</div>
                            <div class="col col-4">
                                -{{ coupon.amount | currency: 'EUR' }}
                            </div>
                        </div> -->
									<div class="row text-right" *ngIf="selectOrder.used_amount">
										<div class="col col-8">Desconto de saldo:</div>
										<div class="col col-4">-{{ selectOrder.used_amount | currency: 'EUR' }}</div>
									</div>
									<div class="row text-right font-weight-bold">
										<div class="col col-8">
											<span style="color: #444; font-size: 20px">Total:</span>
										</div>
										<div class="col col-4">
											<span style="color: #444; font-size: 20px">{{ selectOrder.total | currency:
												'EUR' }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6">
							<h3>Pagamento</h3>
							<i class="icon-dots"></i>
							<br />
							<div #paymentForm></div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</section>