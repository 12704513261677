import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-change-profile',
	templateUrl: './change-profile.component.html',
	styleUrls: ['./change-profile.component.css']
})
export class ChangeProfileComponent implements OnInit {
	public user: User;
	public changeProfileForm = this.formBuilder.group({
		name: '',
		email: '',
		phone: '',
		nif: '',
	});

	constructor(private apiService: ApiService, private storageService: StorageService, private formBuilder: FormBuilder, private userService: UserService) {
		this.user = this.storageService.get("user");
		this.apiService.user = this.user;
	}

	async ngOnInit() {
		this.getUser();
	}

	save() {
		this.userService.edit(this.changeProfileForm.value).subscribe((data: any) => {
			if (data.status == 'OK') {
				this.userService.setUser(data.data);
				Swal.fire({
					title: 'Sucesso!',
					text: 'Perfil alterado com sucesso',
					icon: 'success',
					timer: 3000,
					showConfirmButton: false
				});

			}
		});
	}

	async getUser() {
		this.fillProfile();
	}

	fillProfile() {
		this.changeProfileForm.controls['name'].setValue(this.user.name);
		this.changeProfileForm.controls['email'].setValue(this.user.email);
		this.changeProfileForm.controls['phone'].setValue(this.user.phone);
		this.changeProfileForm.controls['nif'].setValue(this.user.nif);
	}

	logout() {
		this.userService.logout();
	}
}
