<section>
	<div class="container">
		<h4 class="title text-center">Checkout</h4>
	</div>

	<div class="container">
		<div class="box p-3 p-md-5 mx-xl-5">
			<div class="row" *ngIf="cart.items.length == 0">
				<div class="col">
					<p class="lead mb-0">O seu carrinho está vazio!</p>
				</div>
			</div>
			<div class="row" *ngIf="cart.items.length">
				<div class="col-md-12">
					<h3 class="mb-5">Resumo</h3>

					<table class="table">
						<tbody>
							<tr class="row" *ngFor="let item of cart.items">
								<td class="col-sm-6 col-md-2 d-flex align-items-center">
									<span class="border">
										<img [src]="item.version ? item.version.images[0] : item.product.images[0]"
											alt="{{ item.product.name }}" class="img-fluid" />
									</span>
								</td>
								<td class="col-sm-6 col-md d-flex flex-column justify-content-center">
									<div>{{ item.product.name }} <span *ngIf="item.version">| {{item.version.color}}</span></div>
									<div *ngIf="item.product.brand.name"><small>{{ item.product.brand.name }}</small>
									</div>
								</td>
								<td class="col d-flex align-items-center">
									{{ item.quantity }} x
									{{ item.product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}
								</td>
								<td class="col-auto d-flex align-items-center">
									<select [(ngModel)]="item.quantity" class="form-control" name="quantity"
										(ngModelChange)="recalculate()" required>
										<option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9]" [ngValue]="i">
											{{ i }}
										</option>
									</select>
								</td>
								<td class="col-auto d-flex align-items-center">
									<button class="btn" (click)="removeItem(item.product_id)">
										<i class="fa fa-trash"></i>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="col-md-12">
					<h3 class="my-5">Checkout</h3>
					<form [formGroup]="checkoutForm">
						<div class="row">
							<div class="col-md-4">
								<h4 class="my-3">Dados Pessoais</h4>
								<div class="mb-3">
									<label class="form-label">Nome</label>
									<input type="text" class="form-control" formControlName="name" />
								</div>
								<div class="mb-3">
									<label class="form-label">Email</label>
									<input type="email" class="form-control" formControlName="email" />
								</div>
								<div class="mb-3">
									<label class="form-label">Telemóvel</label>
									<input type="tel" class="form-control" formControlName="phone" />
								</div>
								<div class="mb-3">
									<label class="form-label">NIF</label>
									<input type="tel" class="form-control" formControlName="nif" />
									<p>Para emissão de fatura com NIF e em compras de valor igual ou superior a 1.000 € o preenchimento é obrigatório. No caso de não preenchimento a fatura é emitida sem NIF.</p>
								</div>
							</div>
							<div class="col-md-4">
								<h4 class="my-3">Método de Entrega</h4>
								<div class="form-group">
									<div *ngFor="let delivery_type of delivery_types" class="form-check">
										<input type="radio" id="delivery_type-{{ delivery_type.id }}"
											formControlName="delivery_type" value="{{ delivery_type.id }}"
											class="form-check-input" />
										<label class="form-check-label" for="delivery_type-{{ delivery_type.id }}">{{
											delivery_type.name }}</label>
									</div>
								</div>
								<div *ngIf="checkoutForm.controls['delivery_type'].value === '2'">
									<h4 class="my-3">Loja de Entrega</h4>
									<div class="mb-3">
										<select formControlName="store_id" class="form-control"
											placeholder="Selecione uma loja">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												uma loja</option>
											<option *ngFor="let store of stores" [ngValue]="store.id">
												{{ store.name }}
											</option>
										</select>
									</div>
								</div>
								<div *ngIf="checkoutForm.get('delivery_type')?.value === '1'">
									<h4 class="my-3">Morada de Entrega</h4>
									<div class="mb-3">
										<select class="form-control" formControlName="address_id">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												um opção</option>
											<option value="add">Adicionar Morada</option>
											<option *ngFor="let address of addresses" [ngValue]="address.id">
												{{ address.address }} {{ address.postcode }} {{ address.city }}
											</option>
										</select>
									</div>
									<div *ngIf="checkoutForm.get('address_id')?.value == 'add'">
										<div class="mb-3">
											<label class="form-label">Rua</label>
											<input type="text" class="form-control" formControlName="address" />
										</div>
										<div class="mb-3">
											<label class="form-label">Código-postal</label>
											<input type="text" class="form-control" formControlName="postal" />
										</div>
										<div class="mb-3">
											<label class="form-label">Localidade</label>
											<input type="text" class="form-control" formControlName="city" />
										</div>
									</div>
								</div>
								<div *ngIf="checkoutForm.get('nif')?.value" class="form-group">
									<h4 class="my-3">Morada de Faturação</h4>
									<div class="mb-3">
										<select class="form-control" formControlName="address_fiscal_id">
											<option class="sl-placeholder" [ngValue]="null" [disabled]="true">Selecione
												um opção</option>
											<option value="add">Adicionar Morada</option>
											<option *ngFor="let address of addresses" [ngValue]="address.id">
												{{ address.address }} {{ address.postcode }} {{ address.city }}
											</option>
										</select>
									</div>
									<div *ngIf="checkoutForm.get('address_fiscal_id')?.value == 'add'">
										<div class="mb-3">
											<label class="form-label">Rua</label>
											<input type="text" class="form-control" formControlName="address_fiscal" />
										</div>
										<div class="mb-3">
											<label class="form-label">Código-postal</label>
											<input type="text" class="form-control" formControlName="postal_fiscal" />
										</div>
										<div class="mb-3">
											<label class="form-label">Localidade</label>
											<input type="text" class="form-control" formControlName="city_fiscal" />
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<h4 class="my-3">Método de Pagamento</h4>
								<div class="mb-3">
									<div *ngFor="let paymethod of payments" class="form-check">
										<input type="radio" id="paymethod-{{ paymethod.id }}"
											formControlName="payment_type" value="{{ paymethod.id }}"
											class="form-check-input" />
										<label class="form-check-label" for="paymethod-{{ paymethod.id }}">{{
											paymethod.name }}</label>
									</div>
								</div>
								<div *ngIf="checkoutForm.controls['payment_type'].value == 1">
									<div class="mb-3">
										<label class="form-label">Número MBWay</label>
										<input type="text" class="form-control" formControlName="mbway" />
									</div>
								</div>
								<h4 class="my-3">Saldo e Cupões</h4>
								<div class="form-group">
									<div class="form-check">
										<input type="checkbox" id="customRadio2" formControlName="use_balance"
											class="form-check-input" />
										<label class="form-check-label" for="customRadio2">Descontar saldo</label>
									</div>
								</div>
								<div class="form-group my-2" *ngIf="checkoutForm.controls['use_balance'].value">
									<div class="row row-centered">
										<div *ngIf="card.value > 0" class="col">
											<input type="number" min="0" [max]="card?.value" class="form-control"
												formControlName="discount" />
										</div>
										<div class="col-auto">
											<p class="text-nowrap">
												Saldo disponível:
												<strong>{{ card?.value }}€</strong>
											</p>
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="form-check">
										<input type="checkbox" id="customRadio3" formControlName="use_coupon"
											class="form-check-input" />
										<label class="form-check-label" for="customRadio3">Descontar cupão</label>
									</div>
								</div>
								<div class="form-group my-2" *ngIf="checkoutForm.controls['use_coupon'].value">
									<select formControlName="coupon" class="form-control"
										placeholder="Selecione um cupão">
										<option value=""></option>
										<option *ngFor="let coupon of coupons" [ngValue]="coupon.id">
											#{{ coupon.id }} - {{ coupon.amount }} {{ coupon.amount_type == 'Value' ?
											(coupon.value | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT") : '%' }}
											<span *ngIf="coupon.expires_at"> - Expira em {{ coupon.expires_at | date
												}}</span>
										</option>
									</select>
								</div>
								<div class="row mt-5 fs-6">
									<div class="col">Sub-total</div>
									<div class="col d-flex justify-content-end">{{ cart.total | currency : "EUR" :
										"symbol" : "1.2-2" : "pt-PT" }}</div>
								</div>
								<div *ngIf="checkoutForm.get('delivery')?.value !== null" class="row fs-6">
									<div class="col">Taxa Entrega</div>
									<div class="col d-flex justify-content-end">{{ checkoutForm.get('delivery')?.value |
										currency : "EUR" :
										"symbol" : "1.2-2" : "pt-PT" }}</div>
								</div>
								<div *ngIf="checkoutForm.get('discount')?.value" class="row fs-6">
									<div class="col">Desconto</div>
									<div class="col d-flex justify-content-end">- {{ checkoutForm.get('discount')?.value
										| currency : "EUR" :
										"symbol" : "1.2-2" : "pt-PT" }}</div>
								</div>
								<div *ngIf="checkoutForm.get('discount_coupon')?.value" class="row fs-6">
									<div class="col">Cupão</div>
									<div class="col d-flex justify-content-end">- {{
										checkoutForm.get('discount_coupon')?.value | currency : "EUR" :
										"symbol" : "1.2-2" : "pt-PT" }}</div>
								</div>
								<div class="row mb-5 fs-4">
									<div class="col">Total</div>
									<div class="col d-flex justify-content-end">{{ checkoutForm.get('total')?.value |
										currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}</div>
								</div>
								<div class="row">
									<div class="col">
										<button class="my-2 btn btn-primary"
											[disabled]="checkoutForm.invalid || loading" (click)="checkout()"
											type="submit" style="width: 100%">Pagar</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>