<div class="container">
	<h4 class="title text-center">Alterar Perfil</h4>
</div>

<div class="container">
	<div class="box p-3 p-md-5 mx-md-5">
		<div class="d-md-none">
			<div class="mb-5 list-group">
				<a class="list-group-item list-group-item-action border-0" routerLink="/profile">Meu Perfil</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/orders">Encomendas</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/address">Moradas</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/family">Família</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/loyalty">Fidelização</a>
				<a class="list-group-item list-group-item-action active border-0" routerLink="/change-profile">Dados Pessoais</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/change-password">Password</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">Terminar Sessão</a>
			</div>
		</div>
		<div class="row">
			<div class="d-none d-md-block col-md-auto">
				<div class="list-group">
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/profile">
						<span class="menu-user"><i class="fa fa-user"></i><span class="text">Meu Perfil</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/orders">
						<span class="menu-user"><i class="fa fa-box-open"></i><span class="text">Encomendas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/address">
						<span class="menu-user"><i class="fa fa-home"></i><span class="text">Moradas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/family">
						<span class="menu-user"><i class="fa fa-user-group"></i><span class="text">Família</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/loyalty">
						<span class="menu-user"><i class="fa fa-medal"></i><span class="text">Fidelização</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action active border-0" routerLink="/change-profile">
						<span class="menu-user"><i class="fa fa-user-edit"></i><span class="text">Dados Pessoais</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/change-password">
						<span class="menu-user"><i class="fa fa-key"></i><span class="text">Password</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">
						<span class="menu-user"><i class="fa fa-sign-out"></i><span class="text">Terminar Sessão</span></span>
					</a>
				</div>
			</div>
			<div class="col-md">
				<div class="row">
					<div class="mb-3 col-10 offset-1">
						<form [formGroup]="changeProfileForm" action="">
							<div class="mb-3">
								<label for="">Nome</label>
								<input type="text" class="form-control" placeholder="Nome" formControlName="name" />
							</div>
							<div class="mb-3">
								<label for="">E-mail</label>
								<input type="text" class="form-control" placeholder="E-mail" formControlName="email" />
							</div>
							<div class="mb-3">
								<label for="">Telemóvel</label>
								<input type="number" class="form-control" placeholder="Telemóvel" formControlName="phone" />
							</div>
							<div class="mb-3">
								<label for="">NIF</label>
								<input type="number" class="form-control" placeholder="NIF" formControlName="nif" />
							</div>
							<button class="btn btn-primary" (click)="save()">Guardar</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
