<section>
	<div class="container">
		<h4 class="title text-center">Iniciar Sessão</h4>
	</div>

	<div class="container">
		<div class="box p-4 p-md-5">
			<div class="row align-items-center">
				<div class="offset-md-4 col-md-4">
					<form [formGroup]="loginForm">
						<div class="form-group mb-3">
							<input type="email" class="form-control" formControlName="email" placeholder="E-mail" />
						</div>
						<div class="form-group mb-3">
							<input type="password" #passwordfield class="form-control" formControlName="password" placeholder="Password" />
							<span (click)="passwordfield.type = passwordfield.type == 'password' ? 'text' : 'password'" class="fa fa-fw field-icon toggle-password" [ngClass]="passwordfield.type == 'password' ? ' fa-eye' : ' fa-eye-slash'"></span>
						</div>
						<div class="form-group mt-4">
							<button class="btn btn-lg btn-primary" [disabled]="loginForm.invalid" (click)="login()" type="submit" style="width: 100%">Iniciar Sessão</button>
						</div>
						<div class="form-group mt-4">
							<button class="btn btn-lg btn-primary btn-facebook" (click)="loginFirebase('Facebook')" type="submit" style="width: 100%"><img src="assets/btn-facebook.jpeg" alt="" />Iniciar Sessão com o Facebook</button>
						</div>
						<div class="form-group mt-4">
							<button class="btn btn-lg btn-primary btn-google" (click)="loginFirebase('Google')" type="submit" style="width: 100%"><img src="assets/btn-google.png" alt="" />Iniciar Sessão com a Google</button>
						</div>
					</form>
				</div>
			</div>
			<div class="row align-items-center">
				<div class="offset-md-4 col-md-4 text-center"><p class="my-3">ou</p></div>
			</div>
			<div class="row align-items-center">
				<div class="offset-md-4 col-md-4 align-items-center">
					<div class="form-group">
						<button class="btn btn-primary" (click)="register()" style="width: 100%">Criar Conta</button>
					</div>
				</div>
			</div>
			<br />
			<div class="row align-items-center">
				<div class="offset-md-4 col-md-4 align-items-center">
					<div class="form-group">
						<button class="btn btn-secondary" (click)="recovery()" style="width: 100%">Recuperar Password</button>
					</div>
				</div>
			</div>
			<!-- <ng-template #customLoadingTemplate>
				<div class="custom-class">
					<h3>Loading...</h3>
				</div>
			</ng-template> -->
		</div>
	</div>
</section>
