<div class="container">
	<h3 *ngIf="!apt" class="title text-center">Pontos de venda</h3>

	<p *ngIf="!apt" class="text-light text-justify mt-5 mx-md-5">
		Em cada Loja garantimos um serviço totalmente personalizado. Dispomos de uma equipa de profissionais altamente qualificada e com formação técnica ao mais alto nível repartida pelas diversas áreas de Optometria, Contactologia e Ortóptica, que alia o rigor à qualidade dos produtos e serviços! Para fazer uma avaliação gratuita ao estado da sua visão, escolha a ÓPTICA que lhe for mais
		conveniente, agende marcação ou coloque a questão que gostaria de ver esclarecida através de um dos contactos fornecidos ou do formulário disponibilizado.
	</p>

	<div class="row mt-5 mx-md-5">
		<div class="col-md-6 col-lg-6 col-xl-4" *ngFor="let store of stores">
			<div class="store" style="background-image: url('{{ store.image }}')">
				<div class="gradient">
					<div class="info d-flex flex-column">
						<div *ngIf="store.address" class="item d-flex">
							<div class="item-image">
								<img src="assets/icons/address.svg" height="20px" />
							</div>
							<div class="p-2">
								{{ store.address }}
								<br />
								{{ store.postalcode }} {{ store.city }}
							</div>
						</div>
						<div *ngIf="store.phone" class="item d-flex">
							<div class="item-image">
								<img src="assets/icons/phone.svg" height="20px" />
							</div>
							<div class="p-2">{{ store.phone }}</div>
						</div>
						<div *ngIf="store.mobile" class="item d-flex">
							<div class="item-image">
								<img src="assets/icons/mobile.svg" height="20px" />
							</div>
							<div class="p-2">{{ store.mobile }}</div>
						</div>
						<div *ngIf="store.email" class="item d-flex">
							<div class="item-image">
								<img src="assets/icons/email.svg" height="20px" />
							</div>
							<div class="p-2">
								<a class="btn-link" href="mailto:{{ store.email }}">{{ store.email }}</a>
							</div>
						</div>
					</div>
					<div class="name py-2 px-3" routerLink="/store/{{ store.id }}">
						<div class="store-name">{{ store.name }}</div>
						<div class="more">ver mais</div>
					</div>
				</div>
			</div>
			<div class="description px-3"></div>
		</div>
	</div>
</div>

<app-appointment></app-appointment>
