import { Component, OnInit } from "@angular/core";
import { ProductService } from "src/app/services/product.service";

@Component({
	selector: "app-favorites",
	templateUrl: "./favorites.component.html",
	styleUrls: ["./favorites.component.css"]
})
export class FavoritesComponent implements OnInit {
	products: any;

	constructor(private productService: ProductService) {}

	ngOnInit(): void {
		this.all();
	}

	all() {
		this.productService.favorites().subscribe((data: any) => {
			this.products = data.data;
		});
	}

	favorite(product: any) {
		this.productService.favorite(product.id).subscribe((data: any) => {
			if (data.status === "OK") {
				this.all();
			}
		});
	}
}
