import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-code-conduct',
	templateUrl: './code-conduct.component.html',
	styleUrls: ['./code-conduct.component.css']
})
export class CodeConductComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}
}
