import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { EventsService } from "./events.service";

export class Cart {
	total: number;
	quantity: number;
	items: any[] = [];

	constructor() {
		this.total = 0;
		this.quantity = 0;
		this.items = [];
	}
}

@Injectable({
	providedIn: 'root'
})
export class CartService {
	cart: Cart = new Cart;

	constructor(private storageService: StorageService, private eventsService: EventsService,) {
		let cart = this.storageService.get('cart');

		if (cart) {
			this.cart = cart;
		}
	}

	getCart() {
		return this.cart;
	}

	add(product: any, quantity: number, data: any) {
		let index = this.cart.items.findIndex((p: any) => p.id === product.id);

		if (index === -1) {
			data.quantity = quantity;
			data.id = product.id;
			data.product_id = product.id;
			data.product = product;

			if (data.version) {
				const version = product.versions.find((p: any) => p.id == data.version);
				data.version = version;
			}

			this.cart.items.push({ ...data });
			this.cart.quantity += quantity;
		} else {
			this.cart.items[index].quantity += quantity;
		}

		this.cart.total += data.price * quantity;
		this.cart = this.cart;
        this.eventsService.publish('update:cart', this.cart);
		this.storageService.set('cart', this.cart);
	}

	recalculate() {
		this.cart.quantity = 0;
		this.cart.total = 0;
		this.cart.items.forEach(item => {
			this.cart.quantity += item.quantity;
			this.cart.total += item.product.price * item.quantity;
		});
		this.cart = this.cart;
	}

	remove(id: any) {
		this.cart.items = this.cart.items.filter((p: any) => p.id !== id);
		this.recalculate();
		this.cart = this.cart;
        this.eventsService.publish('update:cart', this.cart);
		this.storageService.set('cart', this.cart);
	}

	clean() {
		this.cart = new Cart;
		this.storageService.set('cart', this.cart);
	}
}
