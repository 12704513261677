<div class="container p-0 px-md-5">
	<h3 class="title text-center"><span class="btn-link" routerLink="/stores">Pontos de venda</span> / {{ store?.name }}</h3>

	<div class="box">
		<div class="row">
			<div *ngIf="store?.specialties.length" class="col">
				<h3>Serviços Clínicos</h3>
				<div class="list-group">
					<div *ngFor="let specialty of store?.specialties" class="list-item-group">
						{{ specialty.name }}
					</div>
				</div>
			</div>
			<div class="col">
				<div class="info d-flex flex-column">
					<div *ngIf="store?.address" class="item d-flex">
						<div class="item-image">
							<img src="assets/icons/address.svg" height="20px" />
						</div>
						<div class="p-2">
							{{ store?.address }}
							<br />
							{{ store?.postalcode }} {{ store?.city }}
						</div>
					</div>
					<div *ngIf="store?.phone" class="item d-flex">
						<div class="item-image">
							<img src="assets/icons/phone.svg" height="20px" />
						</div>
						<div class="p-2">{{ store?.phone }}</div>
					</div>
					<div *ngIf="store?.mobile" class="item d-flex">
						<div class="item-image">
							<img src="assets/icons/mobile.svg" height="20px" />
						</div>
						<div class="p-2">{{ store?.mobile }}</div>
					</div>
					<div *ngIf="store?.email" class="item d-flex">
						<div class="item-image">
							<img src="assets/icons/email.svg" height="20px" />
						</div>
						<div class="p-2"><a class="btn-link" href="mailto:{{ store?.email }}">{{ store?.email }}</a></div>
					</div>
					<div *ngIf="store?.id == 225">
						<div class="pt-2">Horário de Inverno - Novembro a Março</div>
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:00 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:00 - 19:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pt-2">Horário de Verão - Abril a Outubro</div>
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:00 - 20:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:00 - 19:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 226">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">10:00 - 12:30 e 14:00 (3ª e 5ª Feira - 13:30) - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:00 - 14:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 227">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 13:00 e 15:00 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 13:00</span></div>
						<div class="py-0"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pb-2"><span class="px-2">3º Sábado de Cada Mês:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 228">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:00 - 12:30 e 14:00 - 18:30</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 12:00</span></div>
						<div class="py-0"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pb-2"><span class="px-2">4º Sábado de Cada Mês:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 229">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 12:30 e 14:30 - 19:30</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:30 - 13:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 230">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 12:30 e 14:30 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:30 - 12:30</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 231">
						<div class="pt-2">Horário de Inverno - Novembro a Março</div>
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">10:00 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 19:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pt-2">Horário de Verão - Abril a Outubro</div>
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">10:00 - 20:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 19:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 232">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">10:00 - 13:00 e 14:30 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 13:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 233">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:30 - 13:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 234">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">10:00 - 12:30 e 14:30 - 18:30</span></div>
						<div class="py-0"><span class="px-2">Sábado e Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 235">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 13:00 e 15:00 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 13:00</span></div>
						<div class="py-0"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pb-2"><span class="px-2">2º Sábado de Cada Mês:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 236">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:00 - 12:30 e 14:30 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">09:00 - 12:30</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 237">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 13:00 e 15:00 - 19:00</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 13:00</span></div>
						<div class="py-0"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pb-2"><span class="px-2">3º Sábado de Cada Mês:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div *ngIf="store?.id == 238">
						<div class="pt-2"><span class="px-2">Segunda a Sexta-feira:</span> <span class="px-2">09:30 - 12:30 e 14:00 - 18:30</span></div>
						<div class="py-0"><span class="px-2">Sábado:</span> <span class="px-2">10:00 - 13:00</span></div>
						<div class="pb-2"><span class="px-2">Domingo:</span> <span class="px-2">Encerrada</span></div>
						<div class="pb-2"><span class="px-2">3º Sábado de Cada Mês:</span> <span class="px-2">Encerrada</span></div>
					</div>
					<div class="d-none" *ngFor="let schedule of store?.schedules">
						<span *ngIf="schedule.start" class="px-2">
							{{ schedule.day }}
						</span>
						<span *ngIf="schedule.start" class="px-2"> {{ schedule.start }} - {{ schedule.end }} </span>
						<span *ngIf="schedule.start2" class="px-2"> {{ schedule.start2 }} - {{ schedule.end2 }} </span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="container">
		<div #map style="width: 100%; height: 300px"></div>
	</div>
</div>

<app-appointment></app-appointment>
