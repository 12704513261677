<div class="container">
	<div *ngIf="campaign" class="campaign">
		<div class="row">
			<div class="col">
				<div class="campaign-image">
					<img class="img-fluid" src="{{ campaign.image }}" />
				</div>
			</div>
		</div>
		<div class="campaign-body px-3 text-light">
			<div class="row my-1">
				<div class="col">
					<small class="text-muted">{{ getCategories(campaign) }}</small>
				</div>
				<div class="col-auto">
					<small class="text-muted">{{ campaign.date_publish | date }}</small>
				</div>
			</div>
			<h3 class="campaign-title text-light my-5">{{ campaign.title }}</h3>
			<div class="text-light" [innerHTML]="campaign.content"></div>
		</div>
	</div>
</div>
