import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class BrandService {

	constructor(private api: ApiService) { }

	all(promotion: boolean = false) {
		return this.api.get("brand/all", { id: this.api.MERCHANT_ID, promotion });
	}

	filter(categories: any, promotion: boolean = false) {
		return this.api.post("brand/category", { id: this.api.MERCHANT_ID, category: categories, promotion });
	}
}
