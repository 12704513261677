import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root"
})
export class CategoryService {
	constructor(private api: ApiService) {}

	all(promotion: boolean = false) {
		return this.api.get("category/all", { id: this.api.MERCHANT_ID, promotion });
	}

	subcategories(id: string) {
		return this.api.get("category/subcategory", { id });
	}
}
