<div class="container container-news" *ngIf="news">
    <img class="img" [src]="news.image_path" alt="{{ news.title }}" />
    <h1 class="mt-5 text-lg-start text-center text-white">
        {{ news.title }}
    </h1>
    <div class="row mt-5 text-white">
        <h2 class="text-lg-start text-center">{{ news.subtitle }}</h2>
        <div class="mt-5 col-12" [innerHtml]="news.content">
        </div>
    </div>
</div>

<section>
    <div class="container">
        <h3 class="text-center text-light pb-5">Seguir <a class="btn-link" target="_blank" href="https://www.instagram.com/opticaslincesa/">&#64;opticaslincesa</a></h3>
        <div class="news row">
            <a href="{{ post.type == 'fb' ? post.link : getTitlePathAsString('news', post) }}" *ngFor="let post of all | slice : 0 : 5"
               class="post my-3 col-xs-12 col-sm-6 col-md-3 col-lg"  target="{{ post.type == 'fb' ? '_blank' : '_self' }}">
                <img src="{{ post.image_path }}" />
            </a>
        </div>
    </div>
</section>
