import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	public user: User;
	public card: any;
	public coupons: any = [];

	constructor(private apiService: ApiService, private storageService: StorageService, private userService: UserService) {
		this.user = this.storageService.get("user");
		this.apiService.user = this.user;
	}

	async ngOnInit() {
		await this.loadCard();
	}

	logout() {
		this.userService.logout();
	}

	async loadCard() {
		this.userService.card().subscribe(
			(data: any) => {
				if (data.status === "OK") {
					this.card = data.data[0];

					this.loadCoupons();
				}
			}
		);
	}

	loadCoupons() {
		this.userService.coupons(this.card.id).subscribe(
			(data: any) => {
				if (data.status === "OK") {
					this.coupons = data.data;
				}
			}
		);
	}
}
