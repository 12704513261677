<div class="container">
	<h3 class="title text-center">Condições de Venda e Utilização do Site</h3>

	<p class="mt-5 mx-5">As Condições de Venda e Utilização do Site têm por objeto definir e disponibilizar ao Utilizador todas as informações necessárias sobre as modalidades de encomenda, venda, pagamento e entrega das compras efetuadas no website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>.</p>

	<ol type="I" class="mx-5">
		<li class="mt-3">
			<p><b>INFORMAÇÕES</b></p>
			<ol type="1">
				<li class="my-3">Estas condições gerais de venda são acordadas entre a Óptica Lince pessoa coletiva 503 079 871 e o utilizador que pretenda efetuar compras, através do website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> (doravante designado por "Utilizador").</li>
				<li class="my-3">As Condições Gerais de Venda aplicam-se, exclusivamente, a pessoas singulares, não comerciantes, entendendo-se como tal, qualquer pessoa singular que atue com fins que não se integrem no âmbito da sua atividade comercial, industrial, artesanal ou profissional.</li>
				<li class="my-3">As Partes acordam que as compras efetuadas através deste website serão reguladas, exclusivamente, pelas condições gerais de venda, em vigor a cada momento, com exclusão de quaisquer outras.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>ENCOMENDA DE PRODUTOS</b></p>
			<ol type="1">
				<li class="my-3">
					A realização de encomendas no website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> encontra-se dependente do prévio Registo do Utilizador em <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>, com o preenchimento da totalidade dos campos obrigatórios aí identificados.
					<ol>
						<li class="my-2">Com este registo estará a criar a sua conta pessoal no website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>, por via da qual poderá efetuar encomendas, acompanhar o seu estado ou realizar operações relacionadas.</li>
						<li class="my-2">
							O correto preenchimento dos campos referentes ao registo é da inteira responsabilidade do Utilizador, sendo que a Óptica Lince não suporta qualquer custo nem assume qualquer responsabilidade decorrente de erro, lapso ou omissão no preenchimento dos campos de registo e/ou de realização de encomenda e/ou decorrente de realização de compras fraudulentas por terceiros com
							recurso a dados do Utilizador.
						</li>
						<li class="my-2">1.3 Os dados introduzidos no registo ficam sujeitos à política de privacidade da Óptica Lince, que poderá ser consultada em <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>. Ao criar o registo de Utilizador em <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> estará a declarar que aceita os termos constantes da política de privacidade da Óptica Lince.</li>
					</ol>
				</li>
				<li class="my-3">
					O Utilizador pode encomendar produtos no website, selecionando-os e adicionando-os ao carrinho de compras.
					<ol>
						<li class="my-2">As imagens utilizadas para comunicação dos produtos, em <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> e outros canais digitais, são meramente ilustrativas, podendo existir diferença entre as cores da imagem e as do produto. As imagens utilizadas para comunicação dos produtos podem, também, conter artigos com uma função meramente decorativa, não estando disponíveis para venda.</li>
						<li class="my-2">
							Condições específicas de compra de lentes de contacto:
							<ol type="a">
								<li class="my-1">As lentes de contacto disponibilizadas no website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> são produtos unicamente de reposição de lentes de contacto que haviam sido prescritas previamente por um profissional de saúde qualificado (ótico optometrista ou oftalmologista) e cuja primeira compra se realizou numa ótica.</li>
								<li class="my-1">Se é a primeira compra de lentes de contacto do Utilizador, ou se faz mais de 1 ano desde que foi a um especialista rever a sua graduação, recomendamos que marque uma consulta de diagnóstico com o seu ótico- optometrista ou oftalmologista antes de fazer a sua compra.</li>
								<li class="my-1">Neste sentido, o Utilizador ao realizar um pedido através do website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>, confirma que as lentes de contacto foram prescritas anteriormente por um profissional qualificado (ótico optometrista ou oftalmologista) e que não teve qualquer tipo de problema de adaptação a essa graduação.</li>
								<li class="my-1">De acordo com o exposto anteriormente, a Óptica Lince não assumirá nenhum tipo de responsabilidade por eventuais danos à sua saúde que sejam causados pelo incumprimento, por parte do cliente, das anteriores condições.</li>
								<li class="my-1">A tolerância ao produto fornecido é da exclusiva responsabilidade do respetivo fabricante. O fabricante fornece as mercadorias na embalagem original fechada. A correta aplicação e manuseio das lentes de contato é da exclusiva responsabilidade do Utilizador.</li>
								<li class="my-1">A duração de uso indicada para lentes de contato refere-se a um período de tempo calculado de acordo com a média de uso. Para evitar problemas oculares, é essencial não ultrapassar o período de uso indicado nas instruções da embalagem. Se aparecerem irritações, é recomendado parar de usar as lentes imediatamente e consultar o seu especialista.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li class="my-3">Para realizar a encomenda, deverá completar a informação solicitada e escolher as opções que lhe são disponibilizadas ao longo do processo, em particular, nome, número de identificação fiscal (que para efeitos fiscais, deverá constar da fatura), método de entrega, morada, e-mail e forma de pagamento.</li>
				<li class="my-3">A conclusão da encomenda equivale à aceitação plena e completa dos preços e descrição dos produtos disponíveis para venda assim como das Condições de Venda e Utilização do Site que serão as únicas aplicáveis ao contrato assim celebrado.</li>
				<li class="my-3">Ao concluir a encomenda, o Utilizador aceita receber a respetiva fatura, por meio eletrónico, nos termos do artigo 12.º, n.º 1, do Decreto-Lei n.º 28/2019, de 15 de fevereiro.</li>
				<li class="my-3">
					A Óptica Lince dará seguimento às encomendas recebidas online unicamente até ao limite dos stocks disponíveis, comprometendo-se, na falta de disponibilidade do produto, a informar o Utilizador, logo que lhe seja possível.
					<ol>
						<li class="my-2">Todas as encomendas estão sujeitas à confirmação da disponibilidade do produto.</li>
						<li class="my-2">
							Em caso de dificuldades de fornecimento de produtos ou no caso do produto se encontrar esgotado, o Utilizador receberá, para o e-mail associado à sua encomenda, a informação acerca da indisponibilidade temporária ou definitiva do produto, procedendo-se ao reembolso do valor liquidado no ato da encomenda, no prazo de 14 dias, contados da comunicação de indisponibilidade.
						</li>
					</ol>
				</li>
				<li class="my-3">A restituição dos valores será efetuada através de transferência bancária, devendo o Utilizador facultar os dados necessários para o efeito, quando contactado. Tal restituição não implica quaisquer custos para o Utilizador.</li>
				<li class="my-3">Os dados constantes na fatura são da inteira responsabilidade do Utilizador.</li>
				<li class="my-3">A validade das encomendas depende do método de pagamento escolhido pelo Utilizador. Caso o pagamento não seja efetuado até ao termo do período indicado para o efeito, a encomenda será cancelada.</li>
				<li class="my-3">Independentemente do rigor aplicado na atualização do website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>, é possível que possam existir erros involuntários na informação apresentada sobre os produtos, pelo que a Óptica Lince reserva o direito de cancelar a encomenda do Utilizador, caso tal se verifique.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>ENTREGA/LEVANTAMENTO</b></p>
			<ol type="1">
				<li class="my-3">
					A Óptica Lince disponibiliza dois métodos de entrega, que o Utilizador poderá escolher no momento da realização da encomenda: Envio para a morada | Levantamento na loja.
					<ol>
						<li class="my-2">
							Envio para a morada:
							<ol type="a">
								<li class="my-1">Disponível em Portugal Continental e Ilhas dos Açores e da Madeira.</li>
								<li class="my-1">A Óptica Lince reserva-se o direito de proceder ao cancelamento da encomenda em caso de impossibilidade da sua entrega na morada indicada para envio.</li>
								<li class="my-1">O produto será entregue ao Utilizador ou a pessoa que, no momento da entrega, se apresente como estando em condições e assuma a obrigação de a fazer chegar ao Utilizador.</li>
							</ol>
						</li>
						<li class="my-2">
							Levantamento na loja:
							<ol type="a">
								<li class="my-1">O Utilizador poderá, aquando da realização da sua encomenda, escolher de entre uma lista de lojas aquela onde pretende proceder ao levantamento da mesma.</li>
								<li class="my-1">O Utilizador será avisado para o e-mail associado à sua encomenda, que a mesma se encontra disponível para levantamento.</li>
								<li class="my-1">O Utilizador dispõe de um prazo máximo de 15 dias para proceder ao levantamento da encomenda, contados da data do envio do e-mail a confirmar a receção na loja selecionada. Findo o referido prazo, a Óptica Lince reserva-se o direito a, de forma livre, cancelar a encomenda, ficando isenta de qualquer responsabilidade por esse facto.</li>
								<li class="my-1">Para proceder ao levantamento, deverá apresentar o número de encomenda no balcão da loja selecionada, podendo ainda ser solicitado o respetivo número de identificação.</li>
								<li class="my-1">O produto será entregue a terceira pessoa que, no momento da entrega, se apresente como estando em condições e assuma a obrigação de a fazer chegar ao Utilizador apresentando o número de encomenda e o cartão do cidadão.</li>
								<li class="my-1">A entrega considera-se concluída e conforme após a conferência e assinatura do destinatário/terceira pessoa que, no momento da entrega, se apresente como estando em condições e assuma a obrigação de a fazer chegar ao Utilizador.</li>
							</ol>
						</li>
					</ol>
				</li>
				<li class="my-3">As despesas de envio variam de acordo com o método de entrega selecionado pelo Utilizador e poderão, a todo o tempo e sem aviso prévio, ser alteradas pela Óptica Lince. Para conhecer os custos de envio da encomenda, o Utilizador deve adicionar, ao cesto de compras, os produtos que pretende adquirir e selecionar o método de entrega.</li>
				<li class="my-3">Serão imputados ao Utilizador todos os custos resultantes da necessidade de repetição do ato de entrega em virtude da indicação de endereços errados, insuficientes ou da falta de indicação da existência de situações relevantes para a realização da entrega.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>PAGAMENTO E VALIDAÇÃO DA ENCOMENDA</b></p>
			<ol type="1">
				<li class="my-3">
					A Óptica Lince propõe ao Utilizador as seguintes modalidades de pagamento:
					<ol type="a">
						<li class="my-2">
							Cartão de crédito (Visa e Mastercard)
							<p>No caso do pagamento com cartão de crédito, o débito será efetuado, no cartão do Utilizador, imediatamente após o registo da encomenda e verificação dos dados colocados.</p>
						</li>
						<li class="my-2">
							Multibanco
							<p>A referência Multibanco gerada mantém-se ativa até 72 (setenta e duas) horas, sendo desativada após esse período.</p>
						</li>
						<li class="my-2">
							MBWAY
							<p>O pagamento através de MBWAY mantém-se ativo até 5 (cinco) minutos, sendo desativado após esse período.</p>
						</li>
					</ol>
				</li>
				<li class="my-3">A Óptica Lince empregará todos os esforços para garantir a máxima confidencialidade e segurança nos dados transmitidos através da internet.</li>
				<li class="my-3">Para que a encomenda do Utilizador seja aceite e validada, o pagamento deverá ser efetuado nos termos e prazos previstos no n.º 1 do presente artigo.</li>
				<li class="my-3">Após confirmação da receção do pagamento, será enviado um e-mail a confirmar a validação da encomenda, no prazo máximo de 24 (vinte e quatro) horas. O e-mail será enviado para o endereço eletrónico indicado pelo Utilizador.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>PREÇO DE VENDA</b></p>
			<ol type="1">
				<li class="my-3">Os preços devem entender-se em moeda Euro, incluindo o IVA em vigor na data do pagamento da encomenda.</li>
				<li class="my-3">Caso se verifique alteração das tabelas de preços dos produtos à venda neste website, tal situação obrigará a uma atualização dos preços anunciados. A ocorrer, essa situação não afetará as encomendas já registadas.</li>
				<li class="my-3">
					Os preços apresentados correspondem aos preços em vigor, no entanto, só devem ser considerados válidos enquanto estiverem visíveis no website numa página atualizada, (cache ou páginas do website não atualizadas podem exibir preços que já não estão em vigor). O preço visualizado só será garantido após a encomenda se encontrar devidamente registada e paga, de acordo com as
					Condições Gerais de Venda.
				</li>
				<li class="my-3">Os preços das lojas físicas podem diferir dos aqui apresentados.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>DESCONTOS/PROMOÇÕES (CUPÕES)</b></p>
			<ol type="1">
				<li class="my-3">
					No website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a> podem existir Cupões promocionais, sob a forma de descontos percentuais ou de valor aplicados sobre o preço de venda ao público, os quais podem ser aplicados no campo “Descontar Cupão” aquando do “checkout” da encomenda.
					<ol>
						<li class="my-2">O Cupão consiste num código promocional com uma validade limitada, atribuído pela Óptica Lince e disponibilizado através dos seus canais de comunicação com um valor específico, que pode ser descontado nas compras realizadas através do website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>.</li>
					</ol>
				</li>
				<li class="my-3">Sempre que exista uma campanha em vigor no website, as suas condições específicas, como elegibilidade de Utilizadores, duração, exclusões, entre outras, encontrar-se-ão devidamente detalhadas, na página da respetiva campanha. As campanhas disponíveis online e os códigos promocionais apenas são válidos para a realização de compras em <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>.</li>
				<li class="my-3">Os descontos promocionais serão válidos durante o período indicado no website, desde que a encomenda seja concluída, pelo Utilizador (através do “checkout”) e o respetivo pagamento efetuado até ao final desse período.</li>
				<li class="my-3">Guardar um produto no “cesto de compras” não garante o preço nem o desconto do mesmo.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>UTILIZAÇÃO DO CARTÃO CLIENTE</b></p>
			<ol type="1">
				<li class="my-3">
					O Utilizador poderá utilizar o seu Cartão Cliente, seja para acumular o valor das compras e/ou descontar o saldo disponível no pagamento das encomendas a efetuar, de acordo com o estipulado nos Termos e Condições do Cartão. Para que o possa fazer, o Utilizador deverá, antes de avançar com a sua encomenda, selecionar o montante a utilizar no campo “Descontar Saldo” aquando do
					“checkout” da encomenda.
				</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>DEVOLUÇÃO (PRODUTOS CONFORME)</b></p>
			<ol type="1">
				<li class="my-3">O prazo para a devolução dos bens adquiridos, no âmbito do Direito de Livre Resolução do Consumidor, é de 14 (catorze) dias, contados desde o dia da receção do produto, nos termos do artigo 10º, n.º 1, do Decreto-Lei n.º 24/2014, de 14 de fevereiro, na redação em vigor. Para o efeito poderá utilizar o presente <a class="btn-link" href="assets/forms/formulario-livre-resolucao.pdf" target="_blank">Formulário</a>.</li>
				<li class="my-3">
					Para efetuar uma devolução, a comunicação da decisão de resolução pode ser feita por:
					<ol type="a">
						<li class="my-2">Correio postal (através do seguinte contacto: ÓPTICA LINCE, SA - RUA DO COMÉRCIO Nº 32, 6300-679 GUARDA)</li>
						<li class="my-2">E-mail (através do endereço <a class="btn-link" href="mailto:apoiocliente@opticaslince.com">apoiocliente@opticaslince.com</a>). Neste caso, ser-lhe-á enviado aviso de confirmação de receção da resolução.</li>
					</ol>
				</li>
				<li class="my-3">
					O Utilizador deverá, sem demora injustificada e o mais tardar, no prazo de 14 dias a contar da data em que tiver comunicado a sua decisão de resolução do contrato, enviar ou entregar o produto encomendado, juntando cópia da comunicação de resolução e da fatura de compra:
					<ol type="a">
						<li class="my-2">Para o seguinte endereço: ÓPTICA LINCE, SA - RUA DO COMÉRCIO Nº 32, 6300-679 GUARDA.</li>
						<li class="my-2">Numa loja física. Consultar Lojas físicas</li>
					</ol>
				</li>
				<li class="my-3">
					Os produtos devolvidos têm obrigatoriamente de se encontrar em perfeitas condições de venda, ou seja, no mesmo estado em que chegaram à posse do Utilizador, sem qualquer defeito e/ou irregularidade. A embalagem das lentes de contacto ou líquidos de conservação e limpeza deve ser mantida de forma intacta não sendo aceite a devolução de qualquer embalagem que esteja ou tenha sido
					aberta ou com o invólucro violado.
				</li>
				<li class="my-3">Assim que a encomenda seja recebida, o Consumidor será informado por e-mail da receção da mesma e do reembolso dos montantes pagos.</li>
				<li class="my-3">
					A Óptica Lince compromete-se a reembolsar o Utilizador do valor dos artigos devolvidos e do valor suportado para envio normal no prazo máximo de 14 (catorze) dias, a contar da receção da devolução do(s) produto(s).
					<ol>
						<li class="my-2">Os custos suplementares resultantes da sua escolha de uma modalidade de envio diferente da modalidade menos onerosa de envio normal oferecida serão suportados na sua totalidade pelo Utilizador.</li>
					</ol>
				</li>
				<li class="my-3">No caso de devolução, o Utilizador aceita que a restituição do valor seja efetuada através de transferência bancária para a conta por si indicada.</li>
				<li class="my-3">Se a devolução de determinado(s) produto(s) implicar o não preenchimento dos critérios para aplicação de oferta de determinada campanha à encomenda efetuada pelo Utilizador, o montante a reembolsar ou a cobrar ao Utilizador, será ajustado em conformidade.</li>
				<li class="my-3">A Óptica Lince não se responsabiliza por eventuais danos provocados durante o processo de devolução do produto.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>TROCAS</b></p>
			<ol type="1">
				<li class="my-3">Não serão permitidas trocas de produtos em conformidade adquiridos no website <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a>.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>GARANTIA (PRODUTOS NÃO CONFORME)</b></p>
			<ol type="1">
				<li class="my-3">
					Todos os produtos comercializados pela Óptica Lince, exceto os que estejam em liquidação (com redução de preço igual ou acima de 50% do PVP original), possuem a garantia indicada nos termos da legislação nacional, de 3 (três) anos contra defeito de fabrico, a partir da data de emissão da respetiva fatura, de acordo com a Lei das Garantias (D.L. n.º 67/2003, atualizado pelo D.
					L. n.º 84/2021). A garantia no 3º (terceiro) ano só é aplicável e válida caso surja algum defeito e terá de ser o Cliente a provar que tal defeito já existia no momento em que o bem lhe foi entregue.
					<ol>
						<li class="my-2">Os bens em liquidação são vendidos no exato estado em que se encontram e não beneficiam de garantia/assistências por se tratarem de produtos descontinuados pelo fornecedor.</li>
					</ol>
				</li>
				<li class="my-3">De forma a ser acionada a garantia é obrigatória a apresentação da fatura e do produto pelo Utilizador, bem como o preenchimento do respetivo formulário de reclamação. O produto será posteriormente enviado para análise. Para o efeito poderá utilizar o presente <a class="btn-link" href="assets/forms/formulario-reclamacao.pdf" target="_blank">Formulário</a>.</li>
				<li class="my-3">
					Para efetuar uma reclamação, a comunicação do problema pode ser feita por:
					<ol type="a">
						<li class="my-2">Correio postal (através do seguinte contacto: ÓPTICA LINCE, SA - RUA DO COMÉRCIO Nº 32, 6300-679 GUARDA)</li>
						<li class="my-2">E-mail (através do endereço <a href="mailto:apoiocliente@opticaslince.com" class="btn-link">apoiocliente@opticaslince.com</a>). Neste caso, ser-lhe-á enviado aviso de confirmação de receção.</li>
						<li class="my-2">Numa loja física. Consultar Lojas físicas.</li>
					</ol>
				</li>
				<li class="my-3">
					O Utilizador deverá enviar ou entregar o produto encomendado, juntando cópia da comunicação do problema e da fatura de compra:
					<ol type="a">
						<li class="my-2">Para o seguinte endereço: ÓPTICA LINCE, SA - RUA DO COMÉRCIO Nº 32, 6300-679 GUARDA (custo de envio suportado pelo Utilizador)</li>
						<li class="my-2">Numa loja física. Consultar Lojas físicas.</li>
					</ol>
				</li>
				<li class="my-3">Em caso de defeito do artigo, a troca ou devolução será alvo de reparação, troca ou desconto sobre o preço de venda por parte da Óptica Lince. Caso se verifique uma utilização incorreta do Utilizador, não há direito a garantia, pelo que a eventual reparação e/ou troca é suportado por este.</li>
				<li class="my-3">Caso o defeito do artigo se manifeste no prazo de 30 dias, a contar da data de entrega, o Utilizador pode solicitar a sua imediata substituição ou resolução do contrato de compra e venda.</li>
				<li class="my-3">
					As garantias não cobrem:
					<ul>
						<li class="my-2">Desgaste normal do material, deteriorações ou acidentes resultantes de utilizações negligentes;</li>
						<li class="my-2">Intervenções nos produtos, nomeadamente tentativas de reparação, ou mesmo reparação, fora dos nossos serviços de assistência;</li>
						<li class="my-2">Uso impróprio ou negligente do artigo, que pode causar danos irrecuperáveis (variações de temperatura, contacto com água, utilização de produtos abrasivos e não recomendáveis pelo fabricante);</li>
						<li class="my-2">A alteração da cor do produto com o tempo, devido à oxidação dos acabamentos.</li>
					</ul>
				</li>
				<li class="my-3">Ao abrigo da garantia, a reparação/substituição por produto igual ou similar, caso não exista rutura ou descontinuidade de stock/produto, deve ser realizada num prazo máximo de 45 (quarenta e cinco) dias, não sendo disponibilizado por parte da Óptica Lince um produto provisório de substituição.</li>
				<li class="my-3">Ao abrigo da garantia, em caso de reparação/s, o bem reparado passa a beneficiar de um prazo de garantia adicional de seis meses por cada reparação até ao limite de quatro reparações.</li>
				<li class="my-3">A reparação de produtos reclamados fora do prazo de garantia ou do seu âmbito é sempre suportada pelo Utilizador. Será obrigatária a concordância escrita por parte do Cliente com o Termo de Responsabilidade de Reparação.</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>POLÍTICA DE PRIVACIDADE E PROTECÇÃO DE DADOS PESSOAIS DOS CLIENTES</b></p>
			<ol type="1">
				<li class="my-3">
					No cumprimento da legislação sobre a Proteção de Dados Pessoais, todos os dados pessoais das pessoas singulares, fornecidos e recolhidos pela Óptica Lince, são guardados e processados exclusivamente para uso pessoal da Óptica Lince, e destinam-se, designadamente para a execução do contrato de compra e venda no qual o titular dos dados é parte e para cumprimento de obrigação
					jurídica a que o responsável pelo tratamento esteja sujeito, bem como para análise estatística, por razões técnicas e administrativas, faturação, cobrança do preço e comunicação com o Cliente, definição de perfis do Cliente.
				</li>
				<li class="my-3">A Óptica Lince poderá subcontratar a prestação de serviços junto de outras empresas com vista ao cumprimento das finalidades acima identificadas, que atuam mediante instruções da Óptica Lince.</li>
				<li class="my-3">
					O Cliente, na qualidade de titular, tem o direito a solicitar o acesso aos seus dados; a retificá-los caso estejam fora de validade, incorretos, incompletos ou alterados; a apagá-los quando os mesmos já não são necessários à finalidade para a qual foram recolhidos (o apagamento está limitado ao prazo imposto legalmente); à limitação dos seus dados, para a execução do contrato,
					e cumprimento da legislação; à oposição do tratamento, à portabilidade dos dados fornecidos; a retirar o seu consentimento desde que estes direitos não colidam com a execução do contrato ou com o cumprimento das normas legais.
				</li>
				<li class="my-3">Contudo o direito ao apagamento encontra-se limitado aos prazos legais de 10 anos (ou 12 anos para documentos referentes aos períodos de tributação de 2014 e 2015), prazo exigido para a conservação de documentos da contabilidade (artigo 123º do CIRC).</li>
				<li class="my-3">O Cliente tem direito a apresentar reclamação a uma entidade de controlo, caso entenda ter existido violação legal de um dos seus direitos supra identificados.</li>
				<li class="my-3">A prestação de dados pelo Cliente à Óptica Lince, para as finalidades descritas É OBRIGATÓRIA e a sua não prestação impede o cumprimento do contrato, levando à cessação do mesmo.</li>
				<li class="my-3">
					O Cliente para o exercício dos seus direitos, poderá contactar o representante da Óptica Lince através dos seguintes meios:
					<ul>
						<li class="my-2">Email: <a href="mailto:geral@opticaslince.com" class="btn-link">geral@opticaslince.com</a></li>
						<li class="my-2">Website: <a class="btn-link" href="https://www.opticaslince.com/">www.opticaslince.com</a></li>
						<li class="my-2">Carta: Óptica Lince, SA, com sede na Rua do Comércio n.º 32, 6300-679 Guarda</li>
						<li class="my-2">Telefone: 271 200 830</li>
						<li class="my-2">Horário de atendimento: das 9h às 20h, de segunda a sexta-feira e das 9h às 19h ao sábado, exceto feriados</li>
					</ul>
				</li>
			</ol>
		</li>
		<li class="mt-3">
			<p><b>LITÍGIOS</b></p>
			<ol type="1">
				<li class="my-3">O contrato de compra e venda dos nossos produtos rege-se, em todas as circunstâncias, pela Lei Portuguesa.</li>
				<li class="my-3">Fica reconhecido por ambas as partes que em caso de litígio o foro competente para a respetiva ação judicial é a Comarca da Guarda.</li>
				<li class="my-3">
					De acordo com a Lei nº144/2015, de 8 de Setembro, em caso de litígio o consumidor pode recorrer a uma Entidade de Resolução Alternativa de Litígios de consumo (RAL):
					<ul>
						<li class="my-2">Lojas Óptica Lince em Batalha | Belmonte | Celorico da Beira | Covilhã | Gouveia | Guarda | Ourém | Pinhel | Sabugal | Seia | Trancoso | Vila Nova de Foz Côa: Centro Nacional de Informação e Arbitragem de Conflitos de Consumo (CNIACC)</li>
						<li class="my-2">Lojas Óptica Lince em Matosinhos: Centro de Informação e Arbitragem do Porto (CICAP)</li>
						<li class="my-2">Lojas Óptica Lince em Oliveira do Hospital: Centro de Arbitragem de Conflitos de Consumo do Distrito de Coimbra (CACCDC)</li>
					</ul>
				</li>
			</ol>
		</li>
	</ol>

	<hr class="my-5">

	<h3 class="title text-center mt-5">Termos & Condições</h3>

	<p class="mt-5 mx-5">O utilizador do site da Ópticas linces, S.A. empenha-se em agir honestamente e aceitar tacitamente os seguintes Termos e Condições:</p>

	<h5 class="mt-3 mx-5">Alteração dos Termos de Utilização</h5>
	<p class="mx-5">A Ópticas Lince poderá, sem qualquer aviso prévio, tomar as ações necessárias para garantir a integridade, segurança, continuidade ou qualidade deste site, incluindo restrições ou limitações de acesso.</p>

	<h5 class="mt-3 mx-5">Acesso ao site</h5>
	<p class="mx-5">O acesso ao site não está sujeito a registo.</p>
	<p class="mx-5">A Ópticas Lince tem o direito exclusivo de suspender, parcial ou totalmente, o acesso ao site, em especial nas operações de gestão, manutenção, reparação, alteração ou modernização e encerrar, definitiva ou temporariamente, parcial ou totalmente, a qualquer momento, por sua escolha, o site ou qualquer um dos serviços ou funcionalidades, sem aviso prévio.</p>

	<h5 class="mt-3 mx-5">Utilização do site</h5>
	<p class="mx-5">A utilização do site está sujeita ao cumprimento das seguintes regras:</p>
	<ul class="mx-5">
		<li>Não utilizar o site para enviar ou disponibilizar qualquer conteúdo ilegal, falso, enganoso, ameaçador, maldoso, abusivo, difamatório, injurioso, invasivo da privacidade, racial, ética ou moralmente condenável, prejudicial ou atentatório da dignidade das pessoas ou prejudicial para menores, ou ainda que possa afetar negativamente a imagem da Ópticas Lince;</li>
		<li>
			Não utilizar o site para disponibilizar ou transmitir qualquer tipo de material que contenha ou possa conter vírus, worms, defeitos, cavalos de Troia ou outro item ou códigos informáticos, ficheiros ou programas que sejam suscetíveis de interromper, destruir ou limitar a funcionalidade de qualquer equipamento ou sistema informático (hardware ou software) ou equipamento de comunicações;
		</li>
		<li>Não remover qualquer aviso de direito de autor, marca comercial ou avisos de direitos de propriedade;</li>
		<li>Não disponibilizar ou transmitir qualquer conteúdo não solicitado ou não autorizado como SPAM.</li>
	</ul>
	<p class="mx-5">Em geral, o Utilizador deve utilizar o site de forma responsável, prudente e cuidadosa, não devendo perturbar ou degradar a continuidade, integridade e qualidade dos recursos e funcionalidades do mesmo.</p>
	<p class="mx-5">O Utilizador reconhece que a utilização que faça do site é por sua conta e risco, sendo o único responsável por qualquer dano causado ao seu sistema e/ou equipamento informático ou por outros danos ou prejuízos, incluindo perda ou danificação de dados, que resultem da utilização dos materiais, conteúdos ou informações obtidas, por qualquer forma, através do site.</p>

	<h5 class="mt-3 mx-5">Proibição de Utilização Comercial</h5>
	<p class="mx-5">O conteúdo do Site da Ópticas Lince, incluindo textos, imagens ou qualquer outra informação, é propriedade da Ópticas Lince, a qual detém os respetivos direitos de autor.</p>
	<p class="mx-5">Ao utilizar o Site da Ópticas Lince, o utilizador obriga-se a respeitar a legislação em vigor, nomeadamente, o Código do Direito de Autor e dos Direitos Conexos, o Código da Propriedade Industrial e a Lei da Criminalidade Informática.</p>
	<p class="mx-5">O utilizador não pode copiar, importar ou utilizar conteúdo proveniente do Site da Ópticas Lince sem obter prévia autorização e sem o referir como fonte de informação. Relativamente a conteúdo proveniente de outros sites, ligados a este, o utilizador deverá obter a autorização dos respetivos autores, respeitando as próprias declarações de direitos de autor.</p>
	<p class="mx-5">O uso ou partilha não autorizada de qualquer conteúdo deste site pode ser objeto de sanções civis ou criminais, de acordo com as leis aplicáveis.</p>

	<h5 class="mt-3 mx-5">Dados Pessoais</h5>
	<p class="mx-5">
		A utilização deste site não implica necessariamente o fornecimento de dados pessoais. No entanto, caso pretenda solicitar pedidos de orçamento, contacto ou esclarecimento, apresentar reclamações, comentários ou sugestões, deverá indicar-nos alguns dados, incluindo o seu nome, número de telefone e endereço de e-mail, sendo os mesmos tratados nos termos definidos na nossa Política de
		Privacidade e Proteção de Dados.
	</p>

	<h5 class="mt-3 mx-5">Informações</h5>
	<p class="mx-5">A informação disponibilizada no site visa essencialmente esclarecer e informar os Utilizadores sobre a identidade e atividade da Ópticas Lince e os seus serviços. Apesar da análise minuciosa de que a mesma foi objeto, podem existir erros ou imprecisões pelos quais não nos responsabilizamos.</p>

	<h5 class="mt-3 mx-5">Responsabilização</h5>
	<p class="mx-5">O site foi desenvolvido a pensar nos interesses dos nossos Utilizadores. No entanto, não podemos garantir que o site ou qualquer funcionalidade disponível no mesmo vá ao encontro de quaisquer necessidades e expectativas que tenha ou que sirva os seus fins específicos.</p>
	<p class="mx-5">O utilizador é o único responsável pelos prejuízos, diretos ou indiretos, causados a si próprio, à Ópticas Lince ou a quaisquer terceiros, relacionados com a utilização que fizer deste site, comprometendo-se a proceder às indemnizações necessárias, em virtude de qualquer ação, reclamação ou condenação a que dê origem.</p>
	<p class="mx-5">
		A Ópticas Lince não garante que os serviços disponibilizados pelo seu site funcionem ininterruptamente ou que se encontrem sempre livres de erros, vírus ou outros elementos prejudiciais. Não obstante, a Ópticas Lince  empenhar-se-á no sentido de o site não ter quaisquer vírus ou outros elementos do género, perigosos para o seu computador. Ainda assim e tendo em consideração que a Ópticas
		Lince não tem controlo integral quanto à circulação de informação através da Internet, não consegue garantir que os mesmos não contenham qualquer tipo de vírus ou outros elementos que possam danificar o seu computador.
	</p>
	<p class="mx-5">
		A Ópticas Lince não é responsável pela exatidão, qualidade, segurança, legalidade ou legitimidade, incluindo o cumprimento das regras respeitantes a direitos de autor e direitos conexos, dos conteúdos ou serviços contidos neste site que tenham sido fornecidos por anunciantes ou parceiros comerciais, bem como por qualquer informação contida em sites de terceiros para os quais este
		estabeleça ligações.
	</p>
	<p class="mx-5">A Ópticas Lince não está obrigada a guardar o conteúdo do seu site, incluindo textos, imagens ou qualquer outra informação, podendo o mesmo ser destruído a qualquer momento, sem que por tal facto advenha qualquer direito de indemnização para os utilizadores ou quaisquer terceiros.</p>
	<p class="mx-5">Podem existir ligações a sítios externos ao site da Ópticas Lince sobre os quais a Ópticas Lince, não tem qualquer controlo e pelos quais não assume qualquer responsabilidade.</p>
	<p class="mx-5">A Ópticas Lince não assume responsabilidade por eventuais problemas que surjam na sequência da utilização do site ou de quaisquer sítios externos a ele ligados.</p>

	<h5 class="mt-3 mx-5">Confidencialidade</h5>
	<p class="mx-5">Nos termos do Art.º 35º da Constituição da República Portuguesa e da Lei n.º 67/98, de 26 de Outubro, a informação recolhida sobre os utilizadores do Site da Ópticas Lince não é de carácter pessoal, pelo que se assegura a confidencialidade da sua identidade.</p>
	<p class="mx-5">Haverá, todavia, lugar à identificação dos utilizadores sempre que estes cometam ilegalidades, violem os presentes Termos e Condições, ou quando tal for solicitado por decisão judicial.</p>

	<h5 class="mt-3 mx-5">Validade dos Termos e Condições de Utilização</h5>
	<p class="mx-5">Se alguma parte ou disposição dos presentes Termos de Utilização não for executável ou estiver em conflito com a lei aplicável, a validade das restantes partes ou disposições não será afetada.</p>

	<h5 class="mt-3 mx-5">Lei Aplicável e Foro Competente</h5>

	<p class="mx-5">Os presentes Termos de Utilização regem-se pela Lei Portuguesa. A resolução de quaisquer questões e litígios será competente em exclusivo ao foro da Comarca da Guarda com expressa renúncia a qualquer outro.</p>
</div>
