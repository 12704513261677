import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AddressService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get("address/all", null, true);
	}

	create(data: any) {
		return this.api.post("address/create", data, true);
	}

	remove(id: any) {
		return this.api.get("address/delete", { id: id }, true);
	}
}
