import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

@Injectable({
	providedIn: "root"
})
export class ApiService {
	public API_URL: string = "https://opticae.online/api/";
	public MERCHANT_ID: string = "17";
	public MERCHANT_URL: string = "opticaslince.opticae.online";
	public user: any;
	public auth_key: string = "";
	public logged: boolean;

	constructor(private http: HttpClient, private storageService: StorageService) {
		this.user = this.storageService.get("user");
	}

	get(endpoint: string, params?: any, token: boolean = false) {
		if (token && this.user) {
			this.auth_key = this.user.auth_key;
		}

		let httpOptions = {
			headers: new HttpHeaders({
				'X-Requested-With': this.MERCHANT_URL,
				Authorization: this.auth_key
			}),
			params: new HttpParams()
		};

		if (params) {
			for (let p in params) {
				if (params[p]) {
					httpOptions.params = httpOptions.params.set(p, params[p]);
				}
			}
		}

		let url = this.API_URL + endpoint;

		return this.http.get(url, httpOptions);
	}

	post(endpoint: string, data: any, token: boolean = false) {
		if (token && this.user) {
			this.auth_key = this.user.auth_key;
		}

		let httpOptions = {
			headers: new HttpHeaders({
				'X-Requested-With': this.MERCHANT_URL,
				Authorization: this.auth_key
			})
		};

		let url = this.API_URL + endpoint;

		return this.http.post(url, data, httpOptions);
	}
}
