<div class="container">
	<div class="campaigns-box">
		<div *ngIf="campaigns === null" class="text-light text-center">
			<p class="text-lead text-light text-center">Nenhuma campanha encontrada</p>
		</div>

		<div *ngIf="campaigns?.length === 0" class="text-light text-center">
			<div class="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>

		<!-- <div class="slide">
			<div id="carouselSlides" class="carousel slide" data-bs-ride="carousel">
				<div class="carousel-indicators">
					<button type="button" data-bs-target="#carouselSlides" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
					<button type="button" data-bs-target="#carouselSlides" data-bs-slide-to="1" aria-label="Slide 2"></button>
					<button type="button" data-bs-target="#carouselSlides" data-bs-slide-to="2" aria-label="Slide 3"></button>
				</div>
				<div class="carousel-inner">
					<div *ngFor="let campaign of campaigns; let i = index" class="carousel-item" [ngClass]="{ active: i === 1 }">
						<img src="{{ campaign?.image }}" class="d-block w-100" alt="assets/slid.png" />
					</div>
				</div>
			</div>
		</div> -->

		<div class="row campaigns">
			<div *ngFor="let campaign of campaigns" class="col-md-6 col-lg-6 col-xl-4 campaign mb-3" routerLink="/campaign/{{ campaign?.id }}">
				<div class="campaign-image">
					<img src="{{campaign.image}}" class="img-fluid">
				</div>
				<div class="campaign-body text-light">
					<div class="row my-1">
						<div class="col">
							<small class="text-muted">{{ getCategories(campaign) }}</small>
						</div>
						<div class="col-auto">
							<small class="text-muted">{{ campaign.date_publish | date }}</small>
						</div>
					</div>
					<h5 class="campaign-title text-light my-1">{{ campaign.title }}</h5>
				</div>
			</div>
		</div>
	</div>
</div>
