import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AppointmentService {

	constructor(private api: ApiService) { }

	create(data: any) {
		data.merchant_id = this.api.MERCHANT_ID;
		return this.api.post("appointment/create", data, true);
	}
}
