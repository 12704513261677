<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
	<div class="offcanvas-header">
		<button type="button" class="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas"
			aria-label="Close"></button>
	</div>
	<div class="offcanvas-body">
		<ul class="navbar-nav w-100">
			<li class="nav-item">
				<a class="nav-link" (click)="menuAboutUsIsCollapsed = ! menuAboutUsIsCollapsed"
					[attr.aria-expanded]="!menuAboutUsIsCollapsed">
					Sobre Nós
				</a>
				<div #collapse="ngbCollapse" [(ngbCollapse)]="menuAboutUsIsCollapsed">
					<ul class="navbar-nav" style="padding-left: 10px">
						<li class="nav-item">
							<a class="nav-link" [ngClass]="{ main: router.url.includes('/history') }"
								data-bs-dismiss="offcanvas" routerLink="/history">Empresa</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" [ngClass]="{ main: router.url.includes('/human-resources') }"
								data-bs-dismiss="offcanvas" routerLink="/human-resources">Recursos Humanos</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" [ngClass]="{ main: router.url.includes('/protocols') }"
								data-bs-dismiss="offcanvas" routerLink="/protocols">Acordos</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" [ngClass]="{ main: router.url.includes('/sustainability') }"
								data-bs-dismiss="offcanvas" routerLink="/sustainability">Política de Sustentabilidade
								Ambiental</a>
						</li>
					</ul>
				</div>
			</li>
			<li class="nav-item">
				<a class="nav-link" (click)="menuProductsIsCollapsed = ! menuProductsIsCollapsed"
					[attr.aria-expanded]="!menuProductsIsCollapsed" aria-controls="collapseExample">
					Produtos
				</a>
				<div #collapse="ngbCollapse" [(ngbCollapse)]="menuProductsIsCollapsed">
					<div *ngFor="let col of categories">
						<ul class="navbar-nav" style="padding-left: 10px">
							<li class="nav-item" *ngFor="let category of col">
								<a [ngClass]="{ main: selectedCategory == category.id }" class="nav-link"
									data-bs-dismiss="offcanvas" routerLink="/products"
									[queryParams]="{ category_id: category.id }"><b>{{
										category.name
										}}</b></a>
								<a [ngClass]="{ main: selectedSubCategory == subcategory.id }"
									*ngFor="let subcategory of category.subcategories"
									[queryParams]="{ category_id: category.id, subcategory_id: subcategory.id }"
									class="nav-link" style="padding-left: 40px" data-bs-dismiss="offcanvas"
									routerLink="/products">{{ subcategory.name }}</a>
								<a [ngClass]="{ main: selectedCategory == category.id }" class="nav-link"
									style="padding-left: 40px" data-bs-dismiss="offcanvas" routerLink="/products"
									[queryParams]="{ category_id: category.id }">+ Ver
									Todas</a>
							</li>
						</ul>
					</div>
				</div>
			</li>
			<li class="nav-item">
				<a class="nav-link" (click)="menuBrandsIsCollapsed = ! menuBrandsIsCollapsed"
					[attr.aria-expanded]="!menuBrandsIsCollapsed" aria-controls="collapseExample">
					Marcas
				</a>
				<div #collapse="ngbCollapse" [(ngbCollapse)]="menuBrandsIsCollapsed">
					<div *ngFor="let col of brands">
						<ul class="nav-list" style="padding-left: 10px">
							<li class="nav-item" *ngFor="let brand of col">
								<a class="nav-link" data-bs-dismiss="offcanvas" routerLink="/products"
									[ngClass]="{ main: selectedBrand == brand.id }"
									[queryParams]="{ brand_id: brand.id }">{{ brand.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</li>
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ main: router.url.includes('/campaigns') }" data-bs-dismiss="offcanvas"
					routerLink="/campaigns">Campanhas</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ main: router.url.includes('/customize') }" data-bs-dismiss="offcanvas"
					routerLink="/customize">Óculos
					Personalizados</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ main: router.url.includes('/services') }" data-bs-dismiss="offcanvas"
					routerLink="/services">Serviços</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" [ngClass]="{ main: router.url.includes('/stores') }" data-bs-dismiss="offcanvas"
					routerLink="/stores">Pontos de
					Venda</a>
			</li>
		</ul>
	</div>
</div>

<div class="logo" [ngClass]="{ scroll: scrolled }">
	<a routerLink="/" class="btn-link">
		<img src="assets/logo.png" alt="" />
	</a>
</div>

<div class="social" [ngClass]="{ menuscroll: menuscrolled }">
	<div class="slogan">
		<div class="slogan-text text-light text-capitalize">A vida é o que você vê!</div>
	</div>
	<div class="facebook my-2">
		<a href="{{ merchant?.facebook_url }}" target="_blank">
			<img src="assets/facebook.png" alt="" />
		</a>
	</div>
	<div class="instagram my-2">
		<a href="{{ merchant?.instagram_url }}" target="_blank">
			<img src="assets/instagram.png" alt="" />
		</a>
	</div>
</div>

<nav class="navbar navbar-expand-lg navbar-dark flex-column" style="position: fixed"
	[ngClass]="{ 'disable-hover': disableHover }" [ngClass]="{ menuscroll: menuscrolled }">
	<div class="container-fluid justify-content-end">
		<ul class="navbar-toggler border-0 navbar-nav flex-row">
			<li class="nav-item">
				<div class="nav-link px-2" (click)="switchSearch()">
					<svg class="nav-icon" width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.86126 16.035C12.8232 16.035 16.035 12.8232 16.035 8.86126C16.035 4.89932 12.8232 1.6875 8.86126 1.6875C4.89932 1.6875 1.6875 4.89932 1.6875 8.86126C1.6875 12.8232 4.89932 16.035 8.86126 16.035Z"
							stroke-width="2" stroke-linejoin="round" />
						<path
							d="M11.2469 6.05125C10.636 5.44035 9.792 5.0625 8.85979 5.0625C7.92758 5.0625 7.08361 5.44035 6.47266 6.05125"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path d="M14.0195 14.0195L17.6002 17.6002" stroke-width="2" stroke-linecap="round"
							stroke-linejoin="round" />
					</svg>
				</div>
			</li>
			<li class="nav-item">
				<a routerLink="/favorites" class="nav-link px-2" [ngClass]="{ active: router.url.includes('/favorites') }">
					<svg class="nav-icon" width="20" height="17" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M6.58325 1.375C4.01963 1.375 1.94141 3.45324 1.94141 6.01684C1.94141 10.6587 7.42722 14.8785 10.3811 15.8602C13.335 14.8785 18.8208 10.6587 18.8208 6.01684C18.8208 3.45324 16.7426 1.375 14.179 1.375C12.6091 1.375 11.2212 2.15437 10.3811 3.34728C9.54108 2.15437 8.15316 1.375 6.58325 1.375Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</a>
			</li>
			<li class="nav-item">
				<a *ngIf="!user" routerLink="/login" class="nav-link px-2"
					[ngClass]="{ active: router.url.includes('/login') }">
					<svg class="nav-icon" width="19" height="21" viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.62925 8.44083C11.3079 8.44083 12.6687 6.92905 12.6687 5.06417C12.6687 3.19929 11.3079 1.6875 9.62925 1.6875C7.95063 1.6875 6.58984 3.19929 6.58984 5.06417C6.58984 6.92905 7.95063 8.44083 9.62925 8.44083Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path
							d="M16.4703 18.5702C16.4703 14.3742 13.4085 10.9727 9.63163 10.9727C5.85475 10.9727 2.79297 14.3742 2.79297 18.5702"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</a>
				<a *ngIf="user" routerLink="/profile" class="nav-link px-2"
					[ngClass]="{ active: router.url.includes('/profile') }">
					<svg class="nav-icon" width="19" height="21" viewBox="0 0 19 21" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.62925 8.44083C11.3079 8.44083 12.6687 6.92905 12.6687 5.06417C12.6687 3.19929 11.3079 1.6875 9.62925 1.6875C7.95063 1.6875 6.58984 3.19929 6.58984 5.06417C6.58984 6.92905 7.95063 8.44083 9.62925 8.44083Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path
							d="M16.4703 18.5702C16.4703 14.3742 13.4085 10.9727 9.63163 10.9727C5.85475 10.9727 2.79297 14.3742 2.79297 18.5702"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</a>
			</li>
			<li class="nav-item">
				<a routerLink="/checkout" class="nav-link px-2" [ngClass]="{ active: router.url.includes('/checkout') }">
					<svg class="nav-icon" width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M2.01172 2.53125H3.48867L4.12165 5.06317L2.01172 2.53125ZM4.12165 5.06317L6.23158 13.5029H17.2032L19.3131 5.06317H4.12165Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path
							d="M6.2308 17.7233C6.92997 17.7233 7.49676 17.1565 7.49676 16.4574C7.49676 15.7582 6.92997 15.1914 6.2308 15.1914C5.53163 15.1914 4.96484 15.7582 4.96484 16.4574C4.96484 17.1565 5.53163 17.7233 6.2308 17.7233Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						<path
							d="M17.2035 17.7233C17.9026 17.7233 18.4694 17.1565 18.4694 16.4574C18.4694 15.7582 17.9026 15.1914 17.2035 15.1914C16.5043 15.1914 15.9375 15.7582 15.9375 16.4574C15.9375 17.1565 16.5043 17.7233 17.2035 17.7233Z"
							stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
					<span *ngIf="cartCount" class="cart-count">{{ cartCount }}</span>
				</a>
			</li>
		</ul>
		<button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu"
			aria-controls="offcanvasMenu" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav w-100 justify-content-center mb-2 mb-lg-0">
				<li class="nav-item menu-w-submenu">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/history') }"
						routerLink="/history">Sobre Nós</a>
					<div class="container-fluid submenu">
						<ul class="nav-list" style="padding-left: 0">
							<li><a class="nav-link" [ngClass]="{ main: router.url.includes('/history') }"
									routerLink="/history">Empresa</a></li>
							<li><a class="nav-link" [ngClass]="{ main: router.url.includes('/human-resources') }"
									routerLink="/human-resources">Recursos Humanos</a></li>
							<li><a class="nav-link" [ngClass]="{ main: router.url.includes('/protocols') }"
									routerLink="/protocols">Acordos</a></li>
							<li><a class="nav-link" [ngClass]="{ main: router.url.includes('/sustainability') }"
									routerLink="/sustainability">Política de Sustentabilidade Ambiental</a></li>
						</ul>
					</div>
				</li>
				<li class="nav-item menu-w-submenu">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/products') }"
						routerLink="/products">Produtos</a>
					<div class="container-fluid submenu">
						<div class="row justify-content-around">
							<div class="col" *ngFor="let col of categories">
								<ul class="nav-list" style="padding-left: 0">
									<li class="nav-item" *ngFor="let category of col">
										<a [ngClass]="{ main: selectedCategory == category.id }" class="nav-link"
											routerLink="/products" [queryParams]="{ category_id: category.id }"><b>{{
												category.name }}</b></a>
										<a [ngClass]="{ main: selectedSubCategory == subcategory.id }"
											*ngFor="let subcategory of category.subcategories"
											[queryParams]="{ category_id: category.id, subcategory_id: subcategory.id }"
											class="nav-link" routerLink="/products">{{ subcategory.name }}</a>
										<a [ngClass]="{ main: selectedCategory == category.id }" class="nav-link"
											routerLink="/products" [queryParams]="{ category_id: category.id }">+ Ver
											Todas</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
				<li class="nav-item menu-w-submenu">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/brands') }"
						routerLink="/brands">Marcas</a>
					<div class="container-fluid submenu">
						<div class="row justify-content-around">
							<div class="col" *ngFor="let col of brands">
								<ul class="nav-list" style="padding-left: 0">
									<li class="nav-item" *ngFor="let brand of col">
										<a class="nav-link" routerLink="/products"
											[ngClass]="{ main: selectedBrand == brand.id }"
											[queryParams]="{ brand_id: brand.id }">{{ brand.name }}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/campaigns') }"
						routerLink="/campaigns">Campanhas</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/customize') }"
						routerLink="/customize">Óculos Personalizados</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/services') }"
						routerLink="/services">Serviços</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [ngClass]="{ main: router.url.includes('/stores') }" routerLink="/stores">Pontos
						de Venda</a>
				</li>
			</ul>
			<div class="pull-right">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<div class="nav-link" (click)="switchSearch()">
							<svg class="nav-icon" width="21" height="21" viewBox="0 0 21 21"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.86126 16.035C12.8232 16.035 16.035 12.8232 16.035 8.86126C16.035 4.89932 12.8232 1.6875 8.86126 1.6875C4.89932 1.6875 1.6875 4.89932 1.6875 8.86126C1.6875 12.8232 4.89932 16.035 8.86126 16.035Z"
									stroke-width="2" stroke-linejoin="round" />
								<path
									d="M11.2469 6.05125C10.636 5.44035 9.792 5.0625 8.85979 5.0625C7.92758 5.0625 7.08361 5.44035 6.47266 6.05125"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M14.0195 14.0195L17.6002 17.6002" stroke-width="2" stroke-linecap="round"
									stroke-linejoin="round" />
							</svg>
						</div>
					</li>
					<li class="nav-item">
						<a routerLink="/favorites" class="nav-link"
							[ngClass]="{ active: router.url.includes('/favorites') }">
							<svg class="nav-icon" width="20" height="17" viewBox="0 0 20 17"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6.58325 1.375C4.01963 1.375 1.94141 3.45324 1.94141 6.01684C1.94141 10.6587 7.42722 14.8785 10.3811 15.8602C13.335 14.8785 18.8208 10.6587 18.8208 6.01684C18.8208 3.45324 16.7426 1.375 14.179 1.375C12.6091 1.375 11.2212 2.15437 10.3811 3.34728C9.54108 2.15437 8.15316 1.375 6.58325 1.375Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="nav-item">
						<a *ngIf="!user" routerLink="/login" class="nav-link"
							[ngClass]="{ active: router.url.includes('/login') }">
							<svg class="nav-icon" width="19" height="21" viewBox="0 0 19 21"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.62925 8.44083C11.3079 8.44083 12.6687 6.92905 12.6687 5.06417C12.6687 3.19929 11.3079 1.6875 9.62925 1.6875C7.95063 1.6875 6.58984 3.19929 6.58984 5.06417C6.58984 6.92905 7.95063 8.44083 9.62925 8.44083Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M16.4703 18.5702C16.4703 14.3742 13.4085 10.9727 9.63163 10.9727C5.85475 10.9727 2.79297 14.3742 2.79297 18.5702"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</a>
						<a *ngIf="user" routerLink="/profile" class="nav-link"
							[ngClass]="{ active: router.url.includes('/profile') }">
							<svg class="nav-icon" width="19" height="21" viewBox="0 0 19 21"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.62925 8.44083C11.3079 8.44083 12.6687 6.92905 12.6687 5.06417C12.6687 3.19929 11.3079 1.6875 9.62925 1.6875C7.95063 1.6875 6.58984 3.19929 6.58984 5.06417C6.58984 6.92905 7.95063 8.44083 9.62925 8.44083Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M16.4703 18.5702C16.4703 14.3742 13.4085 10.9727 9.63163 10.9727C5.85475 10.9727 2.79297 14.3742 2.79297 18.5702"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</a>
					</li>
					<li class="nav-item">
						<a routerLink="/checkout" class="nav-link"
							[ngClass]="{ active: router.url.includes('/checkout') }">
							<svg class="nav-icon" width="21" height="21" viewBox="0 0 21 21"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M2.01172 2.53125H3.48867L4.12165 5.06317L2.01172 2.53125ZM4.12165 5.06317L6.23158 13.5029H17.2032L19.3131 5.06317H4.12165Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M6.2308 17.7233C6.92997 17.7233 7.49676 17.1565 7.49676 16.4574C7.49676 15.7582 6.92997 15.1914 6.2308 15.1914C5.53163 15.1914 4.96484 15.7582 4.96484 16.4574C4.96484 17.1565 5.53163 17.7233 6.2308 17.7233Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M17.2035 17.7233C17.9026 17.7233 18.4694 17.1565 18.4694 16.4574C18.4694 15.7582 17.9026 15.1914 17.2035 15.1914C16.5043 15.1914 15.9375 15.7582 15.9375 16.4574C15.9375 17.1565 16.5043 17.7233 17.2035 17.7233Z"
									stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
							<span *ngIf="cartCount" class="cart-count">{{ cartCount }}</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</nav>

<router-outlet></router-outlet>

<footer class="py-4">
	<div class="container mt-5">
		<div class="row justify-content-between">
			<div class="my-3 col-sm-12 col-md-auto">
				<h5>Contactos</h5>
				<p>SUPORTE - LOJA ONLINE</p>
				<p>808 20 70 80<br /><a href="mailto:geral@opticaslince.com">geral@opticaslince.com</a></p>
				<h5>Opinião</h5>
				<ul>
					<li>
						<a href="https://www.livroreclamacoes.pt/Pedido/ElogioSugestao" target="_blank"><img
								src="assets/icons/smiling.png" /> Fazer elogio/ou sugestão</a>
					</li>
					<li>
						<a href="https://www.livroreclamacoes.pt/Pedido/Reclamacao" target="_blank"><img
								src="assets/icons/frowning.png" /> Livro de reclamações</a>
					</li>
				</ul>
			</div>
			<div class="my-3 col-sm-12 col-md-3">
				<h5>Newsletter</h5>
				<p>Subscreva a nossa Newsletter e receba em primeira mão todas as novidades.</p>
				<div class="btn btn-lg btn-block btn-more" (click)="openModal()">Subscrever</div>
			</div>
			<div class="my-3 col-sm-12 col-md-auto">
				<h5>A Empresa</h5>
				<ul>
					<li><a routerLink="/history">História da Empresa</a></li>
					<li><a routerLink="/services">Serviços</a></li>
					<li><a routerLink="/stores">Pontos de Venda</a></li>
					<li><a routerLink="/human-resources">Recursos Humanos</a></li>
					<li><a routerLink="/protocols">Acordos</a></li>
					<li><a routerLink="/sustainability">Política de Sustentabilidade Ambiental</a></li>
				</ul>
			</div>
			<div class="my-3 col-sm-12 col-md-auto">
				<h5>Apoio ao Cliente</h5>
				<ul>
					<li><a routerLink="/terms-conditions">Condições de Venda e Utilização do Site</a></li>
					<li><a routerLink="/terms-customer-card">Termos e Condições do Cartão Cliente</a></li>
					<li><a routerLink="/privacy">Política de Privacidade</a></li>
					<li><a routerLink="/sales-condition">Condições Gerais de Venda Lojas Física</a></li>
				</ul>
				<div class="images">
					<div class="image cinco">
						<img class="img-fluid" style="height: 70px" src="assets/cinco-estrelas.png" alt="" />
					</div>
					<div class="image pme">
						<img class="img-fluid" style="height: 70px" src="assets/pme-lider.png" alt="" />
					</div>
					<div class="image scoring">
						<img class="img-fluid" style="height: 70px" src="assets/scoring.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>

<app-search [showSearch]="showSearch"></app-search>