import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { User } from "../models/User";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, signInWithCredential, signOut, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";

@Injectable({
	providedIn: "root"
})
export class UserService {
	public user$: BehaviorSubject<User>;
	public user: User;

	constructor(private apiService: ApiService, private router: Router, private storageService: StorageService) {
		this.user$ = new BehaviorSubject(this.user);
		this.initFirebase();
	}

	initFirebase() {
		initializeApp({
			apiKey: "AIzaSyA6xwhb7y8g--KqqdhMKKFmGkawsVTJp-E",
			authDomain: "opticas-lince-7e47e.firebaseapp.com",
			projectId: "opticas-lince-7e47e",
			storageBucket: "opticas-lince-7e47e.appspot.com",
			messagingSenderId: "958915907982",
			appId: "1:958915907982:web:728e69a28159269fdb54df",
			measurementId: "G-233P09N3ZT"
		});
	}

	async getUser(): Promise<User | false> {
		if (!this.user) {
			this.user = await this.storageService.get("user");
		}

		if (this.user) {
			this.apiService.user = this.user;
			return this.user;
		}

		return false;
	}

	setUser(user: any) {
		this.apiService.user = user;
		this.storageService.set('user', user);
		this.user$.next(user);
	}

	login(data: any) {
		data.merchant_id = this.apiService.MERCHANT_ID;
		return this.apiService.post("customer/login/", data);
	}

	logout() {
		this.storageService.remove('user');
		this.storageService.remove('cart');
		this.router.navigate(['/']);
	}

	verifyemail(email: string) {
		var data = {
			email,
			merchant_id: this.apiService.MERCHANT_ID
		};
		return this.apiService.post("customer/verifyemail/", data);
	}

	verifytype(email: string) {
		var data = {
			email,
			merchant_id: this.apiService.MERCHANT_ID,
			type: 2
		};
		return this.apiService.post("customer/verifytype/", data);
	}

	recovery(email: string) {
		var data = {
			merchant_id: this.apiService.MERCHANT_ID,
			email
		};
		return this.apiService.post("customer/recovery/", data);
	}

	register(data: any, type: any = 1) {
		data.merchant_id = this.apiService.MERCHANT_ID;
		data.type = type;
		data.status = 1;

		return this.apiService.post("customer/create/", data);
	}

	authenticate() {
		if (!this.user) {
			this.router.navigate(["/"]);
		} else {
			this.getUser();
		}
	}

	userObservable() {
		return this.user$;
	}

	card() {
		return this.apiService.get("customer/loyaltycards", { merchant_id: this.apiService.MERCHANT_ID }, true);
	}

	coupons(id: any) {
		return this.apiService.get("customer/viewcard", { id, merchant_id: this.apiService.MERCHANT_ID }, true);
	}

	loyalties() {
		return this.apiService.get("customer/loyalty-transactions", { merchant_id: this.apiService.MERCHANT_ID }, true);
	}

	family() {
		return this.apiService.get("family/view", { merchant_id: this.apiService.MERCHANT_ID }, true);
	}

	password(data: any) {
		data.merchant_id = this.apiService.MERCHANT_ID;
		return this.apiService.post("customer/password", data, true);
	}

	edit(data: any) {
		data.merchant_id = this.apiService.MERCHANT_ID;
		return this.apiService.post("customer/edit", data, true);
	}

	addresses(data: any) {
		data.merchant_id = this.apiService.MERCHANT_ID;
		return this.apiService.post("customer/addresses", data, true);
	}

	async google(): Promise<any> {
		const auth = getAuth();
		let response = await this.firebase(auth, new GoogleAuthProvider(), GoogleAuthProvider);
		return response;
	}

	async facebook(): Promise<any> {
		const auth = getAuth();
		let response = this.firebase(auth, new FacebookAuthProvider(), FacebookAuthProvider);
		return response;
	}

	async firebase(auth: any, provider: any, providerCredential: any) {
		return new Promise((resolve, reject) => {
			signInWithPopup(auth, provider)
				.then(async (result: any) => {
					// The signed-in user info.
					const user = result.user;

					// This gives you a Facebook Access Token. You can use it to access the Facebook API.
					const credential = providerCredential.credentialFromResult(result);
					const accessToken = credential.accessToken;

					resolve(result);
				})
				.catch(async (error: any) => {
					// Handle Errors here.
					const errorCode = error.code;
					const errorMessage = error.message;
					console.log(errorMessage);
					// The email of the user's account used.
					const email = error.email;
					// The AuthCredential type that was used.
					const credential = providerCredential.credentialFromError(error);

					const user = error.user;

					reject(errorMessage);
				});
		});
	}
}
