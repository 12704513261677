import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
	@ViewChild('paymentForm') paymentForm: ElementRef;

	public order: any = null;
	public selectOrder: any = null;
	public checkoutId: string;
	public spgContext: string;
	public spgConfig: string;
	public spgStyle: string;
	public urlbase: string;

	constructor(
		private renderer2: Renderer2,
		private orderService: OrderService,
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			const id = params['id'];
			
			if (id) {
				this.load(id);
			}
		});
	}

	load(id: number) {
		this.orderService.one(id).subscribe((data: any) => {
			if (data.status == 'OK') {
				this.selectOrder = data.data;

				this.checkoutId = data.data.checkout_id;

				this.spgContext = this.selectOrder.sibsspg_form_context;
				this.spgConfig = '{"paymentMethodList": [], "amount": { "value": ' + this.selectOrder.total + ', "currency": "EUR" },"language": "pt","redirectUrl": "' + this.getBaseUrl() +'/orders", "customerData": null}';
				this.spgStyle = '{"layout": "default","theme": "default", "color": {"primary": "' + this.selectOrder.merchant.color + '", "secondary": "","border": "","surface": "","header": {"text": "","background": "#FFFFFF"},"body": {"text": "","background": "#FFFFFF"}},"font":""}';

				const scriptSibsSpgPaymentWidget = this.renderer2.createElement('script');
				scriptSibsSpgPaymentWidget.type = 'text/javascript';
				scriptSibsSpgPaymentWidget.src = 'https://api.sibspayments.com/assets/js/widget.js?id=' + this.checkoutId;
				scriptSibsSpgPaymentWidget.text = ``;
				
				this.renderer2.appendChild(this.paymentForm.nativeElement, scriptSibsSpgPaymentWidget);

				const formSibsPayment = this.renderer2.createElement('form');
				this.renderer2.setAttribute(formSibsPayment, 'class', 'paymentSPG');
				this.renderer2.setAttribute(formSibsPayment, 'spg-context', this.spgContext);
				this.renderer2.setAttribute(formSibsPayment, 'spg-config', this.spgConfig);
				this.renderer2.setAttribute(formSibsPayment, 'spg-style', this.spgStyle);

				this.renderer2.appendChild(this.paymentForm.nativeElement, formSibsPayment);
			}
		})
	}

	getBaseUrl() {
		return window.location.origin;
	}
}