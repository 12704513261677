<div class="container">
	<h4 class="title text-center">Encomendas</h4>
</div>

<div class="container">
	<div class="box p-3 p-md-5 mx-md-5">
		<div class="d-md-none">
			<div class="mb-5 list-group">
				<a class="list-group-item list-group-item-action border-0" routerLink="/profile">Meu Perfil</a>
				<a class="list-group-item list-group-item-action active border-0" routerLink="/orders">Encomendas</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/address">Moradas</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/family">Família</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/loyalty">Fidelização</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/change-profile">Dados
					Pessoais</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="/change-password">Password</a>
				<a class="list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">Terminar
					Sessão</a>
			</div>
		</div>
		<div class="row">
			<div class="d-none d-md-block col-md-auto">
				<div class="list-group">
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/profile">
						<span class="menu-user"><i class="fa fa-user"></i><span class="text">Meu Perfil</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action active border-0" routerLink="/orders">
						<span class="menu-user"><i class="fa fa-box-open"></i><span
								class="text">Encomendas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/address">
						<span class="menu-user"><i class="fa fa-home"></i><span class="text">Moradas</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/family">
						<span class="menu-user"><i class="fa fa-user-group"></i><span class="text">Família</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/loyalty">
						<span class="menu-user"><i class="fa fa-medal"></i><span class="text">Fidelização</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/change-profile">
						<span class="menu-user"><i class="fa fa-user-edit"></i><span class="text">Dados
								Pessoais</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="/change-password">
						<span class="menu-user"><i class="fa fa-key"></i><span class="text">Password</span></span>
					</a>
					<a class="p-0 list-group-item list-group-item-action border-0" routerLink="" (click)="logout()">
						<span class="menu-user"><i class="fa fa-sign-out"></i><span class="text">Terminar
								Sessão</span></span>
					</a>
				</div>
			</div>
			<div class="col-md">
				<div id="orders" class="fdb-block no-subheader">
					<div class="container">
						<div class="d-none d-md-block">
							<div *ngIf="!orders" class="row align-items-center text-center">
								<div class="col-12">
									<div class="display-4 text-center">
										<i class="fas fa-shopping-basket fa-spinner"></i>
									</div>
									<h3>A carregar encomendas</h3>
								</div>
							</div>
							<div *ngIf="orders?.length === 0" class="row align-items-center text-center">
								<div class="col-12">
									<div class="display-4 text-center">
										<i class="icon-basket"></i>
									</div>
									<h3>Não tem encomendas registadas</h3>
									<p class="lead">Apenas após a confirmação de uma compra irá visualizar aqui a lista
										das suas encomendas, onde poderá consultar os detalhes, como acompanhar o
										progresso da encomenda ou proceder ao pagamento caso não o faça após a
										confirmação.</p>
									<p>
										<a class="btn outline mt-4 mb-5 mb-md-0" routerLink="/shop">Ir para a Loja</a>
									</p>
								</div>
							</div>
							<div class="row" *ngIf="orders?.length">
								<div class="col-sm-4 col-md-4" id="orders-list">
									<i class="icon-dots"></i>
									<div *ngFor="let order of orders" (click)="changeOrder(order.id)"
										class="order-button" [ngClass]="{
											active: order.id === selectedOrder?.id
										}">
										<h3>#{{ order.name }}</h3>
										<p>{{ order.status }}</p>
										<i class="icon-dots"></i>
										<i class="fas fa-2x fa-angle-right arrow-right"></i>
									</div>
								</div>
								<div class="col-sm-8 col-md-8" *ngIf="selectedOrder">
									<h3>
										#{{ selectedOrder.name }}<small class="float-right">{{ selectedOrder.date | date
											: "dd/MM/yyyy" }}</small>
									</h3>
									<br />
									<div *ngIf="orderStep > 0" class="order-state">
										<div
											class="order-state-item {{ orderStep >= 1 ? 'current' : '' }} {{ orderStep == 1 ? 'last' : '' }}">
											<div class="state-icon">
												<i class="fas fa-fw fa-money-bill-wave"></i>
											</div>
											<div>Aguarda pagamento</div>
										</div>
										<div class="connect-item {{ orderStep >= 2 ? 'current' : '' }}">
											<span></span>
										</div>
										<div
											class="order-state-item {{ orderStep >= 2 ? 'current' : '' }} {{ orderStep == 2 ? 'last' : '' }}">
											<div class="state-icon">
												<i class="fas fa-fw fa-box-open"></i>
											</div>
											<div>Em preparação</div>
										</div>
										<div class="connect-item {{ orderStep >= 3 ? 'current' : '' }}">
											<span></span>
										</div>
										<div
											class="order-state-item {{ orderStep >= 3 ? 'current' : '' }} {{ orderStep == 3 ? 'last' : '' }}">
											<div class="state-icon">
												<i class="fas fa-fw fa-shipping-fast"></i>
											</div>
											<div>Enviada</div>
										</div>
										<div class="connect-item {{ orderStep >= 4 ? 'current' : '' }}">
											<span></span>
										</div>
										<div
											class="order-state-item {{ orderStep >= 4 ? 'current' : '' }} {{ orderStep == 4 ? 'last' : '' }}">
											<div class="state-icon">
												<i class="fas fa-fw fa-home"></i>
											</div>
											<div>Entregue</div>
										</div>
									</div>

									<div class="row justify-content-center py-5">
										<div class="col-12 text-center">
											<div class="fdb-box">
												<h3>
													{{ selectedOrder.payment_state }}
												</h3>
												<dl>
													<dt>Método de Pagamento</dt>
													<dd>
														{{ selectedOrder.payment_type }}
													</dd>
												</dl>
												<div *ngIf="selectedOrder.mb_entity">
													<dl class="row">
														<dt class="col text-end">Entidade</dt>
														<dd class="col text-start">{{ selectedOrder.mb_entity | separateNumber }}</dd>
													</dl>
													<dl class="row align-center">
														<dt class="col text-end">Referência</dt>
														<dd class="col text-start">{{ selectedOrder.mb_reference | separateNumber }}</dd>
													</dl>
													<dl class="row">
														<dt class="col text-end">Montante</dt>
														<dd class="col text-start">{{ selectedOrder.mb_amount | currency
															:
															"EUR" : "symbol" : "1.2-2" : "pt-PT" }}</dd>
													</dl>
												</div>
												<p class="mt-4" *ngIf="orderStep < 2 && !selectedOrder.mb_entity">
													<button class="btn btn-lg btn-primary"
														(click)="pay()">Pagar</button>
												</p>
											</div>
										</div>
									</div>
									<div class="row">
										<div *ngIf="selectedOrder.address_delivery" class="col col-6"
											style="border-left: 2px solid #f9f9f9">
											<p>
												<strong>Endereço de entrega:</strong>
											</p>
											<p>
												{{ selectedOrder.customer.name }}
												<br />
												{{ selectedOrder.address_delivery.address }}
												<br />
												{{ selectedOrder.address_delivery.postcode }}
												{{ selectedOrder.address_delivery.city }}
												<br />
												{{ selectedOrder.address_delivery.country }}
											</p>
										</div>
										<div *ngIf="selectedOrder.store" class="col col-6"
											style="border-left: 2px solid #f9f9f9">
											<p>
												<strong>Endereço de entrega:</strong>
											</p>
											<p>
												{{ selectedOrder.store.name }}
												<br />
												{{ selectedOrder.store.address }}
												<br />
												{{ selectedOrder.store.postcode }}
												{{ selectedOrder.store.city }}
												<br />
												{{ selectedOrder.store.country }}
											</p>
										</div>
										<div *ngIf="selectedOrder.address_billing?.address" class="col col-6"
											style="border-left: 2px solid #f9f9f9">
											<p>
												<strong>Endereço de faturação:</strong>
											</p>
											<p>
												{{ selectedOrder.customer.name }}
												<br />
												{{ selectedOrder.address_billing.address }}
												<br />
												{{ selectedOrder.address_billing.postcode }}
												{{ selectedOrder.address_billing.city }}
												<br />
												{{ selectedOrder.address_billing.country }}
											</p>
										</div>
									</div>
									<br />
									<br />
									<div class="row align-items-center" style="margin-bottom: 20px">
										<div class="col col-8 text-left">
											<strong>Produtos</strong>
										</div>
										<div class="col col-2 text-center">
											<strong>Quantidade</strong>
										</div>
										<div class="col col-2 text-end">
											<strong>Sub-total</strong>
										</div>
									</div>
									<div class="row align-items-center product"
										*ngFor="let product of selectedOrder.items" style="margin-bottom: 20px">
										<div class="col col-3">
											<img [src]="product.image" style="width: 100%" />
										</div>
										<div class="col col-5">
											<a class="btn-link" routerLink="/product/{{ product.product_id }}">{{
												product.version ? product.version.name : product.name }}</a>
											<!-- <button class="btn outline add-comment" *ngIf="!product.comment" (click)="addComment(product)">Escrever comentário</button> -->
										</div>
										<div class="col col-2 text-center">
											{{ product.quantity }}
										</div>
										<div class="col col-2 text-end">
											{{ product.price | currency : "EUR" : "symbol" : "1.2-2" : "pt-PT" }}
										</div>
									</div>
									<div class="row"
										style="border: 1px solid #f9f9f9; margin-bottom: 20px; background: #f9f9f9; padding-top: 10px; padding-bottom: 10px">
										<div class="col col-4"></div>
										<div class="col col-8">
											<!-- <div class="row text-right">
								<div class="col col-6">Sub-total:</div>
								<div class="col col-6">{{ selectedOrder.sub_total | price }}</div>
							</div> -->
											<!-- <div class="row text-right" *ngIf="selectedOrder.fee">
								<div class="col col-6">
									Taxa de envio:
								</div>
								<div class="col col-6">
									{{selectedOrder.fee | price}}
								</div>
							</div> -->
											<div class="row text-end"
												*ngIf="selectedOrder.transaction?.used_amount > 0">
												<div class="col col-6">Desconto do cartão:</div>
												<div class="col col-6">
													-
													{{ selectedOrder.transaction?.used_amount | currency : "EUR" :
													"symbol" : "1.2-2" : "pt-PT" }}
												</div>
											</div>
											<div class="row">
												<div class="col text-end">
													<span style="color: #444; font-size: 20px">
														Total:
													</span>
												</div>
												<div class="col-auto">
													<span style="color: #444; font-size: 20px">
														<strong>{{ selectedOrder.total | currency : "EUR" : "symbol" :
															"1.2-2" : "pt-PT" }} </strong>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ngb-accordion #accordion (panelChange)="toggleAccordian($event)" class="d-md-none"
							[closeOthers]="true" activeIds="order-0">
							<ngb-panel id="{{ order.id }}"
								title="#{{ order.name }} - {{ order.date | date : 'dd/MM/yyyy' }}"
								*ngFor="let order of orders; let i = index">
								<ng-template ngbPanelContent>
									<div *ngIf="selectedOrder">
										<div *ngIf="orderStep > 0 && orderStep < 5" class="order-state mb-2">
											<div class="order-state-item {{ orderStep >= 1 ? 'current' : '' }}">
												<div class="state-icon">
													<i class="fas fa-fw fa-money-bill-wave"></i>
												</div>
												<div>Aguarda pagamento</div>
											</div>
											<div class="connect-item {{ orderStep >= 2 ? 'current' : '' }}">
												<span></span>
											</div>
											<div class="order-state-item {{ orderStep >= 2 ? 'current' : '' }}">
												<div class="state-icon">
													<i class="fas fa-fw fa-box-open"></i>
												</div>
												<div>Em preparação</div>
											</div>
											<div class="connect-item {{ orderStep >= 3 ? 'current' : '' }}">
												<span></span>
											</div>
											<div class="order-state-item {{ orderStep >= 3 ? 'current' : '' }}">
												<div class="state-icon">
													<i class="fas fa-fw fa-shipping-fast"></i>
												</div>
												<div>Enviada</div>
											</div>
											<div class="connect-item {{ orderStep >= 4 ? 'current' : '' }}">
												<span></span>
											</div>
											<div class="order-state-item {{ orderStep >= 4 ? 'current' : '' }}">
												<div class="state-icon">
													<i class="fas fa-fw fa-home"></i>
												</div>
												<div>Entregue</div>
											</div>
										</div>
										<div *ngIf="orderStep > 4" class="order-state mb-2">
											<div class="order-state-item {{ orderStep == 5 ? 'current' : '' }}">
												<div class="state-icon">
													<i class="fas fa-fw fa-times"></i>
												</div>
												<div>Anulada</div>
											</div>
										</div>

										<div class="row justify-content-center py-5">
											<div class="col-12 text-center">
												<div class="fdb-box">
													<h3>
														{{ selectedOrder.payment_state }}
													</h3>
													<dl>
														<dt>Método de Pagamento</dt>
														<dd>
															{{ selectedOrder.payment_type }}
														</dd>
													</dl>
													<p class="mt-4" *ngIf="orderStep != 0">
														<button class="btn outline btn-secondary"
															(click)="pay()">Pagar</button>
													</p>
												</div>
											</div>
										</div>

										<div class="row mt-3">
											<div *ngIf="selectedOrder.address_delivery" class="col-md-6"
												style="border-left: 2px solid #f9f9f9">
												<p>
													<strong>Endereço de entrega:</strong>
												</p>
												<p>
													{{ selectedOrder.customer.name }}
													<br />
													{{ selectedOrder.address_delivery.address }}
													<br />
													{{ selectedOrder.address_delivery.postcode }}
													{{ selectedOrder.address_delivery.city }}
													-
													{{ selectedOrder.address_delivery.country }}
												</p>
											</div>
											<div *ngIf="selectedOrder.store" class="col-md-6"
												style="border-left: 2px solid #f9f9f9">
												<p>
													<strong>Endereço de entrega:</strong>
												</p>
												<p>
													{{ selectedOrder.customer.name }}
													<br />
													{{ selectedOrder.store.address }}
													<br />
													{{ selectedOrder.store.postcode }}
													{{ selectedOrder.store.city }}
													-
													{{ selectedOrder.store.country }}
												</p>
											</div>
											<div *ngIf="selectedOrder.address_billing" class="col-md-6"
												style="border-left: 2px solid #f9f9f9">
												<p>
													<strong>Endereço de faturação:</strong>
												</p>
												<p>
													{{ selectedOrder.customer.name }}
													<br />
													{{ selectedOrder.address_billing.address_comercial }}
													<br />
													{{ selectedOrder.address_billing.postcode_comercial }}
													{{ selectedOrder.address_billing.city_comercial }}
													-
													{{ selectedOrder.address_billing.country_comercial }}
												</p>
											</div>
										</div>
										<hr />
										<div class="row align-items-center mt-3">
											<div class="col text-left">
												<strong>Produtos</strong>
											</div>
											<div class="col col-auto text-center">
												<strong>Qnt | Sub-total</strong>
											</div>
										</div>

										<div class="row align-items-center" *ngFor="let product of selectedOrder.items">
											<div class="col">
												<img [src]="product.image" style="width: 50px" />
												<a class="text-small" routerLink="/product/{{ product.product_id }}">{{
													product.version?.length > 0 ? product.version.name : product.name
													}}</a>
												<!-- <button class="btn btn-sm outline" (click)="addComment(product)">Escrever comentário</button> -->
											</div>
											<div class="col-auto text-end">
												{{ product.quantity }} |
												{{ product.price }}
											</div>
										</div>
										<div class="row"
											style="border: 1px solid #f9f9f9; margin-bottom: 20px; background: #f9f9f9; padding-top: 10px; padding-bottom: 10px">
											<div class="col col-4"></div>
											<div class="col col-8">
												<!-- <div class="row text-right">
									<div class="col col-6">Sub-total:</div>
									<div class="col col-6">{{ selectedOrder.sub_total | price }}</div>
								</div> -->
												<!-- <div class="row text-right">
									<div class="col col-6">
										Taxa de envio:
									</div>
									<div class="col col-6">
										{{selectedOrder.fee | price}}
									</div>
								</div> -->
												<div class="row text-end"
													*ngIf="selectedOrder.transaction?.used_amount > 0">
													<div class="col col-6">Desconto do cartão:</div>
													<div class="col col-6">
														-
														{{ selectedOrder.transaction?.used_amount | currency : "EUR" :
														"symbol" : "1.2-2" : "pt-PT" }}
													</div>
												</div>
												<div class="row">
													<div class="col col-6 text-end">
														<span style="color: #444; font-size: 20px">
															<strong>Total:</strong>
														</span>
													</div>
													<div class="col col-6 text-end">
														<span style="color: #444; font-size: 20px">
															<strong>{{ selectedOrder.total | currency : "EUR" : "symbol"
																: "1.2-2" : "pt-PT" }} </strong>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</ngb-panel>
						</ngb-accordion>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>