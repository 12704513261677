import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BrandService } from "src/app/services/brand.service";
import { CategoryService } from "src/app/services/category.service";
import { ProductService } from "src/app/services/product.service";
import Swal from "sweetalert2";

@Component({
	selector: "app-products",
	templateUrl: "./products.component.html",
	styleUrls: ["./products.component.css"]
})
export class ProductsComponent implements OnInit {
	@HostListener('window:resize', ['$event']) onResize(event: Event) {
		this.checkIfMobile();
	}

	products: any;
	brandOptions: any;
	categoryOptions: any;
	priceOptions: any;
	subcategoryOptions: any;
	selectedBrand: any = [];
	selectedCategory: any = [];
	selectedSubCategory: any = null;
	selectedPrice: any = [];
	dropdownSettings: IDropdownSettings;
	categorySelected: [];
	subcategorySelected: [];
	grid: string = 'four';
	page: number = 0;
	limit: number = 16;
	headerImage: string = '/assets/products-bg.jpg';
	productsImage: string = '/assets/products-bg.jpg';
	isCollapsed: boolean = false;
	windowScrolled: boolean = false;
	titlePage: string = 'Produtos';
	isMobile: boolean = false;
	isDesktop: boolean = false;
	activeIds: string = '';
	pageLoaded: boolean = true;
	showLoadMore: boolean = true;

	constructor(private route: ActivatedRoute, private router: Router, private productService: ProductService, private brandService: BrandService, private categoryService: CategoryService) { }

	ngOnInit(): void {
		this.pageLoaded = false;
		this.checkIfMobile();

		window.addEventListener('scroll', () => {
			this.windowScrolled = window.pageYOffset !== 0;
		});

		this.dropdownSettings = {
			singleSelection: false,
			idField: "id",
			textField: "name",
			itemsShowLimit: 3,
			allowSearchFilter: false,
			enableCheckAll: false,
			showSelectedItemsAtTop: false,
			noDataAvailablePlaceholderText: "Sem dados disponíveis"
		};

		this.route.queryParams.subscribe(async (params) => {
			this.selectedBrand = [];
			this.selectedCategory = [];
			this.selectedSubCategory = [];
			this.page = 0;

			if (params) {
				if (params["brand_id"]) {
					this.selectedBrand = [{ brand_id: params["brand_id"] }];
				}
				if (params["subcategory_id"]) {
					this.selectedSubCategory = [{ id: params["subcategory_id"] }];
				}
				if (params["category_id"]) {
					this.selectedCategory = [{ category_id: params["category_id"] }];
				}
			}

			await this.categories();
			await this.brands();

			this.prices();

			if (params['search']) {
				this.searchAll(params['search']);
			} else {
				this.all();
			}

			this.pageLoaded = true;
		});
	}

	checkIfMobile() {
		this.isMobile = window.innerWidth <= 767.98;
		this.isDesktop = window.innerWidth >= 1400;

		this.activeIds = this.isMobile ? '' : 'category, subcategory, brand, price';
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
	}

	all(clear = true) {
		let brands = [];
		let categories = [];
		let subcategories = [];
		let prices = [];

		if (clear) {
			this.page = 0;
			this.products = null;
		}

		if (this.selectedBrand.length) {
			brands = this.selectedBrand;

			if (brands.length) {
				brands = brands.map((brand: any) => {
					return { brand_id: brand.id };
				});
			}
		}

		if (this.selectedCategory.length) {
			categories = this.selectedCategory;

			categories = categories.map((category: any) => {
				return { id: category.id };
			});

			let ids = categories.map((category: any) => {
				return category.id;
			});

			if (ids) {
				this.subcategories(ids);
			}
		}

		if (this.selectedSubCategory.length) {
			subcategories = this.selectedSubCategory;
			subcategories = subcategories.map((subcategory: any) => {
				return { id: subcategory.id };
			});
		}

		if (this.selectedPrice) {
			prices = this.selectedPrice;
			prices.map((price: any) => {
				return { min: price.id.split(":")[0], max: price.id.split(":")[1] };
			});
		}

		this.productService.filter(this.page, this.limit, categories, subcategories, brands, prices).subscribe((data: any) => {
			if (data.status === "OK") {
				if (this.products === null) {
					this.products = [];
				}

				for (const item of data.data) {
					this.products.push(item);
				}

				if (data.data.length < 16) {
					this.showLoadMore = false;
				} else {
					this.showLoadMore = true;
				}
			} else {
				this.products = [];
				this.showLoadMore = false;
			}
		});
	}

	searchAll(term: string) {
		this.products = [];

		this.productService.search(term).subscribe((data: any) => {
			if (data.status === "OK") {
				for (const item of data.data) {
					this.products.push(item);
				}
			} else {
				this.products = null;
				this.page = 0;
			}
		});
	}

	async brands() {
		return new Promise((resolve) => {
			const categories = this.selectedCategory.filter((category: any) => {
				return category.checked;
			});

			this.brandService.filter(categories).subscribe((data: any) => {
				this.brandOptions = data.data;

				if (this.selectedBrand.length) {
					let ids = this.selectedBrand.map((brand: any) => {
						if (brand.brand_id) {
							return parseInt(brand.brand_id);
						} else {
							return parseInt(brand.id);
						}
					});

					if (this.brandOptions.length) {
						this.brandOptions.map((brand: any) => {
							if (ids.length) {
								if (ids.includes(brand.id)) {
									brand.checked = true;
								}
							}
						});
					}

					this.selectedBrand = this.selectedBrand.map((brand: any) => {
						return this.brandOptions.find((brandOptions: any) => {
							if (brand.brand_id) {
								return brandOptions.id == brand.brand_id;
							} else {
								return brandOptions.id == brand.id;
							}
						});
					});
				}

				resolve(true);
			});
		});
	}

	async categories() {
		return new Promise((resolve) => {
			this.categoryService.all().subscribe(async (data: any) => {
				this.categoryOptions = data.data;

				if (this.selectedCategory) {
					if (this.selectedCategory.length) {
						let ids = this.selectedCategory.map((category: any) => {
							return parseInt(category.category_id);
						});

						this.categoryOptions.map((category: any) => {
							if (ids.includes(category.id)) {
								category.checked = true;
							}
						});

						if (ids) {
							await this.subcategories(ids);
						}
					}

					this.selectedCategory = this.selectedCategory.map((category: any) => {
						return this.categoryOptions.find((categoryOptions: any) => {
							return categoryOptions.id == category.category_id;
						});
					});

					if (this.selectedCategory.length == 1) {
						if (this.selectedCategory[0].image) {
							this.headerImage = this.selectedCategory[0].image;

							this.titlePage = this.selectedCategory[0].name;
						}
					}
				}
				resolve(true);
			});
		});
	}

	async subcategories(categories: any) {
		return new Promise((resolve) => {
			this.categoryService.subcategories(categories).subscribe((data: any) => {
				this.subcategoryOptions = data.data;

				if (this.subcategoryOptions.length) {
					this.subcategoryOptions.map((subcategory: any) => {
						subcategory.name = subcategory.name;
						// subcategory.name = subcategory.name + " (" + subcategory.category + ")";
					});
					if (this.selectedSubCategory.length) {
						let ids = this.selectedSubCategory.map((subcategory: any) => {
							return parseInt(subcategory.id);
						});

						this.subcategoryOptions.map((subcategory: any) => {
							if (ids.includes(subcategory.id)) {
								subcategory.checked = true;
							}
						});

						this.selectedSubCategory = this.selectedSubCategory.map((subcategory: any) => {
							return this.subcategoryOptions.find((subcategoryOptions: any) => {
								return subcategoryOptions.id == subcategory.id;
							});
						});

						if (this.selectedSubCategory.length == 1) {
							if (this.selectedSubCategory[0].image) {
								this.headerImage = this.selectedSubCategory[0].image;
								this.titlePage = this.selectedCategory[0].name + ' | ' + this.selectedSubCategory[0].name;
							}
						} else {
							this.headerImage = this.selectedCategory[0].image;
							this.titlePage = this.selectedCategory[0].name;
						}
					}
				} else {
					this.subcategoryOptions = this.selectedSubCategory = [];
					this.titlePage = this.selectedCategory[0].name;
				}

				resolve(true);
			});
		});
	}

	prices() {
		this.priceOptions = [
			{ id: "100:150", name: "100€ - 150€", min: 100, max: 150 },
			{ id: "150:200", name: "150€ - 200€", min: 150, max: 200 },
			{ id: "200:250", name: "200€ - 250€", min: 200, max: 250 },
			{ id: "250:300", name: "250€ - 300€", min: 250, max: 300 },
			{ id: "300:350", name: "300€ - 350€", min: 300, max: 350 },
			{ id: "350:400", name: "350€ - 400€", min: 350, max: 400 },
			{ id: "400:450", name: "400€ - 450€", min: 400, max: 450 }
		];
	}

	onItemSelect(item: any) {
		this.all();
	}

	favorite(product: any) {
		this.productService.favorite(product.id).subscribe((data: any) => {
			if (data.status === "OK") {
				product.favorite = !product.favorite;
			} else {
				if (data.code == 404) {
					Swal.fire({
						title: "Deve iniciar sessão para adicionar produtos aos favoritos",
						icon: "error",
						showClass: {
							popup: "animate__animated animate__fadeInDown"
						},
						hideClass: {
							popup: "animate__animated animate__fadeOutUp"
						},
						confirmButtonText: "Iniciar Sessão",
						confirmButtonColor: '#afd43d',
						showCancelButton: true,
						cancelButtonText: "Fechar"
					}).then((result) => {
						if (result.isConfirmed) {
							this.router.navigate(["/login"]);
						}
					});
				}
			}
		});
	}

	async changeCategory(event: any) {
		this.selectedCategory = [];
		this.selectedSubCategory = [];
		this.categoryOptions.map((category: any) => {
			category.checked = false;
		});

		if (event.target.checked) {
			const categorySelected = this.categoryOptions.find((category: any) => category.id == event.target.value);
			categorySelected.checked = true;
			this.selectedCategory.push(categorySelected);
		} else {
			this.selectedCategory = this.selectedCategory.filter((category: any) => category.id != event.target.value);
			this.titlePage = 'Produtos';
		}

		await this.brands();

		let categories = this.selectedCategory.map((category: any) => {
			return { category_id: category.id };
		});

		let ids = categories.map((category: any) => {
			return category.category_id;
		});

		if (ids) {
			await this.subcategories(ids);
		}

		if (this.selectedCategory.length) {
			this.headerImage = this.selectedCategory[0].image;
			this.titlePage = this.selectedCategory[0].name;
		} else {
			this.headerImage = this.productsImage;
		}

		this.all();
	}

	async changeSubcategory(event: any) {
		if (event.target.checked) {
			const subcategorySelected = this.subcategoryOptions.find((subcategory: any) => subcategory.id == event.target.value);
			subcategorySelected.checked = true;
			this.selectedSubCategory.push(subcategorySelected);
			this.titlePage = this.selectedCategory[0].name + ' | ' + subcategorySelected.name;
		} else {
			this.selectedSubCategory = this.selectedSubCategory.filter((subcategory: any) => subcategory.id != event.target.value);
			this.titlePage = this.selectedCategory[0].name;
		}

		// await this.brands();

		this.headerImage = this.selectedCategory[0].image;

		this.all();
	}

	changeBrand(event: any) {
		if (event.target.checked) {
			if (this.brandOptions.length) {
				const brandSelected = this.brandOptions.find((brand: any) => {
					return brand.id == event.target.value;
				});
				this.selectedBrand.push(brandSelected);
			}
		} else {
			if (this.selectedBrand.length) {
				this.selectedBrand = this.selectedBrand.filter((brand: any) => {
					return brand.id != event.target.value;
				});
			}
		}

		this.all(true);
	}

	changePrice(event: any) {
		if (event.target.checked) {
			const priceSelected = this.priceOptions.find((price: any) => price.id == event.target.value);
			this.selectedPrice.push(priceSelected);
		} else {
			this.selectedPrice = this.selectedPrice.filter((price: any) => price.id != event.target.value);
		}

		this.all();
	}

	changeGrid(columns: number) {
		switch (columns) {
			case 2:
				this.grid = 'col-xl-6';
				break;
			case 4:
				this.grid = 'col-xl-3';
				break;
			default:
				this.grid = 'col-xl-3';
				break;
		}
	}

	loadMore() {
		if (this.page >= 0) {
			this.page++;

			this.all(false);
		}
	}
}
