<div class="container">
	<h3 class="title text-center">Porque Cada Olhar é Único E o Estilo também!<br />Crie o Seus Óculos Personalizados!</h3>

	<p class="mt-5 mx-5">
		A marca IGREEN disponibiliza um serviço completo de personalização dos seus Próprios Óculos. Os óculos IGREEN não são apenas elegantes, incrivelmente leves, flexíveis e altamente personalizáveis, mas também são fabricadas respeitando o meio ambiente, usando significativamente menos água e eletricidade durante a fabricação, tornando-as uma escolha acertada e incomparável no mercado.
	</p>

	<p class="mt-5 mx-5 text-center">
		<span class="banner">
			<img src="assets/customize/oculos_personalizados.png" class="img-fluid" />
		</span>
	</p>

	<h4 class="mt-5 mx-5 text-center">Personalize <a target="_blank" class="btn-link" href="https://igreen-configurator.thema-optical.com/">AQUI</a> as Suas Armações ou Óculos de Sol!</h4>

	<div class="mx-5 mt-5 custom text-center">
		<div class="item green">
			<div class="figure">
				<img src="assets/customize/glasses.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Escolhe o Seu Modelo</h5>
		</div>
		<div class="item orange">
			<div class="figure">
				<img src="assets/customize/palette.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Escolhe a Sua Cor</h5>
		</div>
		<div class="item red">
			<div class="figure">
				<img src="assets/customize/ruler.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Escolhe o Tamanho</h5>
		</div>
		<div class="item blue">
			<div class="figure">
				<img src="assets/customize/pencil.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Grave o Seu Nome</h5>
		</div>
		<div class="item violet">
			<div class="figure">
				<img src="assets/customize/share.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Imprime ou Partilhe<span>a informação da Sua Personalização</span></h5>
		</div>
		<div class="item indigo">
			<div class="figure">
				<img src="assets/customize/store.png" class="img-fluid" alt="" />
			</div>
			<h5 class="mt-3">Encomende na Ópticas Lince</h5>
		</div>
	</div>

	<p class="mt-5 mx-5">Após a personalização dos Seus Óculos:</p>
	<p class="mx-5">Clique na IMPRESSORA que encontra do lado direito ligeiramente abaixo do óculo personalizado, imprima o documento gerado e dirija-se à Sua Loja Ópticas Lince de preferência.</p>
	<p class="mx-5">Se lhe for mais conveniente, pode também fazer o envio para o e-mail da Sua Loja Ópticas Lince de preferência através do ícone PARTILHAR colocando em Note o seu nome completo e nif.</p>
	<p class="mx-5">Depois é só validar o material e cores escolhidas em Loja e Encomendar os Seus óculos 100% exclusivos feitos à medida por Si.</p>
</div>
