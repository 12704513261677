import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root"
})
export class ProductService {
	constructor(private api: ApiService) { }

	all(category?: any, subcategory?: any, brand?: any, price?: any) {
		return this.api.get("product/all", { id: this.api.MERCHANT_ID, subcategory, category, brand, price });
	}

	one(id: string) {
		return this.api.get("product/one", { id: id });
	}

	filter(page: number = 0, limit: number = 16, categories?: any, subcategories?: any, brands?: any, prices?: any, promotion: boolean = false) {
		categories = categories.map((category: any) => {
			category['category_id'] = category['id'];
			delete category['id'];
			return category;
		});
		
		subcategories = subcategories.map((subcategory: any) => {
			subcategory['subcategory_id'] = subcategory['id'];
			delete subcategory['id'];
			return subcategory;
		});

		return this.api.post("product/filtercategory?id=" + this.api.MERCHANT_ID + '&page=' + page + '&limit=' + limit, { categories, subcategories, brands, prices, promotion }, true);
	}

	favorite(id: string) {
		return this.api.get("product/favorite", { id: id }, true);
	}

	favorites() {
		return this.api.get("customer/favorites", { merchant_id: this.api.MERCHANT_ID }, true);
	}

	feature() {
		return this.api.get("product/feature", { id: this.api.MERCHANT_ID, limit: 100 }, true);
	}

	search(term: string) {
		return this.api.get("product/search", { id: this.api.MERCHANT_ID, term: term }, true);
	}
}
