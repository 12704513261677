import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NewsService } from "src/app/services/news.service";

@Component({
    selector: "app-product",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.css"]
})
export class NewsComponent implements OnInit {
    id: number;
    loader: boolean = true;
    news: any;
    all: any[] = [];
    productPerSlide: number = 4;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private newsService: NewsService) {
        this.activatedRoute.params.subscribe(async (params: any) => {
            this.id = params.id;
            this.load();
            this.loadAll();
        });
    }

	ngOnInit(): void {
	}

	load() {
        this.newsService.one(this.id).subscribe((resp: any) => {
            if (resp.code == 200) {
                this.news = resp.data;
                this.loader = false;
            }  else {
                setTimeout(() => {
                    this.router.navigate(['/']);
                }, 1000);

            }
        });
	}

    loadAll() {
        this.newsService.all().subscribe((resp: any) => {
            if (resp.code == 200) {
                resp.data.forEach((element: any) => {
                    if(element.id != this.id) {
                        this.all.push(element);
                    }
                });
            }
        });
    }

    getTitlePathAsString(type: string, item: any) {
        return type + '/' + item.id + '/' + item.title.replace(/ /g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    }

}
