<div class="container p-0 px-md-5">
	<h3 class="title text-center">Recursos Humanos</h3>

	<h5 class="mt-5 mx-5">Quer fazer parte da nossa Equipa?</h5>

	<p class="mx-5">
		A Ópticas Lince conta com várias lojas nos distritos da Guarda, Castelo Branco, Coimbra, Leiria, Porto e Santarém. Nas últimas décadas, o grupo ganhou a confiança de cada vez mais pessoas e hoje ocupa uma posição de mercado pioneira na área da saúde visual. Dispõe de uma equipa de mais de 40 profissionais altamente qualificada, com formação técnica ao mais alto nível, repartida pelas
		diversas áreas de Optometria, Contactologia e Ortótica.
	</p>

	<p class="mx-5">Se procura a oportunidade de fazer parte de uma Equipa com elevados padrões de exigência e qualidade, que revertem a favor da saúde, conforto e qualidade de vida dos seus Clientes, envie-nos o seu Curriculum Vitae para: <a class="btn-link" href="mailto:rh@opticaslince.com">rh@opticaslince.com</a></p>

	<p class="mx-5">Mais informação sobre a nossa política de Recursos Humanos: <a class="btn-link" routerLink="/code-conduct">CÓDIGO DE BOA CONDUTA, PARA A PREVENÇÃO E COMBATE AO ASSÉDIO NO TRABALHO</a></p>

	<div class="mt-5 mx-5 row">
		<div class="col-sm py-2"><img src="assets/human-resources/1.jpg" class="img-fluid"></div>
		<div class="col-sm py-2"><img src="assets/human-resources/3.jpg" class="img-fluid"></div>
		<div class="col-sm py-2"><img src="assets/human-resources/2.jpg" class="img-fluid"></div>
	</div>
</div>
