<div class="container">
	<h3 class="title text-center">Termos e Condições Gerais do<br>Cartão de Cliente Digital Óptica Lince</h3>

	<ol class="mt-5 mx-5">
		<li class="my-4">O Cartão de Cliente Digital é propriedade da Óptica Lince, S.A. e faz parte do seu programa de fidelização.</li>

		<li class="my-4">O Cartão de Cliente Digital é pessoal e intransmissível, sendo atribuído um cartão por pessoa e está disponível para consulta na APP e em <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a>.</li>

		<li class="my-4">O programa de fidelização dirige-se aos clientes da Óptica Lince, S.A., maiores de 16 anos, e permite aos aderentes ao Cartão de Cliente Digital acumular saldo em Cartão e outras vantagens e descontos em função de campanhas especiais. A adesão ao Cartão de Cliente Digital é livre e gratuita.</li>

		<li class="my-4">A adesão ao Cartão pode ser feita nas lojas físicas, na APP e em <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a>.</li>

		<li class="my-4">É válida a utilização do Cartão de Cliente Digital em toda a rede de lojas físicas em Portugal, na APP e no website <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a></li>

		<li class="my-4">A utilização do Cartão de Cliente Digital permite-lhe acumular, como saldo do seu cartão, a quantia 5% do valor das suas compras e receber um vale durante o seu mês de aniversário de 10€. Poderá ainda acumular outras vantagens e descontos em função de campanhas especiais.</li>

		<li class="my-4">O vale aniversário é válido nas Lojas, na APP e <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a> no mês do seu aniversário. Para utilizar a oferta nas Lojas mostre o e-mail recebido ou o vale disponível na APP, no momento da compra. Para utilizar na APP e <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a>, no momento do checkout selecione o código que lhe foi fornecido no campo “Descontar Cupão”. É válido em compras superiores a 30€.</li>

		<li class="my-4">
			Salvo o caso de campanhas ou promoções especiais, poderá utilizar os descontos acumulados em cartão passadas 24 horas após a emissão desses mesmos descontos. Caso ocorra algum problema informático aquando da utilização do cartão, deverá seguir as indicações dadas pela Loja ou, no caso do website <a href="https://www.opticaslince.com/" class="btn-link">www.opticaslince.com</a> contactar o serviço de apoio ao cliente através do e-mail
			<a class="btn-link" href="mailto:apoioaocliente@opticaslince.com">apoioaocliente@opticaslince.com</a>
		</li>

		<li class="my-4">O saldo do Cartão de Cliente Digital pode ser usado, no todo ou em parte, em compras de valor igual ou superior ao valor do desconto que seja utilizado.</li>

		<li class="my-4">O saldo do Cartão de Cliente Digital em caso algum será reembolsado ao cliente em numerário, tendo por exclusiva finalidade a utilização prevista no número anterior.</li>

		<li class="my-4">No caso de haver devolução ou troca de compras, que tenham originado uma acumulação de saldo no cartão, que ainda não foi gasto, far-se-á o desconto no saldo do cartão do valor creditado com a aquisição do artigo.</li>

		<li class="my-4">Em caso de devoluções ou troca de produtos que tenham originado acumulação de valores em cartão entretanto gastos, o Cliente receberá a quantia resultante do valor da devolução menos o benefício/desconto entretanto utilizado.</li>

		<li class="my-4">Caso a troca ou devolução se refira a artigos adquiridos, total ou parcialmente, com os valores acumulados no cartão, o reembolso do preço, correspondente ao valor das compras, será sempre adicionado ao saldo do cartão e nunca através de reembolso em numerário.</li>

		<li class="my-4">A utilização do saldo acumulado em cartão como método de pagamento numa determinada compra exclui a adição de novos valores em cartão nessa mesma compra. Os valores em cartão apenas são acumuláveis quando se verifica uma transação financeira.</li>

		<li class="my-4">
			A adesão ao Cartão de Cliente Digital implica a recolha e tratamento de dados pessoais. Os seus dados serão tratados em conformidade com os termos da nossa <a routerLink="/terms-conditions" class="btn-link">Política de Privacidade</a>. Caso se oponha à utilização desses dados, não poderá continuar a usufruir do Cartão. Tais dados serão utilizados para efeitos de gestão do cartão, como, por exemplo, validação de benefícios e
			controlo da utilização do cartão em cumprimento das regras previstas no programa.
		</li>

		<li class="my-4">
			A Óptica Lince, SA é a entidade responsável pela recolha e tratamento dos seus dados pessoais de aderente (como, por exemplo, o nome, morada, número de contribuinte, telefone, data de nascimento e email) dos seus dados transacionais (relativos ao valor das suas compras) e dos dados operacionais, relacionados com o saldo constante do Cartão de Cliente Digital e com o histórico dos
			saldos.
		</li>

		<li class="my-4">
			O tratamento destes dados será realizado para as seguintes finalidades:
			<ol>
				<li class="my-2">para gerir e aperfeiçoar o programa de benefícios associados ao Cartão de Cliente Digital, permitindo realizar o cálculo do saldo em cartão e a atribuição das vantagens associadas ao cartão, como, por exemplo, para o envio do seu vale de oferta no seu aniversário;</li>
				<li class="my-2">a análise de dados para deteção de fraude ou uso indevido do Cartão.</li>
			</ol>
		</li>

		<li class="my-4">Os seus dados serão conservados durante todo o período em que for possuidor do Cartão de Cliente Digital, ou seja, enquanto a sua conta não for cancelada por qualquer motivo, ou por tempo superior se assim a lei o estabelecer.</li>

		<li class="my-4">Nos termos previstos na legislação aplicável, o aderente pode exercer os seus direitos de acesso, de retificação, de apagamento, de limitação do tratamento, de portabilidade, de oposição ao tratamento dos seus dados pessoais. Para mais informações sobre estes direitos e a forma de os exercer, consulte a nossa <a routerLink="privacy" class="btn-link">Política de Privacidade</a>.</li>

		<li class="my-4">A Óptica Lince, S.A. reserva-se o direito de, de forma unilateral, decidir sobre as vantagens e benefícios associados à utilização do Cartão, bem como sobre as suas condições de funcionamento, cancelamento, alteração, substituição ou modificação.</li>

		<li class="my-4">A Óptica Lince, S.A. reserva-se, ainda, o direito de cancelar o Cartão de Cliente Digital e o respetivo saldo, caso sejam detetados indícios de fraude, utilização indevida ou uso comercial.</li>
	</ol>
</div>
