<div class="container">
	<h3 class="title text-center">Política de Privacidade & Proteção de Dados</h3>

	<h4 class="mt-5 mx-5">QUEM SOMOS?</h4>

	<p class="mx-5">A <b>ÓPTICAS LINCE</b>, titular do número único de pessoa coletiva e de matrícula 503079871, com o capital social de € 110.000,00 (cento e dez mil euros), com sede em Rua do Comércio, 32, 6300-679 Guarda, tem por objeto social, entre outros, o comércio a retalho de material óptico em estabelecimento especializado.</p>

	<p class="mx-5">
		A ÓPTICAS LINCE está empenhada em proteger os dados pessoais dos clientes e utilizadores de produtos e serviços das referidas marcas e, bem assim, os dados pessoais dos respetivos titulares em todas as situações em que ocorra um tratamento de dados pessoais pela ÓPTICAS LINCE ou pelas empresas que fazem parte da sua rede de distribuição. Neste contexto, a ÓPTICAS LINCE elaborou a presente
		Política de Privacidade a qual tem subjacente o seu compromisso em respeitar as regras de proteção de dados pessoais.
	</p>

	<h4 class="mt-5 mx-5">PORQUÊ ESTA POLÍTICA DE PRIVACIDADE?</h4>

	<p class="mx-5">
		Nesta Política pretende-se dar a conhecer aos clientes e utilizadores da ÓPTICAS LINCE as regras gerais de tratamento de dados pessoais, os quais são recolhidos e tratados no estrito respeito e cumprimento do disposto na legislação de proteção de dados pessoais em vigor em cada momento, nomeadamente o Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016.
	</p>

	<p class="mx-5">A ÓPTICAS LINCE respeita as melhores práticas no domínio da segurança e da proteção dos dados pessoais, tendo para o efeito tomado as medidas técnicas e organizativas necessárias por forma a cumprir o RGPD e garantir que o tratamento dos dados pessoais é lícito, leal, transparente e limitado às finalidades autorizadas.</p>

	<p class="mx-5">A ÓPTICAS LINCE está empenhada na proteção e confidencialidade dos dados pessoais, tendo adotado as medidas que considera adequadas para assegurar a exatidão, integridade e confidencialidade dos dados pessoais, bem como, todos os demais direitos que assistem aos respetivos titulares.</p>

	<h4 class="mt-5 mx-5">O QUE ABRANGE ESTA POLÍTICA DE PROTEÇÃO DE DADOS?</h4>

	<p class="mx-5">
		Esta Política aplica-se à recolha e tratamento de dados pessoais dos clientes no contexto da aquisição de quaisquer bens, produtos ou serviços diretamente com a nossa empresa ou através de concessionários autorizados e instalado naquele sítio e para os fins indicados abaixo - recolha que é feita através do preenchimento pelo cliente no momento da aquisição do bem, produto ou serviço - e
		ainda no contexto da recolha de dados de visitantes ou utilizadores que entram em contacto direto com a ÓPTICAS LINCE ou que se registam nos seus websites.
	</p>

	<h4 class="mt-5 mx-5">QUEM É O RESPONSÁVEL PELO TRATAMENTO DE DADOS?</h4>

	<p class="mx-5">As entidades responsáveis pelo tratamento conjunto dos seus dados pessoais são a ÓPTICAS LINCE e o terceiro vendedor autorizado onde tenha adquirido o bem, produto ou serviço. A lista de lojas e vendedores autorizados que integram a rede de distribuição da ÓPTICAS LINCE pode ser consultada em www.opticaslince.com</p>

	<h4 class="mt-5 mx-5">QUAIS SÃO OS TIPOS DE DADOS PESSOAIS TRATADOS?</h4>

	<p class="mx-5">Na ÓPTICAS LINCE compreendemos que a utilização dos seus dados pessoais requer a sua total confiança. Estamos sujeitos a elevados padrões de privacidade e apenas utilizaremos os seus dados pessoais para finalidades claramente identificadas e com respeito pelos seus direitos à sua proteção.</p>

	<p class="mx-5">A confidencialidade e a integridade dos seus dados pessoais são umas das nossas principais preocupações.</p>

	<p class="mx-5">A ÓPTICAS LINCE, em conjunto com os vendedores autorizados onde tenha adquirido o seu bem, produto ou serviço, procede ao tratamento de várias categorias de dados pessoais, designadamente, os fornecidos nos formulários de cliente disponibilizados na rede de lojas, incluindo:</p>

	<ol type="a" class="mx-5">
		<li>Dados demográficos: (i) nacionalidade e (ii) data de nascimento.</li>
		<li>Dados contratuais e de contacto: (i) nome; (ii) sexo; (iii) n.º de cliente; (iv) morada; (v) endereço de correio eletrónico; (vi) telefone/telemóvel.</li>
		<li>Dados de identificação: (i) documento de identificação; (ii) número de contribuinte.</li>
		<li>Dados profissionais: (i) profissão; (ii) título e (iii) habilitações literárias.</li>
		<li>Dados familiares: (i) estado civil; (ii) número de filhos; (iii) dimensão do agregado familiar.</li>
		<li>Dados de interação online: se o cliente se registou ou clicou num e-mail ou link num SMS é possível saber: (i) utilização da página visitada, (ii) e-mails abertos, (iii) SMS clicados; (iv) dados de cookies (dependente da aprovação da política de Cookies)</li>
	</ol>

	<h4 class="mt-5 mx-5">QUAIS AS FINALIDADES DO TRATAMENTO DOS DADOS PESSOAIS E RESPETIVOS FUNDAMENTOS JURÍDICOS</h4>

	<p class="mx-5">Nos termos do RGPD a utilização de dados pessoais tem de justificar-se ao abrigo de um fundamento jurídico para o tratamento de dados pessoais.</p>

	<p class="mx-5">A ÓPTICAS LINCE, em conjunto com a loja ou vendedor autorizado onde tenha adquirido o seu bem, produto ou serviço, apenas trata os seus dados pessoais nas seguintes situações:</p>

	<p class="mx-5"><b>1. PARA PROSSECUÇÃO DE INTERESSES LEGÍTIMOS</b>, nomeadamente, para desenvolver os seus produtos e serviços e para melhorar a qualidade do serviço prestado, tais como:</p>

	<ul class="mx-5">
		<li>Marketing e comunicação de produtos e serviços;</li>
		<li>Gestão e fidelização de clientes mediante a realização de inquéritos de satisfação no âmbito da aquisição de bens ou produtos, gestão de reclamações, realização de estatísticas, análises de perfis de consumo, entre outros.</li>
	</ul>

	<p class="mx-5"><b>2. PARA CUMPRIMENTO DE OBRIGAÇÕES LEGAIS, TAIS COMO:</b></p>
	<ul class="mx-5">
		<li>Responder a pedidos de informação em processos judiciais ou pedidos de autoridades administrativas ou outras entidades terceiras, para detetar e prevenir fraudes ou para proteger os seus interesses ou de terceiros.</li>
	</ul>

	<p class="mx-5"><b>3. PARA CUMPRIMENTO DE OBRIGAÇÕES CONTRATUAIS, TAIS COMO:</b></p>
	<ul class="mx-5">
		<li>Prestar serviços de assistência aos seus clientes;</li>
		<li>Atender aos pedidos dos seus clientes, designadamente respondendo às suas perguntas ou reclamações;</li>
		<li>Prestar serviços de assistência garantida (pós-venda);</li>
		<li>Realizar ações de campo de produtos.</li>
	</ul>

	<p class="mx-5"><b>4. PARA IR DE ENCONTRO ÀS SUAS ESCOLHAS</b></p>

	<p class="mx-5">Os seus dados pessoais são ainda tratados quando tenha dado o seu consentimento prévio e expresso para os seguintes efeitos:</p>

	<ul class="mx-5">
		<li>Realização de análises de perfilagem e segmentação comercial de clientes, baseadas no tratamento de informação biográfica, do produto e marca, que permitam conhecer ou antecipar as necessidades destes com vista à apresentação de campanhas promocionais personalizadas.</li>
		<li>Realização de estudos de mercado e sondagens não se entendendo como tal os inquéritos de satisfação referidos no ponto 1.</li>
	</ul>

	<h4 class="mt-5 mx-5">QUEM SÃO OS DESTINATÁRIOS DOS SEUS DADOS PESSOAIS?</h4>

	<ul class="mx-5">
		<li>Para que a ÓPTICAS LINCE consiga cumprir todos os seus deveres e lhe possa prestar um melhor serviço, poderá ter que comunicar ou dar acesso dos seus dados pessoais a outras entidades.</li>
		<li>A ÓPTICAS LINCE apenas comunicará os seus dados pessoais às seguintes categorias de destinatários:</li>
		<li>Fabricantes bens ou produtos e prestadores de serviços;</li>
		<li>Aos vendedores autorizados;</li>
		<li>À “ÓPTICAS LINCE” para fins de execução de procedimentos administrativos relacionados com os bens ou produtos adquiridos;</li>
		<li>Empresas e outras organizações que sejam subcontratadas para que estes tratem os seus dados em nome e por conta da ÓPTICAS LINCE, ou para fornecer serviços, tais como serviços de contact center, marketing, análise de mercado e inquéritos de satisfação no âmbito da aquisição ou da prestação de serviços de assistência;</li>
		<li>Prestadores de serviços profissionais, como advogados, solicitadores, notários ou outros profissionais similares;</li>
		<li>Entidades financeiras correspondentes no caso de compras de produto com financiamento;</li>
		<li>
			Entidades públicas, tribunais, entidades reguladoras e outras autoridades administrativas, quando se afigure necessário no cumprimento de uma obrigação legal, regulamentar ou de outra natureza, com intuito de assegurar o cumprimento pela ÓPTICAS LINCE, de deveres legais ou regulamentares, bem como assegurar a segurança das pessoas, prevenir, ou de alguma outra forma prevenir e lutar
			contra fraudes.
		</li>
	</ul>

	<p class="mx-5">Nestes casos, a ÓPTICAS LINCE garantirá que os seus dados pessoais sejam utilizados apenas para os fins legítimos aqui previstos, bem como, assegurará que os contratos e cláusulas de tratamento de dados pessoais sejam elaborados e assinados, aplicando-lhes as mesmas medidas de segurança, ou semelhantes, implementadas pela ÓPTICAS LINCE.</p>

	<h4 class="mt-5 mx-5">POR QUANTO TEMPO CONSERVAMOS OS SEUS DADOS PESSOAIS?</h4>

	<p class="mx-5">Conservamos os seus dados apenas durante o período que for necessário no âmbito da finalidade para a qual estes foram recolhidos. Em particular, e para as finalidades abaixo indicadas, a ÓPTICAS LINCE definiu os seguintes prazos de conservação:</p>

	<ul class="mx-5">
		<li>Marketing: 10 (dez) anos a contar da data de aquisição do bem ou produto ou 4 (quatro) anos a contar da data da última prestação de serviços;</li>
		<li>Realização de estatísticas: 15 (quinze) anos;</li>
		<li>Perfilagens e Sondagens: 10 (dez) anos a contar da data de aquisição do bem ou produto ou 4 (quatro) anos a contar da data da última prestação de serviços de assistência.</li>
	</ul>

	<p class="mx-5">Se o tratamento dos seus dados se basear no seu consentimento, conservaremos os seus dados de acordo com o consentimento dado e/ou até esse consentimento ser revogado.</p>

	<p class="mx-5">Uma vez atingido o prazo máximo de conservação, os seus dados pessoais serão anonimizados de forma irreversível (podendo os dados anonimizados ser conservados) ou serão destruídos de forma segura.</p>

	<h4 class="mt-5 mx-5">QUAIS SÃO OS SEUS DIREITOS ENQUANTO TITULAR DE DADOS PESSOAIS?</h4>

	<p class="mx-5">
		Enquanto titular de dados pessoais é-lhe garantido, a qualquer momento, o direito de acesso, retificação, atualização, limitação e apagamento dos seus dados pessoais, o direito de oposição à utilização dos mesmos para fins comerciais pela ÓPTICAS LINCE e à retirada do consentimento, sem que tal comprometa a licitude do tratamento efetuado ao abrigo desse consentimento, bem como o direito à
		portabilidade dos dados.
	</p>

	<p class="mx-5">Em particular, no que respeita ao tratamento dos seus dados pessoais, goza dos seguintes direitos:</p>

	<ol type="a" class="mx-5">
		<li>
			<p><b>Direito de acesso</b></p>
			<p>Sempre que o solicitar, pode obter confirmação sobre se os seus dados pessoais são tratados pela ÓPTICAS LINCE ou pelas lojas ou vendedores autorizados.</p>
			<p>Pode, ainda, aceder aos seus dados pessoais, bem como a obter as seguintes informações:</p>
			<ol type="i">
				<li>razões pelas quais os seus dados pessoais são tratados;</li>
				<li>tipos de dados pessoais que são tratados;</li>
				<li>entidades a quem os seus dados pessoais podem ser transmitidos, incluindo entidades localizadas em países fora da União Europeia ou organizações internacionais, sendo neste caso informado das garantias aplicadas à transferência dos seus dados;</li>
				<li>prazo de conservação dos seus dados ou, se tal não for possível, os critérios para fixar esse prazo;</li>
				<li>direitos de que goza em relação ao tratamento dos seus dados pessoais;</li>
				<li>se os dados pessoais não tiverem sido por si fornecidos, informações sobre a origem dos mesmos;</li>
				<li>existência de decisões individuais automatizadas, incluindo definição de perfis, e, nesse caso, informações sobre a lógica subjacente a esse tratamento, bem como sobre a importância e consequências previstas do mesmo.</li>
			</ol>
		</li>
		<li>
			<p><b>Direito de retificação</b></p>
			<p>Sempre que considerar que os seus dados pessoais (dados pessoais objetivos fornecidos por si) estão incompletos ou incorretos, pode requerer a sua retificação ou que os mesmos sejam completados.</p>
		</li>
		<li>
			<p><b>Direito ao apagamento</b></p>
			<p>Pode solicitar que os seus dados pessoais sejam apagados quando se verifique uma das seguintes situações:</p>
			<ol type="i">
				<li>os dados pessoais deixem de ser necessários para a finalidade que motivou a sua recolha ou tratamento;</li>
				<li>retire o consentimento em que se baseia o tratamento de dados e não exista outro fundamento jurídico para o mesmo;</li>
				<li>apresente oposição ao tratamento dos dados e não existam interesses legítimos prevalecentes, a avaliar caso a caso, que justifiquem o tratamento;</li>
				<li>os dados pessoais tenham sido tratados ilicitamente;</li>
				<li>os dados pessoais tenham que ser apagados ao abrigo de uma obrigação jurídica a que esteja sujeito a ÓPTICAS LINCE; ou</li>
				<li>os dados pessoais tenham sido recolhidos no contexto da oferta de serviços da sociedade de informação.</li>
			</ol>
			<p>O direito ao apagamento não se aplica quando o tratamento seja necessário para os seguintes efeitos:</p>
			<ol>
				<li>exercício de liberdade de expressão e de informação;</li>
				<li>cumprimento de obrigação legal que exija o tratamento e que se aplique à ÓPTICAS LINCE;</li>
				<li>motivos de interesse público no domínio da saúde pública;</li>
				<li>fins de arquivo de interesse público, fins de investigação científica ou histórica ou fins estatísticos, na medida em que o exercício do direito ao apagamento prejudique gravemente a obtenção dos objetivos desse tratamento; ou</li>
				<li>declaração, exercício ou defesa de um direito num processo judicial.</li>
			</ol>
		</li>
		<li>
			<p><b>Direito à limitação do tratamento</b></p>
			<p>A limitação do tratamento permite ao titular solicitar ao responsável que restrinja o acesso a dados pessoais ou que suspenda as atividades de tratamento.</p>
			<p>Pode requerer a limitação do tratamento dos seus dados pessoais nos seguintes casos:</p>
			<ol type="i">
				<li>se contestar a exatidão dos seus dados pessoais, durante um período de tempo que permita à ÓPTICAS LINCE verificar a sua exatidão;</li>
				<li>se a ÓPTICAS LINCE já não precisar dos dados pessoais para fins de tratamento, mas se esses dados forem necessários para efeitos de declaração, exercício ou defesa de um direito num processo judicial; ou</li>
				<li>se tiver apresentado oposição ao tratamento, até que se verifique que os interesses legítimos da ÓPTICAS LINCE prevalecem sobre os seus.</li>
			</ol>
		</li>
		<li>
			<p><b>Direito de portabilidade</b></p>
			<p>Poderá solicitar à ÓPTICAS LINCE a entrega, num formato estruturado, de uso corrente e de leitura automática, os dados pessoais por si fornecidos. Tem ainda o direito de pedir que a ÓPTICAS LINCE transmita esses dados a outro responsável pelo tratamento, desde que tal seja tecnicamente possível.</p>
			<p>O direito de portabilidade apenas se aplica nos seguintes casos:</p>
			<ol type="i">
				<li>quando o tratamento se basear no consentimento expresso ou na execução de um contrato; e</li>
				<li>quando o tratamento em causa for realizado por meios automatizados.</li>
			</ol>
		</li>
		<li>
			<p><b>Direito de oposição</b></p>
			<p>Tem o direito de se opor ao tratamento dos seus dados pessoais a qualquer momento, por motivos relacionados com a sua situação particular, nas seguintes situações:</p>
			<ol type="i">
				<li>Quando o tratamento se basear no interesse legítimo da ÓPTICAS LINCE; ou</li>
				<li>Quando o tratamento for realizado para fins diversos daqueles para os quais os dados foram recolhidos, mas que sejam compatíveis com os mesmos.</li>
			</ol>
			<p>A ÓPTICAS LINCE deixará, nesses casos, de tratar os seus dados pessoais, a não ser que tenha razões legítimas para realizar esse tratamento e que estas que prevaleçam sobre os seus interesses.</p>
			<p>Pode também opor-se ao tratamento dos seus dados para fins de marketing direto, incluindo a definição de perfis que esteja relacionada com esse marketing.</p>
		</li>
		<li>
			<p><b>Direito de não ficar sujeito a decisões individuais exclusivamente automatizadas</b></p>
			<p>Apesar de a ÓPTICAS LINCE poder traçar o seu perfil de modo a realizar campanhas de marketing direcionadas, conforme referido supra, em princípio, a ÓPTICAS LINCE não tomará decisões que o afetem com base em processos exclusivamente automatizados.</p>
		</li>
		<li>
			<p><b>Direito a retirar o seu consentimento</b></p>
			<p>Nos casos em que o tratamento dos dados seja feito com base no seu consentimento, poderá retirar o consentimento a qualquer momento.</p>
		</li>
		<li>
			<p><b>Direito de apresentar reclamações junto da autoridade de controlo</b></p>
			<p>Caso pretenda apresentar alguma reclamação relativamente a matérias relacionadas com o tratamento dos seus dados pessoais poderá fazê-lo junto da Comissão Nacional de Proteção de Dados autoridade de controlo competente em Portugal.</p>
			<p>Para mais informações, aceda a www.cnpd.pt.</p>
		</li>
	</ol>

	<h4 class="mt-5 mx-5">COMO EXERCER OS SEUS DIREITOS:</h4>

	<p class="mx-5">Pode exercer os seus direitos através dos seguintes canais: E-mail para o seguinte endereço: dpo@opticaslince.com Carta dirigida a DPO Ópticas Lince, S.A., Rua do Comércio, 32, 6300-679 Guarda</p>

	<h4 class="mt-5 mx-5">QUAIS AS MEDIDAS ADOTADAS PELA ÓPTICAS LINCE PARA ASSEGURAR A SEGURANÇA DOS SEUS DADOS PESSOAIS?</h4>

	<p class="mx-5">A ÓPTICAS LINCE assume o compromisso de garantir a proteção da segurança dos dados pessoais que lhe são disponibilizados, tendo aprovado e implementado rigorosas regras nesta matéria. O cumprimento destas regras constitui uma obrigação de todos aqueles que legalmente aos mesmos acedem.</p>

	<p class="mx-5">
		Tendo presente a preocupação e empenho que a ÓPTICAS LINCE revela na defesa dos dados pessoais, foram adotadas diversas medidas de segurança, de carácter técnico e organizativo, de forma a proteger os dados pessoais que lhe são disponibilizados contra a sua difusão, perda, uso indevido, alteração, tratamento ou acesso não autorizado, bem como contra qualquer outra forma de tratamento
		ilícito.
	</p>

	<p class="mx-5">Adicionalmente, as entidades terceiras que, no âmbito de prestações de serviços, procedam ao tratamento de dados pessoais em nome e por conta da ÓPTICAS LINCE, estão obrigadas a executar medidas técnicas e de segurança adequadas que, em cada momento, satisfaçam os requisitos previstos na legislação em vigor e assegurem a defesa dos direitos do titular dos dados.</p>

	<p class="mx-5">
		Neste sentido, em todos os websites da ÓPTICAS LINCE os formulários de recolha de dados pessoais obrigam a ligações seguras do browser e todos os dados pessoais cedidos ficam armazenados de forma segura nos sistemas da ÓPTICAS LINCE que, por sua vez, se encontram num datacenter próprio, a coberto de todas as medidas de segurança físicas e lógicas que a ÓPTICAS LINCE entendeu serem
		indispensáveis à proteção dos dados pessoais.
	</p>

	<h4 class="mt-5 mx-5">COMO PODE FICAR A CONHECER QUAISQUER ALTERAÇÕES À POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS?</h4>

	<p class="mx-5">É política da ÓPTICAS LINCE cumprir as disposições legais, regulamentares e normativas que regem a privacidade e a proteção dos dados em todos os países, sem exceção, onde desenvolve a sua atividade, pelo que, sempre que necessário, ajustaremos o nosso tratamento dos dados pessoais como descrito neste documento para garantir o cumprimento legal.</p>

	<p class="mx-5">Por isso, aconselhamos que consulte o site www.opticaslince.com, onde estas informações estarão a todo o tempo atualizadas, para que possa sempre estar ao corrente do tratamento que é feito dos seus dados.</p>

	<p class="mx-5">Sempre que haja alterações em matéria de tratamento dos seus dados pessoais, a ÓPTICAS LINCE irá informá-lo através do site www.opticaslince.com ou através dos outros canais de comunicação normalmente utilizados.</p>

	<p class="mx-5">Atualizado em Fevereiro de 2021</p>
</div>
