import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class SpecialtyService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get("specialties/all", { id: this.api.MERCHANT_ID });
	}

	one(id: string) {
		return this.api.get("specialties/one", { id: id });
	}
}
