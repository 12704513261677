import { Component, OnInit } from "@angular/core";
import { BrandService } from "src/app/services/brand.service";

@Component({
	selector: "app-brands",
	templateUrl: "./brands.component.html",
	styleUrls: ["./brands.component.css"]
})
export class BrandsComponent implements OnInit {
	brands: any;
	constructor(public brandService: BrandService) {}

	ngOnInit(): void {
		this.loadBrands();
	}

	loadBrands() {
		this.brandService.all().subscribe((data: any) => {
			this.brands = data.data;
		});
	}
}
