import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private storage: Storage;

	constructor() {
		this.storage = localStorage;
	}

	set(key: string, value: any): boolean {
		if (this.storage) {
			this.storage.setItem(key, JSON.stringify(value));
			return true;
		}

		return false;
	}

	get(key: string): any {
		if (this.storage) {
			let item = this.storage.getItem(key);
			return item ? JSON.parse(item) : null;
		}

		return null;
	}

	remove(key: string): boolean {
		if (this.storage) {
			this.storage.removeItem(key);
			return true;
		}

		return false;
	}

	clear(): boolean {
		if (this.storage) {
			this.storage.clear();
			return true;
		}
		return false;
	}
}
