import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get("order/allorders", null, true);
	}

	one(id: number) {
		return this.api.get("order/one", { id }, true);
	}

	new(data: any) {
		data.merchant_id = this.api.MERCHANT_ID;
		return this.api.post("order/new", data, true);
	}

	create(data: any) {
		return this.api.post("payment/create", data, true);
	}

	async(data: any) {
		return this.api.post("payment/async", data, true);
	}
	
	multibanco(data: any) {
		return this.api.post("payment/multibanco", data, true);
	}
}
