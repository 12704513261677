import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-family',
	templateUrl: './family.component.html',
	styleUrls: ['./family.component.css']
})
export class FamilyComponent implements OnInit {
	family: any;

	constructor(private userService: UserService) { }

	ngOnInit() {
		this.load();
	}

	load() {
		this.userService.family().subscribe((data: any) => {
			if (data.status == 'OK') {
				this.family = data.data;
			}
		});
	}

	logout() {
		this.userService.logout();
	}
}
