import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
	providedIn: "root"
})
export class MerchantService {
	constructor(private api: ApiService) { }

	one() {
		return this.api.get('merchant/one', { id: this.api.MERCHANT_ID });
	}
}
