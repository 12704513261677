import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
	@HostListener("window:scroll", []) onWindowScroll() {
		this.scrolled = window.scrollY > 50;
	}
	@Input() showSearch: boolean = false;
	public scrolled: boolean = false;
	public searchForm = this.formBuilder.group({
		search: '',
	});

	constructor(private formBuilder: FormBuilder, public router: Router) { }

	ngOnInit(): void {
	}

	switchSearch() {
		this.showSearch = !this.showSearch;
	}

	goSearch() {
		if (this.searchForm.value.search != '') {
			this.switchSearch();
			this.router.navigate(['/products'], { queryParams: this.searchForm.value });
		}
	}
}
