import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-loyalty',
	templateUrl: './loyalty.component.html',
	styleUrls: ['./loyalty.component.css']
})
export class LoyaltyComponent implements OnInit {
	loyalties: any;

	constructor(private userService: UserService) { }

	ngOnInit() {
		this.load();
	}

	load() {
		this.userService.loyalties().subscribe((data: any) => {
			if (data.status == 'OK') {
				this.loyalties = data.data.transactions;
			}
		});
	}

	logout() {
		this.userService.logout();
	}
}
