import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { StoreService } from 'src/app/services/store.service';

@Component({
	selector: 'app-store',
	templateUrl: './store.component.html',
	styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
	@ViewChild("map") mapElement: ElementRef;

	public store: any;
	public store_id: any;
	private map: google.maps.Map

	constructor(private route: ActivatedRoute, private storeService: StoreService) {
		this.route.params.subscribe((params: any) => {
			if (params.id != 'undefined') {
				this.store_id = params.id;
			}
		});
	}

	ngOnInit() {
		this.load(this.store_id);
	}

	load(id: string) {
		this.storeService.one(id).subscribe((data: any) => {
			this.store = data.data;
			this.loadMap();
		});
	}

	loadMap() {
		let loader = new Loader({
			apiKey: 'AIzaSyBYI3g-sKwbjkjSofdiyzWkq9pu_3IwmoY',
		});

		loader.load().then(() => {
			const location = { lat: parseFloat(this.store?.latitude), lng: parseFloat(this.store.longitude) }

			this.map = new google.maps.Map(this.mapElement.nativeElement, {
				center: location,
				zoom: 17
			})

			const marker = new google.maps.Marker({
				position: location,
				map: this.map,
			});
		});
	}
}
