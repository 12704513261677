import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class BannerService {

	constructor(private api: ApiService) { }

	all() {
		return this.api.get('banner/all', { id: this.api.MERCHANT_ID });
	}
}
